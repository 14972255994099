@import "../../assets/styles/variables.scss";
.sign-up-container {
  display: flex;
  width: 50%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  .signup-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 40px;
    max-width: 392px;

    .heading {
      text-align: left;
      //margin-bottom: 15px;
      @media (max-height: 700px) {
        margin-bottom: 4px;
      }
      span {
        color: #222222;
        font-family: Inter;
        -webkit-font-smoothing: antialiased;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0.2px;
      }
    }
    .signup-list {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .name-container {
        display: flex;
        gap: 14px;
        .form-group {
          flex-basis: 50%;
        }
      }
      .sign-up-textfields {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      .cg-eye-btn {
        padding: 4px;
        margin-right: 0px;
        .cg-eye {
          height: 18px;
          width: 18px;
        }
      }
      .form-group {
        text-align: start;
        margin-bottom: 14px;
        @media (max-height: 700px) {
          margin-bottom: 10px;
        }
        label {
          margin-bottom: 13px;
          @media (max-height: 700px) {
            margin-bottom: 10px;
          }
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          line-height: $line-height-16;
        }
        input {
          height: 48px;
          border-radius: 6px;
          max-width: 440px;
          @media (max-height: 700px) {
            height: 44px;
          }
          &::placeholder {
            color: #bdbdbd;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            letter-spacing: 0.2px;
          }
        }
      }
      .form-check-group {
        // display: flex;
        justify-content: space-between;
        text-align: center;
        padding: 0px 10px;
        .form-check-label {
          font-size: 12px;
          font-family: Inter;
          line-height: 18px;
          color: $grey_variation-3;
          letter-spacing: 0.2px;
          a {
            color: $blue;
          }
        }
        .form-check-input {
          cursor: pointer;
          margin-top: 2px;
          &:checked {
            background-color: $green;
            border-color: $green;
          }
        }
      }
      .login-btn {
        height: 52px;
        font-size: 14px;
        // font-weight: $font-weight-600;
        line-height: 21px;
        font-family: "Inter-Semibold";
      }
      .submit-div {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
      .email-error {
        display: flex;
        align-items: flex-start;
        gap: 4px;

        img {
          width: 14px;
          height: 14px;
          margin-top: 1px;
        }

        span {
          margin: 0px;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          line-height: 16px;
        }

        .signup-password-req {
          display: none;
          color: #7A7A7A;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          line-height: 20px;
        }
      }

      .pword-error {
        margin-top: 6px;
      }

      .Mui-focused .signup-password-req {
        display: block;
      }
    }
    .signin-link-container {
      text-align: center;
      display: flex;
      justify-content: center;
      span {
        -webkit-font-smoothing: antialiased;
        color: #222;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        .signin-link {
          cursor: pointer;
          color: $green;
          font-family: "Inter-Medium";
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .sign-up-container {
    width: 100%;
    justify-content: flex-start;
    background-color: #f8f9fc;
    background-image: url(../../assets/images/snowflake_image.svg);
    background-repeat: no-repeat;
    background-position: 106% -6%;
    .signin-header {
      background-color: transparent;
    }
    .block {
      padding-left: 0px;
      padding-right: 0px;
      .signup-parent {
        gap: 32px;
        background: #fff;
        padding: 40px;
        width: 100%;
        max-width: 472px;
        border-radius: 8px;
        .signup-list {
          gap: 32px;
        }
      }
      .confirmmail-parent {
        background: #fff;
        padding: 40px;
        width: 100%;
        max-width: 472px;
        border-radius: 8px;
      }
    }
  }
}

@media (max-width: 425px) {
  .sign-up-container {
    background-color: #fff;
    background-image: none;
    .signin-header {
      background: #f8f9fc;
    }
    .block {
      padding-left: 0px;
      padding-right: 0px;
      .signup-parent {
        padding: 20px;
        .heading {
          margin: 0;
          span {
            font-size: 22px;
            line-height: 33px;
            letter-spacing: 0.2px;
            font-weight: 600;
          }
        }
        .signup-list {
          .form-check-group {
            margin: 0px 5px;
            padding: 0px;
            text-align: start;
            .form-check-label {
              font-size: 12px;
            }
          }
          .login-btn {
            font-family: "Inter-Medium";
          }
        }
      }
      .confirmmail-parent {
        padding: 20px;
        padding-top: 0px;
        .heading {
          margin: 0;
          span {
            font-size: 22px;
            line-height: 33px;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }
}
