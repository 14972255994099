@import "../../assets/styles/variables.scss";
.whrec-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 32px 16px 32px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 6px;
      .header {
        text-align: start;
        span {
          @include semibold(20px, 26px);
        }
      }
      .timestamp-container {
        display: flex;
        span {
          @include regular(12px, 18px);
          color: #7a7a7a;
          .subheader-bold {
            @include medium(12px, 18px);
          }
        }
      }
    }
  }

  .whgrid-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    .whgrid-filter-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .filter-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      position: relative;
    }
    .export-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 8px;
    }
    .grid-block {
      width: 100%;
      display: flex;
      gap: 24px;
      border-radius: 12px;
      flex-direction: column;
      background-color: $white;
    }
    .select-container{
      width: 230px;
      @include calendarDropdowns();
    }
  }
  .load {
    background-color: transparent;
  }
  .qry-detail-btn {
    display: flex;
    gap: 4px;
    align-items: center;
    color: $green;
    background: transparent;
    border: none;
    font-family: "Inter-Medium";
    font-weight: 500;
    font-size: 14px;
    line-height: $line-height-16;
    letter-spacing: $letter-spacing-point1;
    padding-left: 0px;
    .img-container {
      display: flex;
      width: 18px;
      height: 18px;
      align-items: center;
      justify-content: center;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.plans-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  display: none;
  &.show {
    display: block;
  }
}
.plans-modal {
  z-index: -1;
  opacity: 0;
  background: transparent;
  &.show {
    z-index: 1050;
    opacity: 1;
  }
  .plans-modal-dialog {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    opacity: 1;
    pointer-events: none;
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
    &.show {
      transform: none;
    }
    @media (min-width: 768px) {
      max-width: 500px;
      margin: 1.75rem auto;
      min-height: calc(100% - 3.5rem);
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        .modal-title {
          margin-bottom: 0;
          line-height: 1.5;
          font-weight: bold;
          color: #222222;
          text-transform: capitalize;
        }
        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
          cursor: pointer;
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.5;
          -webkit-appearance: button;
          background-color: transparent;
          border: 0;
        }
      }
      .modal-body {
        flex: 1 1 auto;
        padding: 1rem;
        position: relative;
        p {
          font-size: 14px;
          line-height: 150%;
          color: #7a7a7a;
          text-align: start;
        }
        .det-container {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          .plan-det {
            flex: 1 1 45% !important;
            &.box {
              border: 1px solid #d6d6d6;
              border-radius: 4px;
              //box-shadow: 0px 0px 2px #cecece;
              //min-width: 250px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              .highlighted-span {
                border: 1px solid #f5f5f5;
                padding: 4px 8px;
                // margin: 4px 0px;
                border-radius: 4px;
                color: $green;
                background-color: white;
                font-size: 12px;
                margin-bottom: 0px;
              }
            }
            &.box-selected {
              border-color: $green;
              //box-shadow: 0px 0px 2px $green;
            }
            &.p-3 {
              padding: 1rem;
            }
            .det-span {
              font-weight: 600;
              text-align: start;
              margin-bottom: 0.5rem;
              line-height: 22px;
              font-size: 14px;
              align-self: flex-start;
              color: #222222;
            }
            div {
              margin-bottom: 0.5rem;
              font-weight: 400;
              line-height: 22px;
              font-size: 14px;
              color: #222222;
              text-align: left;
              align-self: flex-start;
            }
            span {
              font-weight: 600;
              text-align: start;
              margin-bottom: 0.5rem;
              font-size: 1rem;
              align-self: flex-start;
            }
          }
          .px-3 {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

.recs-modal {
  z-index: -1;
  opacity: 0;
  background: transparent;
  &.show {
    z-index: 1050;
    opacity: 1;
  }
  .voucher-modal-dialog {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    opacity: 1;
    pointer-events: none;
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
    &.show {
      transform: none;
    }
    width: 500px;
    @media (min-width: 768px) {
      max-width: 500px;
      margin: 1.75rem auto;
      min-height: calc(100% - 3.5rem);
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        .modal-title {
          margin-bottom: 0;
          line-height: 1.5;
          font-weight: bold;
          color: #222222;
          text-transform: capitalize;
          &.small-font {
            font-size: 1rem;
          }
        }
        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
          cursor: pointer;
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.5;
          -webkit-appearance: button;
          background-color: transparent;
          border: 0;
        }
      }
      .modal-body {
        flex: 1 1 auto;
        padding: 1rem;
        position: relative;
        p {
          font-size: 14px;
          line-height: 150%;
          color: #303037;
          text-align: start;
        }
        .recs__container {
          display: flex;
          flex-direction: column;
          overflow-y: auto;
          max-height: 280px;
          //align-items: center;
          //justify-content: center;
          // .recs__block {
          //   border-bottom: 1px solid #ededed;
          //   padding: 6px 0px;
          //   .rec__parent {
          //     display: flex;
          //     gap: 4px;
          //     p {
          //       font-size: 12px;
          //       line-height: 22px;
          //       font-weight: 600;
          //       color: #222;
          //       margin-bottom: 0px;
          //       padding-bottom: 6px;
          //       &.color__blue {
          //         color: rgb(5, 151, 242);
          //       }
          //     }
          //   }
          // }
          .qry__container {
            display: flex;
            flex-direction: column;
            gap: 2px;
            .copy-image {
              width: 28px;
              height: 28px;
              position: absolute;
              margin-top: 4px;
              margin-right: 4px;
              align-self: flex-end;
              cursor: pointer;
              z-index: 99;
              &:hover {
                opacity: 0.8;
              }
              img {
                height: 100%;
                width: 100%;
              }
            }

            .raw-div {
              overflow-y: auto;
              border-radius: 8px;
              position: relative;
              padding: 8px 12px;
              height: inherit;
              text-align: start;
              font-size: 12px;
              .rec__parent {
                p {
                  margin-bottom: 8px;
                  font-size: 12px;
                  line-height: 22px;
                  color: grey;
                }
              }
            }
          }
        }
      }
    }
  }
}
