@import "../../assets/styles/variables.scss";
.modal-container.add-user-modal {
  z-index: 1000;
  top: 0;
  left: 0;
}
.form-container.add-user-container {
  width: 100%;
  height: calc(100% - 70px);
  @media (max-height: 700px) {
    height: calc(100% - 70px);
  }
  background-color: transparent;
  position: absolute;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 1000;
  .form-parent {
    opacity: 1;
    border-radius: 10px;
    width: 470px;
    background-color: white;
    .form-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 49px;
      border-bottom: 1px solid #e1e1e1;
      background-color: white;
      position: fixed;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 470px;
      z-index: 1000;
      &.no-border {
        border: none;
        height: 40px;
      }
      .header {
        display: flex;
        align-items: center;
        padding-left: 32px;
        span {
          font-weight: $font-weight-600;
          font-size: $font-size-18;
          line-height: 25.2px;
          color: #222222;
        }
      }
      .close-img {
        display: flex;
        align-items: center;
        padding-right: 32px;
        &.padding-right-16 {
          padding-right: 16px;
        }
        span {
          color: $grey_variation-3;
          cursor: pointer;
        }
      }
    }
    .field-container {
      text-align: start;
      padding-top: 74px;
      //padding-bottom: 24px;
      height: 442px;
      padding-left: 32px;
      padding-right: 32px;
      overflow: auto;
      &.edit__field__container {
        height: auto;
        overflow: inherit;
      }
      .form-group {
        margin-bottom: 24px;
        &.edit__form__group {
          margin-bottom: 16px;
        }
        .label-parent {
          display: flex;
          justify-content: space-between;
          .label-container {
            display: flex;
            gap: 4px;
            label {
              font-weight: $font-weight-400;
              font-size: $font-size-14;
              line-height: $line-height-16;
              letter-spacing: $letter-spacing-point1;
              color: $black_variation-2;
              margin-bottom: 12px;
            }
            .img-container {
              height: 14px;
              width: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              .info-tooltip {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  margin-top: 2px;
                  height: 100%;
                  width: 100%;
                  cursor: pointer;
                }
              }
            }
            &.edit__label {
              label {
                color: #222;
                font-family: "Inter-Medium";
                font-size: 14px;
                font-style: normal;
                line-height: 20px; /* 142.857% */
                text-transform: capitalize;
                margin-bottom: 6px;
              }
            }
          }
          a {
            font-size: $font-size-14;
            line-height: $line-height-16;
            font-weight: $font-weight-400;
            text-decoration: none;
            text-align: center;
            color: #0597f2;
            letter-spacing: 0.2px;
            img {
              margin-bottom: 2px;
            }
          }
        }
        .checkbox-container {
          display: flex;
          gap: 8px;
          align-items: center;
          height: 20px;
          .form-check-label {
            font-size: $font-size-14;
            font-weight: $font-weight-400;
            line-height: 21px;
            letter-spacing: 0.2px;
            color: $grey_variation-3;
          }
          input {
            cursor: pointer;
            height: 100%;
            width: 20px;
            margin-top: 0px;
            &:checked {
              background-color: $green;
              border-color: $green;
            }
          }
        }
        .select-container {
          cursor: pointer;
          //width: 230px;
          height: 48px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            background-color: $grey-variation-5;
            display: flex;
            min-height: 40px;
            height: 48px;
            cursor: pointer;
            width: 100% !important;
            background-color: #f9f9fc;
            border-radius: 8px;
            .selectcategory__value-container {
              flex-wrap: nowrap;
              display: flex;
              height: 48px;
              .selectcategory__single-value {
                text-align: start;
                font-size: 14px;
              }
            }
            &:hover {
              border: 0.5px solid $green;
            }
          }
          .selectcategory__control--is-focused {
            border: 0.5px solid $green;
            box-shadow: 0 0 0 0.5px $green;
          }
          .selectcategory__menu {
            z-index: 1099;
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                display: flex;
                align-items: center;
                &.selectcategory__option--is-focused {
                  background-color: #ecf8f4;
                }
              }
            }
          }
          &.edit__select {
            height: 44px;
            .selectcategory__control {
              height: 44px;
              .selectcategory__value-container {
                height: 44px;
              }
            }
          }
        }
        input {
          height: 48px;
          border-radius: 6px;
          font-size: 14px;
          &.edit__field {
            height: 44px;
          }
          &.disabled {
            color: #7a7a7a;
            cursor: not-allowed;
          }
        }
        .cg-eye {
          top: 18px;
          left: 93%;
          cursor: pointer;
        }
        input::placeholder {
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
        input::-webkit-input-placeholder {
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
        input::-moz-placeholder {
          /* Firefox 19+ */
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
        input:-ms-input-placeholder {
          /* IE 10+ */
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
        input:-moz-placeholder {
          /* Firefox 18- */
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
      }
    }
    .delete-field-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      //justify-content: space-between;
      gap: 24px;
      padding-top: 42px;
      //padding-bottom: 24px;
      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .success-header-container {
        display: flex;
        flex-direction: column;
        // max-width: 286px;
        justify-content: center;
        gap: 12px;
        align-items: center;
        .success-header {
          max-width: 338px;
          span {
            font-family: "Inter-Semibold";
            font-size: 18px;
            line-height: 28px;
            color: #222222;
          }
        }
        .success-subheader {
          max-width: 286px;
          span {
            color: #7a7a7a;
            font-size: 14px;
            line-height: 22px;
            font-family: "Inter";
          }
        }
        .select-container {
          cursor: pointer;
          width: 100%;
          height: 44px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            background-color: $grey-variation-5;
            display: flex;
            min-height: 40px;
            height: 48px;
            cursor: pointer;
            width: 100% !important;
            background-color: #f9f9fc;
            border-radius: 8px;
            .selectcategory__value-container {
              flex-wrap: nowrap;
              display: flex;
              height: 48px;
              .selectcategory__single-value {
                text-align: start;
                font-size: 14px;
              }
            }
            &:hover {
              border: 0.5px solid $green;
            }
          }
          .selectcategory__control--is-focused {
            border: 0.5px solid $green;
            box-shadow: 0 0 0 0.5px $green;
          }
          .selectcategory__menu {
            z-index: 1099;
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
    .success-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      justify-content: center;
      gap: 24px;
      padding-top: 42px;
      .img-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .success-header-container {
        display: flex;
        flex-direction: column;
        // max-width: 286px;
        justify-content: center;
        gap: 12px;
        align-items: center;
        .success-header {
          span {
            font-family: "Inter-Semibold";
            font-size: 18px;
            line-height: 20px;
            color: #222222;
          }
        }
        .success-subheader {
          max-width: 286px;
          span {
            color: #7a7a7a;
            font-size: 14px;
            line-height: 22px;
            font-family: "Inter";
          }
        }
      }
    }
    .button-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
      padding-right: 32px;
      padding-bottom: 24px;
      padding-top: 5px;
      &.center-align {
        //flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 0px;
        padding-top: 30px;
        gap: 16px;
        button {
          width: 144px;
        }
      }
      &.button-delete {
        button {
          width: 92px;
        }
      }
      button {
        height: 40px;
        border-radius: 10px;
        //font-weight: $font-weight-500;
        font-size: $font-size-14;
        line-height: $line-height-16;
        letter-spacing: $letter-spacing-point1;
        font-family: "Inter";
        &.medium-style {
          font-family: "Inter-Medium";
        }
      }
    }
  }

  // .form-parent {
  //   padding-left: 24px;
  //   padding-right: 24px;
  //   padding-top: 6px;
  //   padding-bottom: 24px;
  //   background-color: $white;
  //   opacity: 1;
  //   border: 1px solid $white;
  //   border-radius: 10px;
  //   //z-index: 999;
  //   .form-header {
  //     display: flex;
  //     flex-direction: row;
  //     gap: 190px;
  //     height: 49px;
  //     border-bottom: 1px solid #e1e1e1;
  //     .header {
  //       display: flex;
  //       align-items: center;
  //       span {
  //         font-weight: $font-weight-600;
  //         font-size: $font-size-18;
  //         line-height: 25.2px;
  //         color: #10202f;
  //       }
  //     }
  //     .close-img {
  //       display: flex;
  //       align-items: center;
  //       span {
  //         color: $grey_variation-3;
  //         cursor: pointer;
  //       }
  //     }
  //   }
  //   .field-container {
  //     text-align: start;
  //     padding-top: 24px;
  //     padding-bottom: 24px;
  //     .form-group {
  //       margin-bottom: 15px;
  //       label {
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-16;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $black_variation-2;
  //         margin-bottom: 8px;
  //       }
  //       input {
  //         height: 48px;
  //         border-radius: 6px;
  //       }
  //       .cg-eye {
  //         top: 18px;
  //         left: 93%;
  //         cursor: pointer;
  //       }
  //       input::placeholder {
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //       input::-webkit-input-placeholder {
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //       input::-moz-placeholder {
  //         /* Firefox 19+ */
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //       input:-ms-input-placeholder {
  //         /* IE 10+ */
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //       input:-moz-placeholder {
  //         /* Firefox 18- */
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //     }
  //   }
  //   .button-container {
  //     display: flex;
  //     flex-direction: row-reverse;
  //     gap: 20px;
  //     button {
  //       height: 40px;
  //       border-radius: 10px;
  //       font-weight: $font-weight-500;
  //       font-size: $font-size-14;
  //       line-height: $line-height-16;
  //       letter-spacing: $letter-spacing-point1;
  //     }
  //   }
  // }
}
.info-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  height: auto;
  .react-tooltip-lite {
    max-width: 320px !important;
    max-height: 90px;
    background: #606060;
    border: 1px solid #606060;
    line-height: 18px;
    padding: 8px 12px 8px 12px !important;
  }
  .react-tooltip-lite-arrow {
    border-color: #606060;
    border-top: 10px solid #606060;
  }
  .react-tooltip-lite-right-arrow {
    border-color: #606060;
  }
}
