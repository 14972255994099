@import "../../assets/styles/variables.scss";
.form-container {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .form-parent {
    opacity: 1;
    border-radius: 10px;
    width: 488px;
    background-color: white;
    .form-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 49px;
      border-bottom: 1px solid #e1e1e1;
      background-color: white;
      position: fixed;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      width: 488px;
      z-index: 999;
      .header {
        display: flex;
        align-items: center;
        padding-left: 24px;
        span {
          font-weight: $font-weight-600;
          font-size: $font-size-18;
          line-height: 25.2px;
          color: #222222;
        }
      }
      .close-img {
        display: flex;
        align-items: center;
        padding-right: 24px;
        span {
          color: $grey_variation-3;
          cursor: pointer;
        }
      }
    }
    .field-container {
      text-align: start;
      padding-top: 74px;
      //padding-bottom: 24px;
      height: 534px;
      padding-left: 24px;
      padding-right: 24px;
      overflow: auto;
      .form-group {
        margin-bottom: 24px;
        .label-parent {
          display: flex;
          justify-content: space-between;
          .label-container {
            display: flex;
            gap: 4px;
            label {
              font-weight: $font-weight-400;
              font-size: $font-size-14;
              line-height: $line-height-16;
              letter-spacing: $letter-spacing-point1;
              color: $black_variation-2;
              margin-bottom: 12px;
            }
            .img-container {
              height: 14px;
              width: 14px;
              display: flex;
              justify-content: center;
              align-items: center;
              .info-tooltip {
                display: flex;
                justify-content: center;
                align-items: center;
                img {
                  margin-top: 2px;
                  height: 100%;
                  width: 100%;
                  cursor: pointer;
                }
              }
            }
          }
          a {
            font-size: $font-size-14;
            line-height: $line-height-16;
            font-weight: $font-weight-400;
            text-decoration: none;
            text-align: center;
            color: #0597f2;
            letter-spacing: 0.2px;
            img {
              margin-bottom: 2px;
            }
          }
        }
        input {
          height: 48px;
          border-radius: 6px;
          font-size: 14px;
        }
        .cg-eye {
          top: 18px;
          left: 93%;
          cursor: pointer;
        }
        input::placeholder {
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
        input::-webkit-input-placeholder {
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
        input::-moz-placeholder {
          /* Firefox 19+ */
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
        input:-ms-input-placeholder {
          /* IE 10+ */
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
        input:-moz-placeholder {
          /* Firefox 18- */
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing-point1;
          color: $grey_variation-1;
        }
      }
    }
    .button-container {
      display: flex;
      flex-direction: row-reverse;
      gap: 20px;
      padding-right: 24px;
      padding-bottom: 24px;
      padding-top: 5px;
      button {
        height: 40px;
        border-radius: 10px;
        font-weight: $font-weight-500;
        font-size: $font-size-14;
        line-height: $line-height-16;
        letter-spacing: $letter-spacing-point1;
      }
    }
  }

  // .form-parent {
  //   padding-left: 24px;
  //   padding-right: 24px;
  //   padding-top: 6px;
  //   padding-bottom: 24px;
  //   background-color: $white;
  //   opacity: 1;
  //   border: 1px solid $white;
  //   border-radius: 10px;
  //   //z-index: 999;
  //   .form-header {
  //     display: flex;
  //     flex-direction: row;
  //     gap: 190px;
  //     height: 49px;
  //     border-bottom: 1px solid #e1e1e1;
  //     .header {
  //       display: flex;
  //       align-items: center;
  //       span {
  //         font-weight: $font-weight-600;
  //         font-size: $font-size-18;
  //         line-height: 25.2px;
  //         color: #10202f;
  //       }
  //     }
  //     .close-img {
  //       display: flex;
  //       align-items: center;
  //       span {
  //         color: $grey_variation-3;
  //         cursor: pointer;
  //       }
  //     }
  //   }
  //   .field-container {
  //     text-align: start;
  //     padding-top: 24px;
  //     padding-bottom: 24px;
  //     .form-group {
  //       margin-bottom: 15px;
  //       label {
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-16;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $black_variation-2;
  //         margin-bottom: 8px;
  //       }
  //       input {
  //         height: 48px;
  //         border-radius: 6px;
  //       }
  //       .cg-eye {
  //         top: 18px;
  //         left: 93%;
  //         cursor: pointer;
  //       }
  //       input::placeholder {
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //       input::-webkit-input-placeholder {
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //       input::-moz-placeholder {
  //         /* Firefox 19+ */
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //       input:-ms-input-placeholder {
  //         /* IE 10+ */
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //       input:-moz-placeholder {
  //         /* Firefox 18- */
  //         font-weight: $font-weight-400;
  //         font-size: $font-size-14;
  //         line-height: $line-height-20;
  //         letter-spacing: $letter-spacing-point1;
  //         color: $grey_variation-1;
  //       }
  //     }
  //   }
  //   .button-container {
  //     display: flex;
  //     flex-direction: row-reverse;
  //     gap: 20px;
  //     button {
  //       height: 40px;
  //       border-radius: 10px;
  //       font-weight: $font-weight-500;
  //       font-size: $font-size-14;
  //       line-height: $line-height-16;
  //       letter-spacing: $letter-spacing-point1;
  //     }
  //   }
  // }
}
.info-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  height: auto;
  .react-tooltip-lite {
    max-width: 320px !important;
    max-height: 90px;
    background: #606060;
    border: 1px solid #606060;
    line-height: 18px;
    padding: 8px 12px 8px 12px !important;
  }
  .react-tooltip-lite-arrow {
    border-color: #606060;
    border-top: 10px solid #606060;
  }
  .react-tooltip-lite-right-arrow {
    border-color: #606060;
  }
}
