@import "../../assets/styles/variables.scss";
.view-ctrl-container {
  width: calc(100% - 90px);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0px 24px;
  //overflow: auto;
  gap: 20px;
  @media (max-height: 700px) {
    padding-top: 20px;
    gap: 20px;
  }
  background: #f5f5f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.no-sidebar {
    width: 100%;
  }
  .ctrl-main-block {
    display: flex;
    flex-direction: column;
    gap: 0px;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    position: relative;
    height: 100%;
    overflow: hidden;
    background-color: #ffffff;
    .submodule-header {
      display: flex;
      // padding: 0px 24px;
      align-items: center;
      gap: 16px;
      border-bottom: 1px solid #12222222;
      position: relative;
      height: 64px;
      z-index: 90;
      @media (max-height: 700px) {
        height: 55px;
      }
      width: -moz-available; /* WebKit-based browsers will ignore this. */
      width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
      width: stretch;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      max-width: inherit;
      //margin-right: 24px;
      // z-index: 90;
      background-color: #ffffff;
      // overflow: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .absolute-submodule {
        // position: absolute;
        height: 64px;
        @media (max-height: 700px) {
          height: 55px;
        }
        padding: 0px 24px;
        width: 100%;
        overflow-x: auto;
        display: flex;
        gap: 16px;
        align-items: center;
        .item {
          padding: 18px 8px;
          @media (max-height: 700px) {
            padding: 13px 8px;
          }
          text-align: start;
          cursor: pointer;
          display: flex;
          align-items: center;
          white-space: pre;
          position: relative;
          height: 100%;
          span {
            font-size: 16px;
            line-height: 26px;
            letter-spacing: 0.2px;
            color: $black_variation-2;
          }
          &.active {
            //background-color: #ecf9f3;
            //border-bottom: 4px solid $green;
            span {
              color: $green;
              font-family: "Inter-Medium";
              //border-bottom: 4px solid $green;
            }
            .underline_div {
              display: block;
              border: 1px solid $green;
            }
          }
          &:hover {
            //background-color: #f7f7f7;
            // border-bottom: 4px solid #bdbdbd;
            .underline_div {
              display: block;
              border: 1px solid #bdbdbd;
            }
          }
          .underline_div {
            height: 1px;
            position: absolute;
            width: 100%;
            top: 97%;
            left: 0px;
            display: none;
          }
        }
      }
    }
  }
  .viewctrl-header-container {
    display: flex;
    text-align: start;
    align-items: center;
    justify-content: space-between;
    .header-block {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .breadcrumbs-container {
        color: $grey_variation-3;
        font-size: $font-size-14;
        font-family: "Inter";
        line-height: $line-height-18;
        letter-spacing: $letter-spacing;
        a {
          &:hover {
            text-decoration: underline !important;
          }
        }
        span {
          color: $grey_variation-3;
          font-size: $font-size-14;
          font-family: "Inter";
          line-height: $line-height-18;
          letter-spacing: $letter-spacing;
        }
      }
      span {
        font-family: "Inter-Semibold";
        font-size: 22px;
        line-height: 28px;
        color: #222;
        letter-spacing: $letter-spacing-point4;
      }
    }
    button {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 40px;
      border-radius: 10px;
      max-width: 200px;
      span {
        font-size: 14px;
        font-family: "Inter-Medium";
        letter-spacing: 0.2px;
        line-height: 16px;
        color: #ffffff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
