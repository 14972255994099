@import "../../assets/styles/variables.scss";
.cr-main-wrapper {
  overflow-y: scroll;
}
.cr-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  overflow-y: scroll;
  padding: 20px 32px 16px 32px;

  .cr-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .date-tab-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
    }
    p {
      margin: 0;
      @include semibold(20px, 26px);
    }
    .tabs-container {
      @include regulatTabs();
      height: 38px;
    }
    .select-container {
      width: 230px;
      height: 40px;
      @include calendarDropdowns();
    }
  }
  .grid-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .stack-graph-container {
    width: 100%;
    .highcharts-title {
      display: none !important;
    }
  }
  .highcharts-credits{
    display: none !important;
  }
}
