@import "../../assets/styles/variables.scss";
.whoverview-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 25px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 32px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      .header {
        text-align: start;
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: $font-weight-600;
          //letter-spacing: $letter-spacing-point4;
          color: $black_variation-2;
        }
      }
      .subheader-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        .sub-header {
          text-align: start;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #7a7a7a;
            .subheader-bold {
              font-weight: 600;
              color: #353a3f;
            }
          }
        }
      }
    }
    .top-filter {
      display: flex;
      gap: 20px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        label {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        @include calendarDropdowns();
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .grid-block {
    width: 100%;
    display: flex;
    gap: 24px;
    border-radius: 12px;
    padding: 16px 0px;
    flex-direction: column;
    background-color: $white;
    .grid-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      .grid-header-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .grid-header {
          text-align: start;
          span {
            font-size: $font-size-24;
            line-height: 24px;
            font-weight: $font-weight-600;
            //letter-spacing: $letter-spacing-point4;
            color: $black_variation-2;
          }
        }
      }
      .grid-filter-container {
        display: flex;
        gap: 20px;
        .filter {
          height: 40px;
          display: flex;
          gap: 10px;
          border: 1px solid #e3e3e8;
          border-radius: 6px;
          padding: 0px 15px;
          cursor: pointer;
          .filter-img {
            height: 100%;
            width: 16px;
            align-items: center;
            text-align: center;
            display: flex;
            img {
            }
          }
          .filter-text {
            height: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            span {
              font-weight: $font-weight-500;
              font-size: $font-size-14;
              line-height: $line-height-16;
              letter-spacing: $letter-spacing-point1;
            }
          }
        }
        .select-container {
          cursor: pointer;
          width: 230px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            background-color: $grey-variation-5;
            display: flex;
            min-height: 40px;
            height: auto !important;
            width: 100% !important;
            background-color: #f9f9fc;
            border-radius: 8px;
            .selectcategory__single-value {
              text-align: start;
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
    .grid-filters {
      background-color: $grey-variation-11;
      padding: 16px;
      display: flex;
      gap: 20px;
      .filter-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .filter-label {
          text-align: start;
          span {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
          }
        }
        .select-container {
          width: 236px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            background-color: $grey-variation-5;
            display: flex;
            min-height: 50px;
            height: auto !important;
            width: 100% !important;
            .selectcategory__single-value {
              text-align: start;
            }
            .selectcategory__multi-value {
              background-color: $grey-variation-2;
              .selectcategory__multi-value__remove {
                svg {
                  fill: #60ca9a;
                  stroke: #60ca9a;
                }
                &:hover {
                  background-color: transparent;
                }
              }
            }
            .selectcategory__indicators {
              .selectcategory__clear-indicator {
                display: none;
              }
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: $grey-variation-3;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
  }
  .load {
    background-color: transparent;
  }
}
