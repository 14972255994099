.custom__button {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #fff 0%, #fafafb 100%);
  font-size: 12px;
  color: #222;
  font-family: "Inter-Medium";
  border-radius: 6px;
  border: 1px solid #e3e3e9;
  padding: 9px 10px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
  font-family: "Inter-Medium";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  outline: none;
  cursor: pointer;
  &:hover {
    border: 1px solid #60ca9a;
  }

}
.disabled__button{
cursor: not-allowed !important;
}