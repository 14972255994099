.not-available-container {
  width: 100%;
  min-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: transparent;
  img {
    width: 217.966px;
    height: 172.75px;
  }
  p {
    color: #7a7a7a;
    text-align: center;
    font-family: "Inter-medium";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
}
