@import "../../assets/styles/variables.scss";
.iframe-container {
  //width: calc(100% - 240px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  top: -40px; // For hiding the hamburger menu in iframes ref: Bhargav
  // padding-left: 20px;
  // padding-right: 20px;
  .cg-frame {
    -ms-zoom: 0.95;
    -moz-transform: scale(0.95);
    -moz-transform-origin: top center;
    -o-transform: scale(0.95);
    -o-transform-origin: top center;
    -webkit-transform: scale(0.95);
    -webkit-transform-origin: top center;
  }
}
