@import "../../assets/styles/variables.scss";
.failedqueries-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 32px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      margin-top: 10px;
      .header {
        text-align: start;
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: $font-weight-600;
          color: $black_variation-2;
          letter-spacing: $letter-spacing;
        }
      }
    }
    .top-filter {
      display: flex;
      gap: 20px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        label {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        .selectcategory__control {
          display: flex;
          min-height: 38px;
          cursor: pointer;
          height: auto !important;
          width: 100% !important;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
          border-radius: 6px;
          background: linear-gradient(
              180deg,
              #fff 0%,
              #fafafb 100%
            ) !important;
          &:active {
            border: 1px solid #60ca9a;
            background: #f7f7f7 !important;
          }
          &:hover {
            border-radius: 6px;
            border: 1px solid #cfcfd6;
            background: #f7f7f7 !important;
          }
          .selectcategory__single-value {
            text-align: start;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            font-family: "Inter-medium";
            color: #222;
            &::before {
              content: url(../../assets/images/common/calendar.svg);
              margin-right: 8px;
              margin-top: 4px;
            }
          }
          &.selectcategory__control--is-focused {
            box-shadow: none !important;
            border: 1px solid $black_variation-2 !important;
            outline: none !important;
            font-size: $font-size-14 !important;
            border: 1px solid #60ca9a !important;
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              font-size: $font-size-14;
              font-weight: $font-weight-500;
              line-height: 16px;
              text-align: start;
              height: 36px;
              color: #353a3f;
              background: none;
              cursor: pointer;
              &.selectcategory__option--is-focused {
                background: #ecf8f4;
              }
              &.selectcategory__option--is-selected {
                font-family: "Inter-Medium";
                color: #353a3f;
              }
            }
          }
        }
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .stats__parent {
    display: flex;
    gap: 16px;
    .donut-main-container {
      width: 70%;
      display: flex;
      min-height: 256px;
      position: relative;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      background: transparent;
      border-radius: 6px;
      .title-container {
        padding: 8px 12px !important;
      }
      .empty__donut {
        flex-basis: 50%;
        min-width: 50%;
        background: #ffffff;
        border-radius: 6px;
        align-self: stretch;
        border: 1px solid #ededed;
        background: #fff;
        box-shadow: 0px 2px 6px 0px rgba(18, 18, 18, 0.1);
        justify-content: center;
        align-items: center;
        display: flex;
        span {
          font-size: 12px;
          color: #222;
        }
      }

      .donut-chart-container {
        //flex-basis: 48%;
        flex-basis: 50%;
        min-width: 50%;
        background: #ffffff;
        border-radius: 6px;
        align-self: stretch;
        border: 1px solid #ededed;
        background: #fff;
        box-shadow: 0px 2px 6px 0px rgba(18, 18, 18, 0.1);
        .donut-chart {
          width: 100%;
          height: 30vh;
          padding: 0px;

          .chart-container {
            width: 100%;
            height: 100%;
            padding: 8px;
          }
          div {
            width: 100%;
            height: 100%;
          }
        }
        .highcharts-credits {
          display: none !important;
        }
        .truncate {
          width: 27px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .highcharts-data-label {
          width: 20px !important;
          height: 12px !important;
        }
      }

      .title-container {
        width: 100%;
        border-bottom: 1px solid #ebebeb;
        text-align: left;
        padding: 12px 16px;
        h5 {
          color: #222;
          font-family: "Inter-medium";
          font-size: 16px;
          font-style: normal;
          line-height: 30px;
          margin-bottom: 0px !important;
        }
        p {
          color: var(--gray-shades-shades-1, #7a7a7a);
          font-family: "Inter-medium";
          font-size: 12px;
          font-style: normal;
          line-height: 18px;
          margin-bottom: 0px !important;
        }
      }

      .spline-chart-container {
        flex-basis: 49%;
        flex-grow: 1;
        background: #ffffff;
        border-radius: 6px;

        .select-container {
          border-bottom: none !important;
          path {
            fill: red !important;
          }
        }
        .spline-header-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #ebebeb;
        }
        .spline-chart {
          width: 100%;
          border-radius: 6px;
          padding: 0px 16px !important;
          padding-bottom: 16px !important;
        }
        .highcharts-credits {
          display: none !important;
        }
        .filter-container {
          padding: 16px;
        }
      }
    }
    .stats__container {
      display: flex;
      flex-direction: row;
      gap: 16px;
      width: 30%;
      flex-wrap: wrap;
      .summary-cards-container {
        flex-basis: 42%;
        min-width: 42%;
        min-height: 118px;
        // max-width: 310px;
        display: flex;
        flex-grow: 1;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        gap: 8px;
        border-radius: 6px;
        border: 1px solid #ededed;
        box-shadow: 0px 2px 6px 0px rgba(18, 18, 18, 0.1);
        .title {
          color: var(--Dark---main, #222);
          text-align: center;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 21px;
          margin-bottom: 0px !important;
        }
        .summary {
          color: var(--primary-shade-2, #4baa7e);
          text-align: center;
          font-family: "Inter-medium";
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: 36px; /* 150% */
          margin-bottom: 0px !important;
        }
      }
    }
  }
  .tabs__container {
    display: flex;
    gap: 12px;
    max-width: fit-content;
    padding: 6px;
    background-color: #f7f7f9;
    border-radius: 6px;
    margin-top: 16px;
    @media (max-height: 700px) {
      margin-top: 8px;
    }
    align-items: center;
    width: auto;
    .tab {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 4px;
      background: #f7f7f9;
      padding: 6px 8px;
      border-radius: 6px;
      cursor: pointer;
      span {
        color: #8a8a8f;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        &:hover {
          color: #222;
        }
      }
      &.active {
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
        background: #fff;
        span {
          color: #4baa7e;
          font-size: 14px;
          font-family: "Inter-medium";
          line-height: 20px;
          &:hover {
            color: #4baa7e;
          }
        }
      }
    }
  }
  .grid-block {
    width: 100%;
    display: flex;
    gap: 12px;
    //border-radius: 12px;
    border-top: 1px solid #f5f5f5;
    padding: 16px 0px;
    flex-direction: column;
    background-color: $white;
    .grid-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      .grid-header-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .grid-header {
          text-align: start;
          span {
            font-size: $font-size-18;
            line-height: 22px;
            font-weight: $font-weight-600;
            //letter-spacing: $letter-spacing-point4;
            color: $black_variation-2;
          }
        }
      }
      .grid-filter-container {
        width: 100%;
        display: flex;
        gap: 12px;
        justify-content: space-between;
        .filter {
          height: 40px;
          display: flex;
          gap: 10px;
          border: 1px solid #e3e3e8;
          border-radius: 6px;
          padding: 0px 15px;
          cursor: pointer;
          .filter-img {
            height: 100%;
            width: 16px;
            align-items: center;
            text-align: center;
            display: flex;
            img {
            }
          }
          .filter-text {
            height: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            span {
              font-weight: $font-weight-500;
              font-size: $font-size-14;
              line-height: $line-height-16;
              letter-spacing: $letter-spacing-point1;
            }
          }
        }
        .select-container {
          cursor: pointer;
          width: 230px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          position: relative !important;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            display: flex;
            min-height: 38px;
            cursor: pointer;
            height: auto !important;
            width: 100% !important;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
            border-radius: 6px;
             border-color: #E3E3E8;
            background: linear-gradient(
              180deg,
              #fff 0%,
              #fafafb 100%
            ) !important;
            &:active {
              border: 1px solid #60ca9a !important;
              background: #f7f7f7 !important;
            }
            &:hover {
              border-radius: 6px;
              border: 1px solid #cfcfd6 !important;
              background: #f7f7f7 !important;
            }
            .selectcategory__single-value {
              text-align: start;
              font-size: 14px;
              font-weight: 500;
              font-family: "Inter-Medium";
              color: #222222;
            }
            &.selectcategory__control--is-focused {
              box-shadow: none !important;
              outline: none !important;
              border: 1px solid #60ca9a !important;
              font-size: $font-size-14 !important;
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 36px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background: #ecf8f4;
                }
                &.selectcategory__option--is-selected {
                  font-family: "Inter-Medium";
                  color: #353a3f;
                }
              }
            }
          }
        }
      }
    }
    .grid-filters {
      background-color: $grey-variation-11;
      padding: 16px;
      display: flex;
      gap: 20px;
      .filter-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .filter-label {
          text-align: start;
          span {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
          }
        }
        .select-container {
          width: 236px;
          cursor: pointer;
          .selectcategory__control {
            display: flex;
            min-height: 50px;
            height: auto !important;
            width: 100% !important;
            .selectcategory__single-value {
              text-align: start;
            }
            .selectcategory__multi-value {
              background-color: $grey-variation-2;
              .selectcategory__multi-value__remove {
                svg {
                  fill: #60ca9a;
                  stroke: #60ca9a;
                }
                &:hover {
                  background-color: transparent;
                }
              }
            }
            .selectcategory__indicators {
              .selectcategory__clear-indicator {
                display: none;
              }
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: $grey-variation-3;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
    &.inactive {
      display: none;
    }
    &.active {
      display: flex;
    }
  }
  .grid-container {
    .download-link {
      height: 28px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
    .download-loader {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 5px solid $green_variation-2;
      animation: 1s pre linear infinite;
    }
    @keyframes pre {
      0% {
        transform: rotate(0deg);
        border-top: 5px solid $green;
      }
      100% {
        transform: rotate(360deg);
        border-top: 5px solid $green;
      }
    }
  }
  .load {
    background-color: transparent;
  }
}
.select-export{
  width: fit-content !important;
}
.select-filter__wrapper{
display: flex;
flex-direction: row;
justify-content: flex-start;
gap: 12px;
}
.fq-filter-button {
  max-width: 85px;
  height: 36px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 7px 8px !important;
  position: relative !important;
  &:hover {
    border: 1px solid #cfcfd6 !important;
  }
}
