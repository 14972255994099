@import "../../assets/styles/variables.scss";

.top-user-container {
  width: 100%;
  background: #ffff;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
  box-shadow: 0px 1px 8px 0px rgba(143, 143, 143, 0.12);

  .title-section {
    width: 100%;
    text-align: left;
    p,
    h5 {
      @include medium(20px, 30px);
      margin: 0;
      padding: 0;
    }
  }
  .data-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    table {
      width: 100%;
      border-radius: 6px;

      .name-section {
        width: 100px;
        text-align: left;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .graph-section {
        width: 48%;
      }
      .cost-section {
        width: 20%;
        text-align: right;
      }
    }
    thead {
      width: 100%;
      height: 42px;

      border-radius: 6px;
      tr {
        position: relative;
        height: 42px;
      }
      tr::after {
        content: "";
        position: absolute;
        inset: 0 -0.5px -1px;
        background: #f5f5f5;
        height: 42px;
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
        border: 1px solid #ebebeb;
      }
      td {
        position: relative;
        z-index: 1;
      }
      td:nth-child(1) {
        min-width: 144px;
      }
      td:nth-child(2) {
        min-width: 124px;
      }
      td:nth-child(4) {
        min-width: 110px;
        text-align: end;
      }

      td {
        @include medium(14px, 21px);
        padding: 4px 8px;
        text-align: left;
      }
    }
    tbody {
      width: 100%;
      height: 42px;
      tr {
        height: 42px;
        border: 1px solid #ebebeb;
        position: relative;
      }
      tr:last-child {
        border: none;
      }

      tr:last-child::after {
        content: "";
        position: absolute;
        inset: 0 -0.5px -1px;
        height: 42px;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
        border: 1px solid #ebebeb;
      }
      td:nth-child(1) {
        min-width: 144px;
      }
      td:nth-child(2) {
        min-width: 124px;
      }
      td:nth-child(4) {
        min-width: 110px;
        text-align: end;
      }
      td {
        position: relative;
        height: 42px;
        @include medium(12px, 18px);
        padding: 0px 8px;
        text-align: left;
        z-index: 2;
      }
    }
  }
}
