@import "../../assets/styles/variables.scss";
.fallback-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  .image-container {
    display: flex;
    .image {
      height: 64px;
      width: 64px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .line-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .line {
        border-bottom: 3px solid #d4d4d4;
        border-style: none none dotted;
        width: 54px;
        height: 0px;
      }
    }
  }
  .text-container {
    width: 770px;
    span {
      font-weight: $font-weight-500;
      font-size: $font-size-24;
      line-height: $line-height-36;
      color: $black_variation-2;
      a {
        text-decoration: none;
        color: $green;
        span {
          font-weight: 900;
          font-size: 17px;
          line-height: 36px;
          color: #222222;
          color: $green;
        }
      }
    }
  }
}
