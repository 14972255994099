@import "../../assets/styles/variables.scss";
.filter-main-container {
  min-width: 600px;
  // min-height: 400px;
  // max-height: 400px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  // gap: 12px;
  border-radius: 6px;
  border: 1px solid #e4e4e7;
  background: #fff;
  box-shadow: -2px 2px 8px 0px rgba(18, 18, 18, 0.12);
  position: absolute;
  top: 45px;
  z-index: 15;

  .filter-row-container {
    width: 100%;
    max-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 12px;
    z-index: 3;
    padding: 16px;

    .filter-row-sub-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 12px;
      .filter-type-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding: 0px 4px;
        @include regularDropdowns();
      }
    }
    .filter__row {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      .filter__section {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        gap: 12px;
        padding: 0px 4px;
      }
      .grid-column-select {
        width: 159px;
        height: 38px;
        // position: relative;
        @include regularDropdowns();
      }
      .grid-operator-select {
        width: 160px;
        height: 38px;
        @include regularDropdowns();
      }
      .grid-value-select {
        width: 221px;
        min-height: 38px;
        position: relative;
        @include regularDropdowns();
        .css-b62m3t-container {
          position: none;
        }
        .selectcategory__single-value {
          text-align: start;
          font-size: 14px;
          font-weight: 500;
          margin: 0px !important;
          display: flex;
          align-items: center;
          font-family: "Inter-Medium";
          color: #222222 !important;
          &::before {
            content: "" !important;
            margin-right: 8px;
            margin-top: 4px;
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              width: max-content !important;
              min-width: 221px !important;
              display: flex !important;
              font-size: $font-size-14 !important;
              font-weight: $font-weight-500 !important;
              line-height: 16px !important;
              text-align: start !important;
              height: 36px !important;
              color: #353a3f !important;
              background: none !important;
              cursor: pointer !important;
              z-index: 99 !important;
              // overflow-x: auto;
              &.selectcategory__option--is-focused {
                width: max-content;
                background: #ecf8f4 !important;
              }
              &.selectcategory__option--is-selected {
                font-family: "Inter-Medium";
                color: #353a3f;
              }
              .dropdown-list-item {
                width: 100%;
                flex-grow: 1;
                label {
                  width: 100% !important;
                }
              }
            }
          }
        }
        .filter-button {
          height: 38px;
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          font-family: "Inter-Medium";
          padding: 4px 6px;
          border-radius: 6px;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
          border-radius: 6px;
          background: linear-gradient(180deg, #fff 0%, #fafafb 100%) !important;
          &:hover {
            border-radius: 6px;
            border: 1px solid #cfcfd6;
            background: #f7f7f7 !important;
          }

          span {
            padding-left: 10px;
            color: #a3a3a3;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
          }
          .chip-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            gap: 12px;
          }
          .chip {
            min-width: 30px;
            width: 40%;
            padding: 4px;
            border-radius: 4px;
            background: #ebebeb;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #222;
            font-size: 12px;
          }
          .chip2 {
            width: 45px;
            padding: 4px;
            border-radius: 4px;
            background: #ebebeb;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #222;
            font-size: 12px;
          }
        }
        /* Style the checkbox */
        input[type="checkbox"] {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          width: 14px;
          height: 14px;
          background-color: #fff; /* Unchecked background color */
          border: 1px solid #222; /* Checked background color */
          border-radius: 3px;
          outline: none;
          cursor: pointer;
          position: "relative";
        }

        /* Style the checked state of the checkbox */
        input[type="checkbox"]:checked {
          background-color: #60ca9a; /* Checked background color */
          border-color: none;
          border: 1px solid #60ca9a;
          border-radius: 3px;
          /* Border color for the checked checkbox */
        }

        /* Style the checkmark inside the checkbox */
        input[type="checkbox"]:checked::after {
          content: "\2713"; /* Unicode checkmark character */
          display: block;
          width: 14px;
          height: 14px;
          text-align: center;
          line-height: 10px;
          color: #fff; /* Color of the checkmark */
          font-size: 12px;

          font-weight: bold;
          position: absolute;
          top: 1px;
          left: -1px;
          outline: none;
        }
      }
    }
    &:last-child {
      padding-bottom: 30px;
    }
  }

  .filter-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px 16px 16px;

    p {
      max-width: 150px;
      color: #0597f2;
      border: none;
      box-shadow: none;
      padding: 0px;
      text-align: left;
      font-family: "Inter-Medium";
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      cursor: pointer;
      margin: 0;
      &:hover {
        color: #0483d2;
      }
    }
    .filter-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      gap: 12px;
    }
    .button-primary {
      display: flex;
      width: 110px;
      height: 36px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: #60ca9a;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
      color: #fff;
      color: var(--White, #fff);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
      border: none;
      &:hover {
        background: #4baa7e;
        border: none;
      }
    }
    .clear__button {
      display: flex;
      width: 110px;
      height: 36px;
      padding: 8px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.1px;
      font-family: "Inter-Medium";
      border-radius: 4px;
      border: 1px solid #e4e4e7;
      background: linear-gradient(180deg, #fff 0%, #fafafb 100%);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
      color: #7a7a7a;
      &:hover {
        border: 1px solid #cfcfd6;
        background: #f7f7f7;
        color: #222;
      }
    }
  }
  .delete-filter:hover {
    svg {
      path {
        stroke: #e96560 !important;
      }
    }
  }

  .menuItems {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 12px;
  }
  .dropdown-list-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
  }
}
.filter-button-disabled {
  background: #a0dfc2 !important;
  color: #f5f5f5 !important;
  cursor: not-allowed;
  .grid-column-select {
    width: 159px;
    height: 38px;
    padding-bottom: 30px;
  }
}
.overflow-y {
  overflow-y: auto;
  padding-bottom: 52px;
}
.footer__stroke {
  border-top: 1px solid #ebebeb;
  background: #fff;
  box-shadow: 0px -1px 10px 0px rgba(137, 137, 137, 0.12);
}
