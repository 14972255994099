@import "../../assets/styles/variables.scss";
.org-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 32px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;
      .header {
        text-align: start;
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: $font-weight-600;
          //letter-spacing: $letter-spacing-point4;
          color: $black_variation-2;
        }
      }
      .subheader-container {
        margin: 16px 0px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 440px;
        max-width: 440px;
        .label-container {
          text-align: start;
          label {
            font-size: 14px;
            font-weight: 400;
            line-height: $line-height-16;
            letter-spacing: $letter-spacing-point1;
            color: #222222;
          }
        }
        input {
          width: 100%;
          height: 48px;
          border: 1px solid #e3e3e8;
          border-radius: 6px;
          background-color: #f9f9fc;
          padding: 0px 16px;
          color: #222222;
          font-size: 15px;
          line-height: 18.15px;
        }
      }
    }
    .top-filter {
      display: flex;
      gap: 20px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        label {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        .selectcategory__control {
          display: flex;
          min-height: 40px;
          height: auto !important;
          width: 100% !important;
          background-color: #f9f9fc;
          border-radius: 8px;
          .selectcategory__single-value {
            text-align: start;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              font-size: $font-size-14;
              font-weight: $font-weight-500;
              line-height: 16px;
              text-align: start;
              height: 40px;
              color: #353a3f;
              background: none;
              cursor: pointer;
              &.selectcategory__option--is-focused {
                background-color: #f0faff;
              }
            }
          }
        }
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .grid-block {
    width: 100%;
    padding: 8px 16px;
    display: flex;
    gap: 24px;
    border-radius: 12px;
    padding: 16px 0px;
    flex-direction: column;
    background-color: $white;
    .update__field {
      display: flex;
      align-items: center;
      gap: 6px;
      .delete_user {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $font-size-12;
        line-height: $line-height-16;
        font-weight: $font-weight-400;
        letter-spacing: $letter-spacing;
        color: #e96560;
        display: flex;
        gap: 4px;
        background: transparent;
        border: none;
        margin-top: 4px;
      }
    }
    .login_creds {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      height: 30px;
      .login__setup {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 76px;
        height: 24px;
        border-radius: 38px;
        background: #ebebeb;
        span {
          font-weight: 400;
          color: #222222;
          font-size: 12px;
          text-align: center;
        }
      }
    }
    .grid-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      .grid-header-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .grid-header {
          text-align: start;
          span {
            font-size: $font-size-18;
            line-height: 22px;
            font-weight: $font-weight-600;
            //letter-spacing: $letter-spacing-point4;
            color: $black_variation-2;
          }
        }
      }
      button {
        height: 40px;
        width: 124px;
        font-family: "inter-Medium";
        font-size: 14px;
        line-height: 16px;
        // font-weight: 500;
        letter-spacing: $letter-spacing;
        display: flex;
        gap: 6px;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
      }
      .grid-filter-container {
        display: flex;
        gap: 20px;
        .filter {
          height: 40px;
          display: flex;
          gap: 10px;
          border: 1px solid #e3e3e8;
          border-radius: 6px;
          padding: 0px 15px;
          cursor: pointer;
          .filter-img {
            height: 100%;
            width: 16px;
            align-items: center;
            text-align: center;
            display: flex;
            img {
            }
          }
          .filter-text {
            height: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            span {
              font-weight: $font-weight-500;
              font-size: $font-size-14;
              line-height: $line-height-16;
              letter-spacing: $letter-spacing-point1;
            }
          }
        }
        .select-container {
          cursor: pointer;
          width: 230px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            display: flex;
            min-height: 40px;
            height: auto !important;
            width: 100% !important;
            background-color: #f9f9fc;
            border-radius: 8px;
            .selectcategory__single-value {
              text-align: start;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
    .grid-filters {
      background-color: $grey-variation-11;
      padding: 16px;
      display: flex;
      gap: 20px;
      .filter-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .filter-label {
          text-align: start;
          span {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
          }
        }
        .select-container {
          width: 236px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            display: flex;
            min-height: 50px;
            height: auto !important;
            width: 100% !important;
            background-color: $grey-variation-5;
            .selectcategory__single-value {
              text-align: start;
            }
            .selectcategory__multi-value {
              background-color: $grey-variation-2;
              .selectcategory__multi-value__remove {
                svg {
                  fill: #60ca9a;
                  stroke: #60ca9a;
                }
                &:hover {
                  background-color: transparent;
                }
              }
            }
            .selectcategory__indicators {
              .selectcategory__clear-indicator {
                display: none;
              }
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: $grey-variation-3;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
  }
  .load {
    background-color: transparent;
  }
}
