@import "../../assets/styles/variables.scss";
.changelog-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  span {
    -webkit-font-smoothing: antialiased;
  }

  //background-color: $grey-variation-11;
  padding: 20px 32px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 10px;
      .header {
        text-align: start;
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: $font-weight-600;
          //letter-spacing: $letter-spacing-point4;
          color: $black_variation-2;
        }
        .tabs__container {
          display: flex;
          gap: 12px;
          max-width: fit-content;
          padding: 6px;
          background-color: #f7f7f9;
          border-radius: 6px;
          margin-top: 0px;
          @media (max-height: 700px) {
            margin-top: 8px;
          }
          align-items: center;
          width: auto;
          .tab {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 4px;
            background: #f7f7f9;
            padding: 6px 8px;
            border-radius: 6px;
            cursor: pointer;
            span {
              color: #8a8a8f;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              &:hover {
                color: #222;
              }
            }
            &.active {
              box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
              background: #fff;
              span {
                color: #4baa7e;
                font-size: 14px;
                font-family: "Inter-medium";
                line-height: 20px;
                &:hover {
                  color: #4baa7e;
                }
              }
            }
            &.disabled {
              pointer-events: none;
              cursor: not-allowed;
              user-select: none;
            }
          }
        }
      }
      .subheader-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        // margin-top: 20px;
        // font-size: 18px;
        // font-weight: $font-weight-600;

        .sub-header {
          text-align: start;
          span {
            font-size: 16px;
            font-weight: 400;
            color: #7a7a7a;
            .subheader-bold {
              font-weight: 600;
              color: #353a3f;
            }
          }
        }
      }
    }
    .filter-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .top-filter {
      display: flex;
      gap: 12px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        position: relative;
        label {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        .selectcategory__control {
          display: flex;
          min-height: 38px;
          cursor: pointer;
          height: auto !important;
          width: 100% !important;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
          border-radius: 6px;
          border-color: #e3e3e8;
          background: linear-gradient(180deg, #fff 0%, #fafafb 100%) !important;

          &:active {
            border: 1px solid #60ca9a;
            background: #f7f7f7 !important;
          }
          &:hover {
            border-radius: 6px;
            border: 1px solid #cfcfd6;
            background: #f7f7f7 !important;
          }
          &.selectcategory__control--is-focused {
            box-shadow: none !important;
            border: 1px solid $black_variation-2 !important;
            outline: none !important;
            font-size: $font-size-14 !important;
            border: 1px solid #60ca9a !important;
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              font-size: $font-size-14;
              font-weight: $font-weight-500;
              line-height: 16px;
              text-align: start;

              height: 36px;
              color: #353a3f;
              background: none;
              cursor: pointer;
              z-index: 99 !important;
              &.selectcategory__option--is-focused {
                background: #ecf8f4;
              }
              &.selectcategory__option--is-selected {
                font-family: "Inter-Medium";
                color: #353a3f;
              }
            }
          }
        }
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .grid-block {
    width: 100%;
    display: flex;
    gap: 24px;
    border-radius: 12px;
    // padding: 16px 0px;
    flex-direction: column;
    background-color: $white;

    .grid-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      .grid-header-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .grid-header {
          text-align: start;
          span {
            font-size: $font-size-24;
            line-height: 24px;
            font-weight: $font-weight-600;
            //letter-spacing: $letter-spacing-point4;
            color: $black_variation-2;
          }
        }
      }
      .grid-filter-container {
        display: flex;
        gap: 20px;
        .filter {
          height: 40px;
          display: flex;
          gap: 10px;
          border: 1px solid #e3e3e8;
          border-radius: 6px;
          padding: 0px 15px;
          cursor: pointer;
          .filter-img {
            height: 100%;
            width: 16px;
            align-items: center;
            text-align: center;
            display: flex;
            img {
            }
          }
          .filter-text {
            height: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            span {
              font-weight: $font-weight-500;
              font-size: $font-size-14;
              line-height: $line-height-16;
              letter-spacing: $letter-spacing-point1;
            }
          }
        }
        .select-container {
          cursor: pointer;
          width: 230px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            display: flex;
            min-height: 38px;
            cursor: pointer;
            height: auto !important;
            width: 100% !important;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
            border-radius: 6px;
            background: linear-gradient(
              180deg,
              #fff 0%,
              #fafafb 100%
            ) !important;

            &:active {
              border: 1px solid #60ca9a;
              background: #f7f7f7 !important;
            }
            &:hover {
              border-radius: 6px;
              border: 1px solid #cfcfd6;
              background: #f7f7f7 !important;
            }
            .selectcategory__single-value {
              text-align: start;
              font-size: 14px;
              font-weight: 500;
              margin: 0px !important;
              display: flex;
              align-items: center;
              font-family: "Inter-Medium";
              color: #222222 !important;
              &::before {
                content: "";
                margin-right: 8px;
                margin-top: 4px;
              }
            }
            &.selectcategory__control--is-focused {
              box-shadow: none !important;
              border: 1px solid $black_variation-2 !important;
              outline: none !important;
              font-size: $font-size-14 !important;
              border: 1px solid #60ca9a !important;
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;

                height: 36px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                z-index: 99 !important;
                &.selectcategory__option--is-focused {
                  background: #ecf8f4;
                }
                &.selectcategory__option--is-selected {
                  font-family: "Inter-Medium";
                  color: #353a3f;
                }
              }
            }
          }
          &#selectSFAcc {
            width: 160px;
          }
          .selectcategory__single-value {
            text-align: start;
            font-size: 14px;
            font-weight: 500;
            display: flex;
            align-items: center;
            &::before {
              content: url(../../assets/images/common/calendar.svg) !important;
              margin-right: 8px;
              margin-top: 4px;
            }
          }
        }
      }
    }
    .grid-filters {
      background-color: $grey-variation-11;
      padding: 16px;
      display: flex;
      gap: 20px;
      .filter-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .filter-label {
          text-align: start;
          span {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
          }
        }
        .select-container {
          width: 236px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            background-color: $grey-variation-5;
            display: flex;
            min-height: 36px;
            height: auto !important;
            width: 100% !important;
            .selectcategory__single-value {
              text-align: start;
            }
            .selectcategory__multi-value {
              background-color: $grey-variation-2;
              .selectcategory__multi-value__remove {
                svg {
                  fill: #60ca9a;
                  stroke: #60ca9a;
                }
                &:hover {
                  background-color: transparent;
                }
              }
            }
            .selectcategory__indicators {
              .selectcategory__clear-indicator {
                display: none;
              }
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: $grey-variation-3;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
  }

  .grid-block:last-child {
    padding-bottom: 32px;
  }

  .load {
    background-color: transparent;
  }
  .export-container {
    width: 90px !important;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .changelog-grid-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  .filter__container {
    .selectcategory__single-value {
      text-align: start;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      align-items: center;
      &::before {
        content: "" !important;
        margin-right: 8px;
        margin-top: 4px;
      }
    }
  }
  .date__filter {
    .selectcategory__single-value {
      text-align: start;
      font-size: 14px;
      font-family: "Inter-Medium";
      font-weight: 500;
      display: flex;
      align-items: center;
      &::before {
        content: url(../../assets/images/common/calendar.svg) !important;
        margin-right: 8px;
        margin-top: 4px;
      }
    }
  }
}
