@import "../../assets/styles/variables.scss";
.dashboardconnection-container {
  span {
    -webkit-font-smoothing: antialiased;
  }
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
  .connection-header {
    //padding-left: 40px;
    display: flex;
    flex-direction: column;
    text-align: start;
    // width: 510px;
    margin-top: 2px;
    margin-bottom: 20px;
    .header {
      font-size: 20px;
      line-height: 24px;
      font-family: "Inter-Semibold";
      //letter-spacing: $letter-spacing-point4;
      color: $black_variation-2;
      margin-bottom: 8px;
    }
    .sub-header {
      font-size: $font-size-14;
      line-height: $line-height-21;
      font-weight: $font-weight-400;
      color: $grey_variation-3;
      margin-top: 8px;
    }
  }
  .cards-parent {
    // padding-left: 32px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 32px;
    &.dashboard-cards {
      .card-container {
        width: 25%;
        display: flex;
        position: relative;
        padding: 10px 0px 10px 18px;
        border: 1px solid $grey-variation-10;
        border-radius: 6px;
        &.selected {
          border: 1px solid #59b2e0;
        }
        cursor: pointer;
        .card-block {
          flex-basis: 50%;
          display: flex;
          flex-direction: column;
          .image-container {
            padding: 2px;
            width: 40px;
            height: 40px;
            margin-top: 10px;
            margin-bottom: 12px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .header-container {
            text-align: start;
            margin-top: 12px;
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;
            gap: 10px;
            .header {
              display: flex;
              text-align: start;
              width: 100%;
              span {
                font-size: $font-size-18;
                line-height: $line-height-16;
                font-family: "Inter-Medium";
                letter-spacing: $letter-spacing;
                color: $black_variation-2;
              }
            }
            .sub-header {
              display: flex;
              text-align: start;
              width: 100%;
              span {
                font-family: "Inter";
                font-size: $font-size-14;
                line-height: $line-height-16;
                letter-spacing: $letter-spacing;
                color: $grey_variation-3;
              }
            }
          }
          .selection-container {
            display: flex;
            flex-direction: column;
            .selected-container {
              display: flex;
              flex-direction: row-reverse;
              margin-top: 8px;
              margin-right: 18px;
            }
            .coming-soon {
              display: flex;
              flex-direction: row-reverse;
            }
          }
        }
      }
    }
  }
}
