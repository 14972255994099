@import "../../assets/styles/variables.scss";
.billing-success-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 24px;
  span {
    -webkit-font-smoothing: antialiased;
  }

  .green-header {
    font-size: 20px;
    color: green;
  }

  //background-color: $grey-variation-11;
  padding: 20px 24px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
}
