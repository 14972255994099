@import "../../assets/styles/variables.scss";
.health__dashboard-container {
  width: calc(100% - 90px);
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 24px 0px 24px;
  //overflow: auto;
  // gap: 20px;
  @media (max-height: 700px) {
    padding-top: 0px;
    // gap: 16px;
  }
  background: #f5f5f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.no-sidebar {
    width: 100%;
  }
  .ctrl-main-block {
    display: flex;
    flex-direction: column;
    gap: 0px;
    border: none;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    position: relative;
    height: 100%;
    overflow: auto;
    background: transparent;
    padding-bottom: 16px;
    .health-container {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;
      @media (max-height: 700px) {
        padding-top: 0px;
        gap: 20px;
      }
      .metric__card__container {
        display: flex;
        flex-direction: column;
        gap: 24px;
        border-radius: 10px;
        background: #fff;
        width: 31%;
        box-shadow: 0px 1px 8px 0px rgba(143, 143, 143, 0.12);
        min-width: 400px;
        @media (max-width: 1399px) {
          width: 31%;
          min-width: 350px;
        }
        @media (max-width: 1260px) {
          min-width: 350px;
        }
        @media (max-width: 1240px) {
          min-width: 300px;
        }
        .card__header__container {
          padding: 16px;
          border-bottom: 1px solid #e8e8e8;
          display: flex;
          text-align: start;
          gap: 4px;
          span {
            font-family: "Inter-Semibold";
            font-size: 18px;
            line-height: 22px;
            color: #222222;
            letter-spacing: 0.4px;
            text-transform: capitalize;
          }
          img {
            width: 24px;
            height: 24px;
          }
        }
        .metric__body__container {
          display: flex;
          flex-direction: column;
          gap: 28px;
          padding: 0px 16px;
          @media (max-height: 700px) {
            gap: 24px;
          }
          .metric__text__container {
            height: 58px;
            text-align: start;
            span {
              .value_text_sm {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: #7a7a7a;
              }
              .value_text_bg_green {
                color: #4baa7e;
                font-size: 28px;
                line-height: 32px; /* 106.667% */
                font-family: "Inter-Semibold";
              }
              .value_text_sm_green {
                font-size: 16px;
                line-height: 24px;
                font-family: "Inter-Medium";
                color: #4baa7e;
              }
            }
          }
          .metric__value__container {
            display: flex;
            justify-content: space-between;
            height: 34px;
            .value_text_sm {
              font-size: 16px;
              line-height: 24px;
              font-weight: 400;
              color: #7a7a7a;
              display: flex;
              align-items: center;
            }
            .value__div {
              display: flex;
              align-items: center;
              .value_text_bg_black {
                color: #222;
                font-size: 24px;
                font-style: normal;
                font-family: "Inter-Medium";
                line-height: 34px;
                letter-spacing: 0.4px;
              }
              .value_text_sm_black {
                color: #222;
                font-family: "Inter-Medium";
                font-size: 16px;
                line-height: 34px;
                letter-spacing: 0.4px;
                margin-top: 2px;
              }
            }
          }
        }
        .metric__footer__container {
          padding: 16px;
          display: flex;
          flex-direction: row-reverse;
          background-color: #f9faf8;
          cursor: pointer;
          border-bottom-right-radius: 10px;
          border-bottom-left-radius: 10px;
          .footer__text__container {
            display: flex;
            gap: 3px;
            align-items: center;
            span {
              font-size: 14px;
              line-height: 20px;
              font-family: "Inter-Medium";
              letter-spacing: 0.1px;
              color: #0597f2;
              &:hover {
                text-decoration: underline;
              }
            }
            img {
              width: 22px;
              height: 22px;
            }
          }
        }
      }
    }
  }
  .health__dashboard-header-container {
    display: flex;
    text-align: start;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
    padding-bottom: 20px;
    .select-container {
      cursor: pointer;
      width: 230px;
      display: flex;
      flex-direction: column;
      gap: 7px;
      label {
        font-size: $font-size-12;
        font-weight: $font-weight-400;
        line-height: $line-height-20;
        letter-spacing: $letter-spacing;
        text-align: start;
        color: #222222;
      }
      @include calendarDropdowns();
    }
    .header-block {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .breadcrumbs-container {
        color: $grey_variation-3;
        font-size: $font-size-14;
        font-family: "Inter";
        line-height: $line-height-18;
        letter-spacing: $letter-spacing;
        a {
          &:hover {
            text-decoration: underline !important;
          }
        }
        span {
          color: $grey_variation-3;
          font-size: $font-size-14;
          font-family: "Inter";
          line-height: $line-height-18;
          letter-spacing: $letter-spacing;
        }
      }
      span {
        font-family: "Inter-Semibold";
        font-size: 22px;
        line-height: 28px;
        color: #222;
        color: $black_variation-2;
      }
    }
    button {
      display: flex;
      align-items: center;
      gap: 8px;
      height: 40px;
      border-radius: 10px;
      span {
        font-size: 14px;
        font-family: "Inter-Medium";
        letter-spacing: 0.2px;
        line-height: 16px;
        color: #ffffff;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
