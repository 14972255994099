@import "../../assets/styles/variables.scss";
.qry-profile-container {
  position: relative;
  &.hidden {
    display: none;
  }
  .profile-block {
    display: flex;
    //flex-direction: column;
    justify-content: space-between;
    gap: 24px;
    height: 800px;
    @media (max-height: 700px) {
      height: 600px;
    }
    .raw-div-container {
      display: flex;
      flex-direction: column;
      text-align: start;
      border: 0.3px solid #e1e1e1;
      box-shadow: 0px 4px 8px 0px #29292914;
      border-radius: 10px;
      position: relative;
      flex: 1 1 0px;
      height: 800px;
      @media (max-height: 700px) {
        height: 600px;
      }
      transition: box-shadow 0.2s ease-in-out;
      //transition: border 0.3s ease-in-out;
      &:hover {
        box-shadow: 0px 12px 20px 0px #29292914;
      }
      .raw-header-container {
        text-align: start;
        padding: 16px 16px 12px 16px;
        span {
          font-size: 16px;
          line-height: 24px;
          color: #222222;
          font-family: "Inter-Semibold";
        }
      }
      .absolute-step-container {
        position: absolute;
        top: 63px;
        left: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        z-index: 80;
        .step-block {
          display: flex;
          gap: 6px;
          .step-container {
            padding: 6px 10px;
            border-radius: 6px;
            background-color: #ededed;
            cursor: pointer;
            span {
              font-family: "Inter-Medium";
              font-size: 15px;
              line-height: 18px;
              color: #7a7a7a;
            }
            &.active {
              background-color: #ffffff;
              span {
                color: $green;
              }
            }
            &:hover {
              background-color: #ffffff;
              span {
                color: $black_variation-2;
              }
            }
          }
        }
        .info-container {
          display: flex;
          gap: 4px;
          .collapser {
            padding: 23.5px 7.7px;
            background-color: #d7d7d7;
            border-radius: 4px;
            cursor: pointer;
            img {
              width: 16px;
              height: 16px;
            }
          }
          .desc-container {
            padding: 8px 10px;
            border: 1px solid #d7d7d7;
            border-radius: 4px;
            background-color: #ffffff;
            display: flex;
            flex-direction: column;
            gap: 1px;
            transition: width 0.3s ease;

            .desc-header {
              span {
                font-family: "Inter-Medium";
                font-size: 14px;
                line-height: 16.8px;
                color: $black_variation-2;
                .id-text {
                  color: #7a7a7a;
                }
              }
            }
          }
          .desc-subheader {
            span {
              font-family: "Inter";
              font-size: 12px;
              line-height: 14.4px;
              color: #7a7a7a;
            }
          }
        }
      }
    }
    .text-div-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      text-align: start;
      max-width: 50%;
      flex: 1 1 0px;
      height: 600px;
      .raw-header-container {
        text-align: start;
        span {
          font-size: 18px;
          line-height: 26px;
          color: #222222;
          font-family: "Inter-Semibold";
        }
      }
      .raw-div {
        overflow-y: auto;
        background-color: #f7f7f7;
        border-radius: 10px;
        position: relative;
        padding-left: 16px;
        padding-top: 8px;
        padding-right: 16px;
        height: inherit;
        pre {
          code {
            font-size: 12px;
            line-height: 20px;
            color: #424242;
          }
        }
      }
    }
  }
  .load {
    background-color: transparent;
    height: 100px;
  }
}
