@import "../../assets/styles/variables.scss";
.confirmmail-parent {
  span {
    -webkit-font-smoothing: antialiased;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 392px;
  .heading-back {
    display: flex;
    margin-bottom: 24px;

    .back-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      cursor: pointer;

      span {
        color: $grey_variation-3;
        font-family: "Inter-Medium";
        font-size: 16px;
        font-style: normal;
        //font-weight: 500;
        line-height: 26px;
        //letter-spacing: 0.4px;
      }
    }
  }
  .heading {
    text-align: left;
    margin-bottom: 12px;
    span {
      color: #222222;
      font-family: "Inter-SemiBold";
      font-size: 24px;
      line-height: 36px;
    }
    @media (max-width: 425px) {
      span {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 33px;
        letter-spacing: 0.2px;
      }
    }
  }
  .subheading {
    text-align: left;
    margin-bottom: 12px;
    padding-right: 5px;
    span {
      color: $grey_variation-3;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      font-weight: $font-weight-400;
      line-height: 21px;
      //letter-spacing: 0.1px;

      .editSignup {
        display: inline;
        color: $green;
        text-decoration: underline;
        cursor: pointer;
      }

      .email-span {
        font-family: "Inter-Medium";
        //font-weight: $font-weight-500;
        // color: $black_variation-2;
      }
    }
  }
  .confirmmail-list {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    .ver-code {
      text-align: start;
      margin-bottom: 16px;
      span {
        color: $black_variation-2;
        font-family: "Inter-Medium";
        font-size: $font-size-14;
        font-style: normal;
        line-height: $line-height-16;
        text-transform: capitalize;
      }
    }
    .name-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      .form-group {
        flex-basis: 50%;
      }
      input {
        width: 100%;
        height: 100%;
        max-width: 52px;
        max-height: 52px;
        aspect-ratio: 1;
        padding: 18px 16px;
        border-radius: 6px;
        border: 1px solid #bdbdbd;
        background: #fff;
        color: #222;
        font-family: "Inter";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.1px;
      }

      input:focus {
        outline: none;
        border: 1px solid #60ca9a;
      }

      @media (max-width: 425px) {
        input {
          width: 46px;
          height: 46px;
          padding: 16px 14px;
          font-size: 22px;
          line-height: 20px;
        }
      }

      @media (max-width: 350px) {
        input {
          width: 40px;
          height: 40px;
          padding: 14px 12px;
        }
      }
    }
    .name-container.error__otp {
      input {
        border: 1px solid #e96560;
      }
    }
    .form-group {
      text-align: start;
      margin-bottom: 24px;
      label {
        margin-bottom: 12px;
        font-size: $font-size-14;
        font-weight: $font-weight-400;
        line-height: $line-height-16;
      }
      input {
        width: 52px;
        height: 52px;
        padding: 18px 16px;
        border-radius: 6px;
        border: 1px solid #bdbdbd;
        background: #fff;
        // max-width: 440px;
        &::placeholder {
          color: #bdbdbd;
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
      }
    }
    .subheading {
      margin-top: 12px;
      span {
        color: $grey_variation-3;
        font-family: "Inter";
        font-size: 14px;
        font-style: normal;
        font-weight: $font-weight-400;
        line-height: 21px;
      }
      .resend_mail {
        display: inline;
        color: $green;
        font-family: "Inter-Medium";
        font-size: 14px;
        font-style: normal;
        line-height: 21px;
        cursor: pointer;
      }
      .ver-code-text__desktop {
        display: inline;
      }
      .ver-code-text__mobile {
        display: none;
      }
      @media (max-width: 425px) {
        .ver-code-text__desktop {
          display: none;
        }
        .ver-code-text__mobile {
          display: inline;
        }
      }
    }
    .login-btn {
      margin-top: 20px;
      height: 52px;
      font-size: 14px;
      line-height: 21px;
      font-family: "Inter-Semibold";
    }
  }
}

@media (max-width: 425px) {
  .reset-container {
    .block {
      padding-top: 24px;
    }
  }
}