@import "../../assets/styles/variables.scss";
.connection-container {
  span {
    -webkit-font-smoothing: antialiased;
  }
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 70px;
  padding-top: 130px;
  display: flex;
  flex-direction: column;
  .connection-header {
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    text-align: start;
    width: 510px;
    margin-top: 2px;
    margin-bottom: 20px;
    .header {
      font-size: $font-size-28;
      line-height: $line-height-26;
      font-weight: $font-weight-600;
      //letter-spacing: $letter-spacing-point4;
      color: $black_variation-2;
      margin-bottom: 8px;
    }
    .sub-header {
      font-size: $font-size-14;
      line-height: $line-height-21;
      font-weight: $font-weight-400;
      color: $grey_variation-3;
      margin-top: 8px;
    }
  }
  .cards-parent {
    padding-left: 32px;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 32px;
  }
}
