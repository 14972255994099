@import "../../assets/styles/variables.scss";
.sign-in-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //flex-basis: 50%;
  width: 50%;
  position: relative;
  span {
    -webkit-font-smoothing: antialiased;
  }
  .signin-parent-block {
    width: 100%;
    max-width: 392px;
    display: flex;
    justify-content: center;
  }
  .signin-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-left: 70px;
    // padding-right: 70px;
    width: 100%;
    max-width: 392px;
    .heading {
      text-align: left;
      margin-bottom: 20px;
      span {
        color: #222222;
        font-family: "Inter-Semibold";
        -webkit-font-smoothing: antialiased;
        font-size: 24px;
        line-height: 26px;
      }
      @media (max-width: 425px) {
        span {
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 33px;
          letter-spacing: 0.2px;
        }
      }
    }
    .sub__heading {
      display: flex;
      flex-direction: column;
      gap: 12px;
      text-align: start;

      p {
        color: #222;
        font-size: 14px;
        font-weight: 400;
        line-height: 21px; /* 150% */
        letter-spacing: 0.2px;
        margin-bottom: 0px;
      }
      @media (max-width: 425px) {
        p {
          font-size: 14px;
          letter-spacing: 0px;
          line-height: 21px;
        }
      }
    }
    .login-list {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 24px;

      .form-group {
        text-align: start;
        margin-bottom: 20px;
        label {
          margin-bottom: 12px;
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          line-height: $line-height-16;
        }
        input {
          height: 48px;
          border-radius: 6px;
          max-width: 440px;
          &::placeholder {
            color: #bdbdbd;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            letter-spacing: 0.2px;
          }
        }
        .cg-eye {
          top: 18px;
          left: 93%;
          cursor: pointer;
        }
      }
      .form-check-group {
        display: flex;
        justify-content: space-between;
        margin-top: 8px;
        margin-bottom: 8px;
        .form-check {
          text-align: start;
          .form-check-label {
            font-size: $font-size-14;
            font-weight: $font-weight-400;
            line-height: 21px;
            letter-spacing: 0.2px;
            color: $grey_variation-3;
          }
          .form-check-input {
            cursor: pointer;
            margin-top: 4px;
            &:checked {
              background-color: $green;
              border-color: $green;
            }
          }
        }
      }
      .forgot-pass {
        cursor: pointer;
        span {
          color: $blue;
          text-decoration: underline;
          font-size: 16px;
          line-height: 19.36px;
          //font-weight: 500;
          letter-spacing: 0.15px;
          font-family: "Inter-Medium";
        }
      }
      .cg-eye-btn {
        padding: 4px;
        margin-right: 0px;
        .cg-eye {
          width: 18.33px;
          height: 18.33px;
        }
      }
      .login-btn {
        margin-top: 2px;
        margin-bottom: 12px;
        height: 52px;
        font-size: 14px;
        line-height: 21px;
        font-family: "Inter-Semibold";
      }
      .email-error {
        display: flex;
        align-items: flex-start;
        gap: 4px;

        img {
          width: 14px;
          height: 14px;
        }

        span {
          margin: 0px;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.2px;
        }
      }
    }
    .signup-link-container {
      margin-top: 24px;
      text-align: center;
      display: flex;
      justify-content: center;
      span {
        color: $black_variation-2;
        //font-family: "Inter-Medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        .signup-link {
          cursor: pointer;
          color: $green;
          font-family: "Inter-Medium";
        }
      }
      .forgot-link {
        cursor: pointer;
        color: $green;
        font-family: "Inter-Medium";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
    .seperator__container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;
      margin-top: 24px;
      .seperator___line {
        border-bottom: 1px solid #bdbdbd;
        align-self: center;
        width: 124px;
        height: 0px;
      }
      span {
        color: #bdbdbd;
        font-size: 14px;
        font-weight: 500;
        line-height: 21px; /* 150% */
        letter-spacing: 0.2px;
      }
    }
    .sso__enable__container {
      display: flex;
      gap: 8px;
      justify-content: center;
      align-items: center;
      height: 52px;
      padding: 8px;
      border-radius: 6px;
      border: 1px solid $green;
      margin-top: 24px;
      cursor: pointer;
      span {
        color: #60ca9a;
        font-size: 14px;
        font-weight: 600;
        line-height: 21px; /* 150% */
        letter-spacing: 0.2px;
      }
      &:hover {
        // background-color: #4baa7e;
        border: 1px solid #4baa7e;
        span {
          color: #4baa7e;
        }
        svg {
          path {
            stroke: #4baa7e;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .sign-in-container {
    width: 100%;
    justify-content: flex-start;
    background-color: #f8f9fc;
    background-image: url(../../assets/images/snowflake_image.svg);
    background-repeat: no-repeat;
    background-position: 100% -1%;

    .signin-header {
      background-color: transparent;
    }

    .signin-parent-block {
      background: #fff;
      padding: 40px;
      width: 100%;
      max-width: 472px;
      border-radius: 8px;
    }

    .signin-parent {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 425px) {
  .sign-in-container {
    background-color: #fff;
    background-image: none;

    .signin-header {
      background: #f8f9fc;
    }

    .block {
      padding-left: 24px;
      padding-right: 24px;
    }

    .signin-parent-block {
      height: 100%;
      padding: 0px;
    }
  }
}
