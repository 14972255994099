@import "../../assets/styles/variables.scss";
.qry-stats-container {
  display: flex;
  flex-direction: column;
  padding: 16px 16px 32px 16px;
  border: 0.3px solid #e1e1e1;
  box-shadow: 0px 4px 8px 0px #29292914;
  border-radius: 10px;
  gap: 12px;
  &.hidden {
    display: none;
  }
  &.pad-0 {
    padding: 0;
    border: none;
  }
  transition: box-shadow 0.2s ease-in-out;
  //transition: border 0.3s ease-in-out;
  &:hover {
    box-shadow: 0px 12px 20px 0px #29292914;
  }
  .header-block {
    display: flex;
    text-align: start;
    align-items: center;
    padding: 0px 8px;
    span {
      font-size: 16px;
      line-height: 24px;
      font-family: "Inter-Semibold";
      color: $black_variation-2;
    }
  }
  .charts-block {
    display: flex;
    flex-direction: column;
    gap: 45px;
  }
  .load {
    background-color: transparent;
    height: 100px;
  }
}
