@import "../../assets/styles/variables.scss";
.qg-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 32px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      gap: 10px;
      justify-content: center;
      .header {
        text-align: start;
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: $font-weight-600;
          //letter-spacing: $letter-spacing-point4;
          color: $black_variation-2;
        }
      }
    }
    .top-filter {
      display: flex;
      gap: 20px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        label {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        .selectcategory__control {
          display: flex;
          min-height: 40px;
          height: auto !important;
          width: 100% !important;
          background-color: #f9f9fc;
          border-radius: 6px;
          background: linear-gradient(180deg, #fff 0%, #fafafb 100%) !important;
          .selectcategory__single-value {
            text-align: start;
            font-size: 14px;
            font-weight: 500;
            padding: 0px !important;
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              font-size: $font-size-14;
              font-weight: $font-weight-500;
              line-height: 16px;
              text-align: start;
              height: 40px;
              color: #353a3f;
              background: none;
              cursor: pointer;
              &.selectcategory__option--is-focused {
                background: #ecf8f4;
              }
            }
          }
        }
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .grid-block {
    width: 100%;
    display: flex;
    gap: 12px !important;
    border-radius: 12px;
    padding: 8px 0px;
    flex-direction: column;
    background-color: $white;
    .grid-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      .grid-header-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .grid-header {
          text-align: start;
          span {
            font-size: 18px;
            line-height: 22px;
            font-weight: $font-weight-600;
            //letter-spacing: $letter-spacing-point4;
            color: $black_variation-2;
          }
        }
      }
      .grid-filter-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        .filter {
          height: 40px;
          display: flex;
          gap: 10px;
          border: 1px solid #e3e3e8;
          border-radius: 6px;
          padding: 0px 15px;
          cursor: pointer;
          &:hover {
            border: 1px solid #60ca9a;
          }
          .filter-img {
            height: 100%;
            width: 16px;
            align-items: center;
            text-align: center;
            display: flex;
          }
          .filter-text {
            height: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            span {
              font-weight: $font-weight-500;
              font-size: $font-size-14;
              line-height: $line-height-16;
              letter-spacing: $letter-spacing-point1;
            }
          }
        }

        .grid-filters {
          flex-basis: 30%;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          background: #ffff;
        }
        .filrt-container {
          cursor: pointer;
          width: 230px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            display: flex;
            min-height: 36px;
            cursor: pointer;
            height: auto !important;
            width: 100% !important;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
            border-radius: 6px;
            background: linear-gradient(
              180deg,
              #fff 0%,
              #fafafb 100%
            ) !important;
            &:active {
              border: 1px solid #60ca9a;
              background: #f7f7f7 !important;
            }
            &:hover {
              border-radius: 6px;
              border: 1px solid #cfcfd6;
              background: #f7f7f7 !important;
            }

            &.selectcategory__control--is-focused {
              box-shadow: none !important;
              outline: none !important;
              font-size: $font-size-14 !important;
              border: 1px solid #60ca9a !important;
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 36px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background: #ecf8f4;
                }
                &.selectcategory__option--is-selected {
                  font-family: "Inter-Medium";
                  color: #353a3f;
                }
              }
            }
          }
          &#selectSFAcc {
            width: 160px;
          }
        }
        .select-container {
          cursor: pointer;
          width: 230px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          position: relative;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            display: flex;
            min-height: 38px;
            cursor: pointer;
            height: auto !important;
            width: 100% !important;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
            border-radius: 6px;
            border-color: #e3e3e8;
            background: linear-gradient(
              180deg,
              #fff 0%,
              #fafafb 100%
            ) !important;

            &:active {
              border: 1px solid #60ca9a;
              background: #f7f7f7 !important;
            }
            &:hover {
              border-radius: 6px;
              border: 1px solid #cfcfd6;
              background: #f7f7f7 !important;
            }
            .selectcategory__single-value {
              text-align: start;
              font-size: 14px;
              font-weight: 500;
              margin: 0px !important;
              display: flex;
              align-items: center;
              font-family: "Inter-Medium";
              color: #222222 !important;
              &::before {
                content: "";
                margin-right: 8px;
                margin-top: 4px;
              }
            }
            &.selectcategory__control--is-focused {
              box-shadow: none !important;
              border: 1px solid $black_variation-2 !important;
              outline: none !important;
              font-size: $font-size-14 !important;
              border: 1px solid #60ca9a !important;
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;

                height: 36px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                z-index: 99 !important;
                &.selectcategory__option--is-focused {
                  background: #ecf8f4;
                }
                &.selectcategory__option--is-selected {
                  font-family: "Inter-Medium";
                  color: #353a3f;
                }
              }
            }
          }
          &#selectSFAcc {
            width: 160px;
          }
        }
      }
    }

    .grid-container {
      .download-link {
        height: 28px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .download-loader {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 5px solid $green_variation-2;
        animation: 1s pre linear infinite;
      }
      @keyframes pre {
        0% {
          transform: rotate(0deg);
          border-top: 5px solid $green;
        }
        100% {
          transform: rotate(360deg);
          border-top: 5px solid $green;
        }
      }
    }
  }
  .load {
    background-color: transparent;
  }
}
.qg-filter-button {
  width: 85px;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 7px 8px !important;
  position: relative !important;
  &:hover {
    border: 1px solid #cfcfd6 !important;
    background: #f7f7f7;
  }
}
.qg-filter-active {
  width: 85px;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 7px 8px !important;
  position: relative !important;
  border: 1px solid #60ca9a;
  background: #fbfefd;
  color: #222;
  svg > path {
    stroke: #fff;
  }
}
.grid-filter-wrapper {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}
.grid-filter-wrapper-left {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}
.grid-filter-wrapper-right {
  display: flex;
  width: 50%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  .export-container {
    width: 90px !important;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .selectcategory__control {
    &:hover {
      border: 1px solid #60ca9a;
    }
  }
}
.qg-filter-button-active {
  width: 85px;
  height: 38px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 7px;
  padding: 7px 8px !important;
  position: relative !important;
  border: 1px solid #60ca9a !important;
  &:hover {
    border: 1px solid #60ca9a !important;
  }
}
.filter-container {
  .selectcategory__single-value {
    text-align: start;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
    &::before {
      content: url(../../assets/images/common/calendar.svg) !important;
      margin-right: 8px;
      margin-top: 4px;
    }
  }
}
.custom__select {
  .selectcategory__value-container {
    padding: 2px 0px !important;
  }
}
