@import "../../assets/styles/variables.scss";
.slack-settings-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 40px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 24px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      .header {
        text-align: start;
        display: flex;
        gap: 8px;
        .back_btn {
          cursor: pointer;
        }
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: $font-weight-600;
          color: $black_variation-2;
          letter-spacing: $letter-spacing-point4;
          //font-family: "Inter-Semibold";
        }
      }
      .subheader-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        .sub-header {
          text-align: start;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #7a7a7a;
            .subheader-bold {
              font-weight: 600;
              color: #353a3f;
            }
          }
        }
      }
    }
    .top-filter {
      display: flex;
      gap: 20px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        label {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        .selectcategory__control {
          display: flex;
          min-height: 40px;
          height: auto !important;
          width: 100% !important;
          background-color: $grey-variation-5;
          background-color: #f9f9fc;
          border-radius: 8px;
          .selectcategory__single-value {
            text-align: start;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              font-size: $font-size-14;
              font-weight: $font-weight-500;
              line-height: 16px;
              text-align: start;
              height: 40px;
              color: #353a3f;
              background: none;
              cursor: pointer;
              &.selectcategory__option--is-focused {
                background-color: #f0faff;
              }
            }
          }
        }
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .settings-form-parent {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .settings-form-container {
      padding: 40px;
      display: flex;
      gap: 24px;
      max-width: 520px;
      flex-direction: column;
      align-items: center;
      border: 1px solid #ebebeb;
      border-radius: 16px;
      background-color: #ffffff;
      .img-container {
        width: 80px;
        height: 80px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .form-group {
        text-align: start;
        label {
          margin-bottom: 12px;
          font-size: 14px;
          font-weight: 400;
          line-height: 16px;
          color: #222222;
        }
        input {
          width: 440px;
          height: 48.28px;
          border-color: #bdbdbd;
          border-radius: 6px;
          border-width: 1px;
          background-color: #ffffff;
          font-size: 14px;
          font-weight: $font-weight-400;
          line-height: $line-height-20;
          //color: #bdbdbd;
        }
      }
    }
  }
  .btn-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    button {
      width: 104px;
      height: 40px;
      font-size: 14px;
      line-height: 16px;
      font-weight: 500;
      font-family: "Inter-Medium";
      border-radius: 10px;
      &.disabled {
        color: #bdbdbd;
        border: 1px solid #bdbdbd;
      }
      &.test-connection {
        width: 181px;
      }
    }
  }
}
.settings-load {
  //background-color: transparent;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  .form-container.alert-success-container {
    width: 100%;
    height: calc(100% - 60px);
    background-color: transparent;
    position: absolute;
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 1000;
    .form-parent {
      opacity: 1;
      border-radius: 10px;
      width: 470px;
      background-color: white;
      .form-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 49px;
        border-bottom: 1px solid #e1e1e1;
        background-color: white;
        position: fixed;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        width: 470px;
        z-index: 1000;
        &.no-border {
          border: none;
          height: 40px;
        }
        .header {
          display: flex;
          align-items: center;
          padding-left: 32px;
          span {
            font-weight: $font-weight-600;
            font-size: $font-size-18;
            line-height: 25.2px;
            color: #222222;
          }
        }
        .close-img {
          display: flex;
          align-items: center;
          padding-right: 32px;
          &.padding-right-16 {
            padding-right: 16px;
          }
          span {
            color: $grey_variation-3;
            cursor: pointer;
          }
        }
      }
      .field-container {
        text-align: start;
        padding-top: 74px;
        //padding-bottom: 24px;
        height: 442px;
        padding-left: 32px;
        padding-right: 32px;
        overflow: auto;
        .form-group {
          margin-bottom: 24px;
          .label-parent {
            display: flex;
            justify-content: space-between;
            .label-container {
              display: flex;
              gap: 4px;
              label {
                font-weight: $font-weight-400;
                font-size: $font-size-14;
                line-height: $line-height-16;
                letter-spacing: $letter-spacing-point1;
                color: $black_variation-2;
                margin-bottom: 12px;
              }
              .img-container {
                height: 14px;
                width: 14px;
                display: flex;
                justify-content: center;
                align-items: center;
                .info-tooltip {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  img {
                    margin-top: 2px;
                    height: 100%;
                    width: 100%;
                    cursor: pointer;
                  }
                }
              }
            }
            a {
              font-size: $font-size-14;
              line-height: $line-height-16;
              font-weight: $font-weight-400;
              text-decoration: none;
              text-align: center;
              color: #0597f2;
              letter-spacing: 0.2px;
              img {
                margin-bottom: 2px;
              }
            }
          }
          .checkbox-container {
            display: flex;
            gap: 8px;
            align-items: center;
            height: 20px;
            .form-check-label {
              font-size: $font-size-14;
              font-weight: $font-weight-400;
              line-height: 21px;
              letter-spacing: 0.2px;
              color: $grey_variation-3;
            }
            input {
              cursor: pointer;
              height: 100%;
              width: 20px;
              margin-top: 0px;
              &:checked {
                background-color: $green;
                border-color: $green;
              }
            }
          }
          input {
            height: 48px;
            border-radius: 6px;
            font-size: 14px;
          }
          .cg-eye {
            top: 18px;
            left: 93%;
            cursor: pointer;
          }
          input::placeholder {
            font-weight: $font-weight-400;
            font-size: $font-size-14;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing-point1;
            color: $grey_variation-1;
          }
          input::-webkit-input-placeholder {
            font-weight: $font-weight-400;
            font-size: $font-size-14;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing-point1;
            color: $grey_variation-1;
          }
          input::-moz-placeholder {
            /* Firefox 19+ */
            font-weight: $font-weight-400;
            font-size: $font-size-14;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing-point1;
            color: $grey_variation-1;
          }
          input:-ms-input-placeholder {
            /* IE 10+ */
            font-weight: $font-weight-400;
            font-size: $font-size-14;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing-point1;
            color: $grey_variation-1;
          }
          input:-moz-placeholder {
            /* Firefox 18- */
            font-weight: $font-weight-400;
            font-size: $font-size-14;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing-point1;
            color: $grey_variation-1;
          }
        }
      }
      .delete-field-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        //justify-content: space-between;
        gap: 24px;
        padding-top: 42px;
        //padding-bottom: 24px;
        .img-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 64px;
          height: 64px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .success-header-container {
          display: flex;
          flex-direction: column;
          // max-width: 286px;
          justify-content: center;
          gap: 12px;
          align-items: center;
          margin-bottom: 50px;
          .success-header {
            max-width: 365px;
            span {
              font-family: "Inter-Semibold";
              font-size: 18px;
              line-height: 20px;
              color: #222222;
              font-weight: 600;
              letter-spacing: $letter-spacing;
            }
          }
          .success-subheader {
            max-width: 286px;
            span {
              color: #7a7a7a;
              font-size: 14px;
              line-height: 16px;
              font-family: "Inter";
              font-weight: 400;
              letter-spacing: $letter-spacing;
            }
          }
        }
      }
      .success-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        justify-content: center;
        gap: 24px;
        padding-top: 42px;
        .img-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .success-header-container {
          display: flex;
          flex-direction: column;
          // max-width: 286px;
          justify-content: center;
          gap: 12px;
          align-items: center;
          .success-header {
            span {
              font-family: "Inter-Semibold";
              font-size: 18px;
              line-height: 20px;
              color: #222222;
            }
          }
          .success-subheader {
            max-width: 286px;
            span {
              color: #7a7a7a;
              font-size: 14px;
              line-height: 22px;
              font-family: "Inter";
            }
          }
        }
      }
      .button-container {
        display: flex;
        flex-direction: row-reverse;
        gap: 20px;
        padding-right: 32px;
        padding-bottom: 24px;
        padding-top: 5px;
        &.center-align {
          //flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 0px;
          padding-top: 30px;
          gap: 16px;
          button {
            width: 144px;
          }
        }
        &.button-delete {
          button {
            width: 92px;
          }
        }
        button {
          height: 40px;
          border-radius: 10px;
          //font-weight: $font-weight-500;
          font-size: $font-size-14;
          line-height: $line-height-16;
          letter-spacing: $letter-spacing-point1;
          font-family: "Inter";
          &.medium-style {
            font-family: "Inter-Medium";
          }
        }
      }
    }
  }
}
