@import "../../assets/styles/variables.scss";
.forgotpwd-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  position: relative;
  span {
    -webkit-font-smoothing: antialiased;
  }
  .forgotpwd-parent-block {
    width: 100%;
    max-width: 392px;
  }
  .forgotpwd-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    // padding-left: 70px;
    // padding-right: 70px;
    width: 100%;
    max-width: 392px;
    .heading-back {
      display: flex;
      margin-bottom: 32px;

      .back-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        span {
          color: $grey_variation-3;
          font-family: "Inter-Medium";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 26px;
          letter-spacing: 0.4px;
        }
      }
    }
    .heading {
      text-align: left;
      margin-bottom: 12px;
      display: flex;
      max-width: 392px;
      flex-direction: column;
      span {
        font-size: 24px;
        //font-weight: 600;
        line-height: 26px;
        font-family: "Inter-Semibold";
        //letter-spacing: 0.4px;
        color: #222222;
      }
    }
    .subheading {
      margin-bottom: 20px;
      text-align: left;
      span {
        color: $grey_variation-3;
        font-family: Inter;
        font-size: 14px;
        line-height: 21px; /* 150% */
        letter-spacing: 0.2px;
        font-weight: 400;
        font-style: normal;
      }

      @media (max-width: 425px) {
        span {
          font-family: Inter;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          letter-spacing: 0.2px;
        }
      }
    }
    .forgotpwd-list {
      margin-top: 20px;
      margin-bottom: 20px;
      .form-group {
        text-align: start;
        margin-bottom: 24px;
        label {
          margin-bottom: 12px;
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          line-height: $line-height-16;
        }
        input {
          height: 48px;
          border-radius: 6px;
          &::placeholder {
            color: #bdbdbd;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            letter-spacing: 0.2px;
          }
        }
      }
      .login-btn {
        margin-top: 40px;
        margin-bottom: 12px;
        padding: 8px;
        height: 52px;
        font-size: 14px;
        line-height: 21px;
        font-family: "Inter-Semibold";
      }
      .email-error {
        display: flex;
        align-items: flex-start;
        gap: 4px;

        img {
          width: 14px;
          height: 14px;
        }

        span {
          margin: 0px;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.2px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .forgotpwd-container {
    width: 100%;
    justify-content: flex-start;
    background-color: #f8f9fc;
    background-image: url(../../assets/images/snowflake_image.svg);
    background-repeat: no-repeat;
    background-position: 100% -1%;

    .signin-header {
      background-color: transparent;
    }

    .forgotpwd-parent-block {
      background: #fff;
      padding: 40px;
      width: 100%;
      max-width: 472px;
      border-radius: 8px;
    }

    .forgotpwd-parent {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 425px) {
  .forgotpwd-container {
    background-color: #fff;
    background-image: none;

    .signin-header {
      background: #f8f9fc;
    }

    .forgotpwd-parent-block {
      height: 100%;
      padding: 0px;
    }
  }
}
