@import "../../assets/styles/variables.scss";
.datasource-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 18px;
  span {
    -webkit-font-smoothing: antialiased;
  }

  //background-color: $grey-variation-11;
  padding: 0px 0px 84px 0px;
  .grid-block {
    width: 100%;
    display: flex;
    gap: 24px;
    border-radius: 12px;
    padding: 0px 0px 16px 0px;
    flex-direction: column;
    background-color: $white;
  }
  .load {
    background-color: transparent;
  }
}
.add__dt__src {
  &.disabled {
    pointer-events: all;
    cursor: not-allowed;
    color: #cccccc;
    border: 1px solid #e4e4e7;
    opacity: 1;
    background: #f7f7f7;
    span {
      color: #cccccc !important;
    }
  }
}
