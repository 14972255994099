@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";
.btn-green-variant {
  color: white;
  &.btn-green-variant {
    background-color: $green;
  }
  &:hover {
    color: white;
  }
}
.btn-outline-dark {
  &:hover {
    background: white;
    color: #212529;
  }
}
.btn.btn-green-variant__login {
  background-color: #60ca9a;
  border-radius: 6px;
  border: none;

  color: #fff;
  font-family: "Inter-Semibold";
  font-size: $font-size-16;
  font-style: normal;
  font-weight: $font-weight-600;
  line-height: $line-height-24;
  letter-spacing: 0.2px;

  &:hover {
    background-color: #4baa7e;
    color: #fff;
    border: none;
  }
  &:active {
    background-color: #459c73;
    color: #fff;
    border: none;
  }
  &:disabled {
    background-color: #4baa7e;
    color: #fff;
    border: none;
    opacity: 0.4;
    cursor: not-allowed;
  }
}
