@import "../../assets/styles/variables.scss";
.chart-legend-hidden {
  visibility: hidden;
}

// Custom Progress Bar
.progressbar-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  padding: 0 8px;
  p {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    margin-bottom: 0rem;
  }
}
.progress-bar {
  min-width: 80%;
  max-width: 80%;
  background-color: #ebebeb;
  border-radius: 20px;
  position: relative;
  margin: 15px 0;
  height: 6px;
}
.progress-done {
  background: #4baa7e;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  transition: 1s ease 0.3s;
  color: #fff;
}
