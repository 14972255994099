@import "../../assets/styles/variables.scss";
.billing-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 24px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 24px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      margin-top: 10px;
      .header {
        text-align: start;
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: $font-weight-600;
          color: $black_variation-2;
          letter-spacing: $letter-spacing-point4;
          //font-family: "Inter-Semibold";
        }
      }
      .subheader-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        .sub-header {
          text-align: start;
          span {
            font-size: 14px;
            font-family: "Inter";
            color: #7a7a7a;
            .subheader-bold {
              font-family: "Inter-Semibold";
              color: #353a3f;
            }
          }
        }
      }
    }
    .top-filter {
      display: flex;
      gap: 20px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        label {
          font-size: $font-size-12;
          font-family: "Inter";
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        .selectcategory__control {
          display: flex;
          min-height: 40px;
          height: auto !important;
          width: 100% !important;
          background-color: $grey-variation-5;
          background-color: #f9f9fc;
          border-radius: 8px;
          .selectcategory__single-value {
            text-align: start;
            font-size: 14px;
            font-family: "Inter-Medium";
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              font-size: $font-size-14;
              font-family: "Inter-Medium";
              line-height: 16px;
              text-align: start;
              height: 40px;
              color: #353a3f;
              background: none;
              cursor: pointer;
              &.selectcategory__option--is-focused {
                background-color: #f0faff;
              }
            }
          }
        }
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .billing-card-container {
    margin: 0px auto;
    width: 100%;
    max-width: 800px;
    .billing-card {
      background-color: #ffffff;
      box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
      border-radius: 6px;
      margin-bottom: 24px;
      border: 0.3px solid #e1e1e1;
      display: flex;
      flex-direction: column;
      .billing-card-header-container {
        display: flex;
        justify-content: space-between;
        padding: 16px;
        border-bottom: 1px solid #f7f7f9;
        .billing-card-header {
          text-align: start;
          display: flex;
          align-items: center;
          span {
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            color: #222222;
          }
        }

        .billing-manage {
          display: flex;
          text-align: start;
          align-items: center;
          justify-content: space-between;
          gap: 8px;
          .btn-manage {
            color: $green;
            font-size: 12px;
            border: 1px solid $green;
            border-radius: 4px;
            //font-weight: 500;
            line-height: 1.5;
            font-family: "Inter-Medium";
            padding: 4px 8px;
            &:hover {
              background-color: $green;
              color: white;
            }
          }
        }
      }
      .usage-table-container {
        padding: 16px;
        .usage-table-header {
          display: flex;
          justify-content: space-between;
          .renew-text {
            font-size: 14px;
            margin-bottom: 1rem;
            text-align: start;
            line-height: 1.5;
            color: #222222;
            text-align: left;
          }
          .plans-info-link {
            color: $green;
            font-size: 12px;
            font-family: "Inter-Medium";
            line-height: 22px;
            text-decoration: none;
            span {
              text-decoration: none;
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
        .update-subs-container {
          display: flex;
          justify-content: space-between;
          .btn-voucher {
            font-size: 14px;
            font-family: "Inter-Medium";
            line-height: 22px;
            margin-top: 1rem;
            color: $green;
            text-decoration: none;
            padding-left: 0;
            padding-right: 0;
            &:hover {
              color: #56b68b;
            }
          }
          .update-subscription {
            display: flex;
            margin-top: 1rem;
            height: 40px;
            button {
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
              white-space: nowrap;
              background-color: $green;
              border-radius: 4px;
              &:hover {
                background-color: #56b68b;
              }
              &:disabled {
                color: #ffffff;
                pointer-events: none;
                cursor: not-allowed;
                background-color: $green;
                border: none;
                opacity: 0.65;
              }
            }
            &.hidden {
              visibility: hidden;
            }
          }
        }

        .usage-table-wrapper {
          min-width: 100%;
          overflow-x: auto;
          border: 1px solid #e3e3e9;
          border-radius: 6px;
          max-height: calc(100vh / 1.25);
          table {
            width: 100%;
            font-size: 14px;
            text-align: start;
            .disabled {
              color: #bdbdbd;
              cursor: not-allowed;
              label,
              td {
                color: #bdbdbd;
                cursor: not-allowed;
                background-color: #fafafa;
              }
            }
            label {
              font-size: 14px;
              color: #222222;
              font-weight: 600;
              cursor: pointer;
              display: flex;
              align-items: center;
              input[type="radio"] {
                margin-right: 0.5rem;
                margin-top: 0px;
                cursor: pointer;
                //accent-color: green;
                &:checked {
                  background-color: #60ca9a;
                  border-color: #60ca9a;
                  box-shadow: none;
                }
              }
            }
            tr {
              td {
                padding: 12px;
                vertical-align: middle;
                white-space: nowrap;
                background-color: #fafafa;
                color: #222222;
                &:nth-child(1) {
                  padding-left: 16px;
                }

                .link {
                  color: #7a7a7a;
                  text-decoration: none;
                  cursor: pointer;
                  &.disabled {
                    pointer-events: none;
                  }
                }
                .link:hover {
                  color: #000000;
                }
              }
              // &:nth-child(odd) {
              //   td {
              //     background-color: #fafafb;
              //   }
              // }

              &.selected {
                td {
                  background-color: #ffffff;
                }
              }
              &:hover {
                td {
                  background-color: #fafafc !important;
                }
              }
            }
            thead {
              width: 100%;
              position: -webkit-sticky;
              position: sticky;
              top: 0;
              background: #ffffff;
              z-index: 998;
              tr {
                th {
                  font-size: 14px;
                  font-weight: 600 !important;
                  text-transform: capitalize;
                  box-shadow: 0 -1px 0 #e3e3e9 inset;
                  padding: 12px;
                  vertical-align: middle;
                  white-space: nowrap;
                  background-color: #ffffff;
                  color: #222222;
                  &:nth-child(1) {
                    padding-left: 16px;
                  }
                }
              }
            }
            tbody {
              width: 100%;
            }
          }
        }
      }
      .billing-card-usage-container {
        display: flex;
        flex-direction: column;
        padding: 16px;
        .usage-text {
          display: flex;
          align-items: center;
          text-align: start;
          margin-bottom: 0.375rem;
          span {
            font-weight: 600;
            font-size: 1.25rem;
            line-height: 30px;
            color: #222222;
            margin-bottom: 0;
          }
        }
        .usage-desc {
          display: flex;
          align-items: center;
          text-align: start;
          span {
            font-weight: 400;
            font-size: 12px;
            line-height: 1.5;
            color: #7a7a7a;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .load {
    background-color: transparent;
  }
}
.billing__fallback {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  gap: 20px;
  .fallback__block {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    .fallback__heading {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      max-width: 368px;
      .header {
        span {
          color: #222;
          text-align: center;
          font-size: 18px;
          font-family: "Inter-Semibold";
          line-height: 24px; /* 133.333% */
          letter-spacing: 0.2px;
        }
      }
      .sub__header {
        span {
          color: #7a7a7a;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px; /* 150% */
          letter-spacing: 0.2px;
          a {
            color: #0597f2;
            text-decoration-line: none;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
.subscription__banner {
  padding: 16px 24px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  left: 0;
  //box-shadow: 0px 2px 1px 0px rgba(64, 72, 82, 0.05);
  background-color: #fef7ec;
  border-top: 0.5px solid #fac06b !important;
  border-bottom: 0.5px solid #fac06b !important;
  .subscription__text {
    width: 100%;
    text-align: start;
    display: flex;
    align-items: center;
    span {
      font-size: 14px;
      font-family: "inter";
      font-weight: 400;
      line-height: 22px;
      white-space: break-spaces;
      color: #303037;
      a {
        color: $blue;
        text-decoration: none;
        cursor: pointer;
      }
      .manage_billing_link {
        background: none;
        border: none;
        color: $blue;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.plans-modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
  display: none;
  &.show {
    display: block;
  }
}
.plans-modal {
  z-index: -1;
  opacity: 0;
  background: transparent;
  &.show {
    z-index: 1050;
    opacity: 1;
  }
  .plans-modal-dialog {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    opacity: 1;
    pointer-events: none;
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
    &.show {
      transform: none;
    }
    @media (min-width: 768px) {
      max-width: 500px;
      margin: 1.75rem auto;
      min-height: calc(100% - 3.5rem);
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        .modal-title {
          margin-bottom: 0;
          line-height: 1.5;
          font-weight: bold;
          color: #222222;
          text-transform: capitalize;
        }
        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
          cursor: pointer;
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.5;
          -webkit-appearance: button;
          background-color: transparent;
          border: 0;
        }
      }
      .modal-body {
        flex: 1 1 auto;
        padding: 1rem;
        position: relative;
        p {
          font-size: 14px;
          line-height: 150%;
          color: #7a7a7a;
          text-align: start;
        }
        .det-container {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          .plan-det {
            flex: 1 1 45% !important;
            &.box {
              border: 1px solid #d6d6d6;
              border-radius: 4px;
              //box-shadow: 0px 0px 2px #cecece;
              //min-width: 250px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              .highlighted-span {
                border: 1px solid #f5f5f5;
                padding: 4px 8px;
                // margin: 4px 0px;
                border-radius: 4px;
                color: $green;
                background-color: white;
                font-size: 12px;
                margin-bottom: 0px;
              }
            }
            &.box-selected {
              border-color: $green;
              //box-shadow: 0px 0px 2px $green;
            }
            &.p-3 {
              padding: 1rem;
            }
            .det-span {
              font-weight: 600;
              text-align: start;
              margin-bottom: 0.5rem;
              line-height: 22px;
              font-size: 14px;
              align-self: flex-start;
              color: #222222;
            }
            div {
              margin-bottom: 0.5rem;
              font-weight: 400;
              line-height: 22px;
              font-size: 14px;
              color: #222222;
              text-align: left;
              align-self: flex-start;
            }
            span {
              font-weight: 600;
              text-align: start;
              margin-bottom: 0.5rem;
              font-size: 1rem;
              align-self: flex-start;
            }
          }
          .px-3 {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
        .plans_alert_container {
          display: flex;
          background-color: #fef7ec;
          border: 0.5px solid #fac06b !important;
          border-radius: 6px;
          align-items: center;
          box-shadow: 0px 2px 1px 0px rgba(64, 72, 82, 0.05);
          padding: 12px;
          gap: 0.5rem;
          margin-bottom: 8px;
          p {
            margin-bottom: 0px;
            line-height: 17px;
            font-weight: 400;
            color: #303037;
            font-size: 12px;
          }
        }
      }
      .modal-footer {
        border-top: 1px solid #dee2e6;
        .change-plan-btn {
          margin: 12px;
          color: #ffffff;
          padding: 7px 14px;
          font-size: 14px;
          font-weight: 600;
          height: 40px;
          line-height: 20px;
          border: 1px solid transparent;
          border-radius: 4px;
          appearance: none;
          -moz-appearance: none;
          &:hover {
            background-color: #56b68b;
          }
        }
      }
    }
  }
}

.voucher-modal {
  z-index: -1;
  opacity: 0;
  background: transparent;
  &.show {
    z-index: 1050;
    opacity: 1;
  }
  .voucher-modal-dialog {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    opacity: 1;
    pointer-events: none;
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
    &.show {
      transform: none;
    }
    width: 310px;
    @media (min-width: 768px) {
      max-width: 500px;
      margin: 1.75rem auto;
      min-height: calc(100% - 3.5rem);
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        .modal-title {
          margin-bottom: 0;
          line-height: 1.5;
          font-weight: bold;
          color: #222222;
          text-transform: capitalize;
          &.small-font {
            font-size: 1rem;
          }
        }
        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
          cursor: pointer;
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.5;
          -webkit-appearance: button;
          background-color: transparent;
          border: 0;
        }
      }
      .modal-body {
        flex: 1 1 auto;
        padding: 1rem;
        position: relative;
        .voucher-form {
          display: flex;
          gap: 10px;
          flex-direction: column;
          .form-group {
            .redeem_code_input {
              &:focus {
                border: 1px solid $green !important;
                box-shadow: 0 0 0 4px #dff4ea !important;
              }
            }
            .connection__fail {
              p {
                color: $red_variation_1;
                display: flex;
                align-items: center;
                font-size: 12px;
                margin-top: 2px;
                margin-bottom: 2px;
                img {
                  margin-right: 5px;
                }
              }
            }
          }
          .change-plan-btn {
            color: #ffffff;
            align-self: flex-end;
            padding: 7px 14px;
            font-size: 14px;
            font-weight: 600;
            height: 40px;
            line-height: 20px;
            border: 1px solid transparent;
            border-radius: 4px;
            appearance: none;
            -moz-appearance: none;
            &:hover {
              background-color: #56b68b;
            }
          }
        }
        p {
          font-size: 14px;
          line-height: 150%;
          color: #303037;
          text-align: start;
        }
      }
      .modal-footer {
        border-top: 1px solid #dee2e6;
        .change-plan-btn {
          margin: 12px;
          color: #ffffff;
          padding: 7px 14px;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          border: 1px solid transparent;
          border-radius: 6px;
          appearance: none;
          -moz-appearance: none;
          &:hover {
            background-color: #56b68b;
          }
        }
      }
    }
  }
}

.cancel-modal {
  z-index: -1;
  opacity: 0;
  background: transparent;
  display: flex;
  &.show {
    z-index: 1050;
    opacity: 1;
  }
  .cancel-modal-dialog {
    display: flex;
    align-items: center;
    position: relative;
    width: auto;
    opacity: 1;
    pointer-events: none;
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px);
    &.show {
      transform: none;
    }
    @media (min-width: 576px) {
      max-width: 500px;
      margin: 1.75rem auto;
      min-height: calc(100% - 3.5rem);
    }
    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #ffffff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid #dee2e6;
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        .modal-title {
          margin-bottom: 0;
          line-height: 1.5;
          font-weight: bold;
          color: #222222;
          &.cancel-title {
            font-size: 1rem;
          }
        }
        .close {
          padding: 1rem 1rem;
          margin: -1rem -1rem -1rem auto;
          cursor: pointer;
          float: right;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1;
          color: #000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.5;
          -webkit-appearance: button;
          background-color: transparent;
          border: 0;
        }
      }
      .modal-body {
        flex: 1 1 auto;
        padding: 1rem;
        position: relative;
        p {
          // padding-bottom: 1rem;
          font-size: 14px;
          line-height: 150%;
          color: #000;
          text-align: start;
        }
        .det-container {
          display: flex;
          align-items: center;
          .plan-det {
            flex: 1 1 45% !important;
            &.box {
              border: 1px solid #cecece;
              border-radius: 6px;
              box-shadow: 0px 0px 2px #cecece;
            }
            &.box-selected {
              border-color: $green;
              box-shadow: 0px 0px 2px $green;
            }
            &.p-3 {
              padding: 1rem;
            }
            h6 {
              font-weight: 600;
              text-align: start;
              margin-bottom: 0.5rem;
              font-size: 1rem;
            }
          }
          .px-3 {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
      .modal-footer {
        border-top: 1px solid #dee2e6;
        .cancel-plan-btn {
          margin: 12px;
          color: #e96560;
          padding: 7px 14px;
          height: 40px;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          border: 1px solid #e96560;
          border-radius: 4px;
          appearance: none;
          -moz-appearance: none;
          &:hover {
            background-color: #e96560;
            color: #ffffff;
          }
        }
        .keep-plan-btn {
          margin: 12px 0px;
          color: #222;
          padding: 7px 14px;
          height: 40px;
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
          border: 1px solid #222;
          border-radius: 4px;
          appearance: none;
          -moz-appearance: none;
          &:hover {
            background-color: #222;
            color: #ffffff;
          }
        }
      }
    }
  }
}
