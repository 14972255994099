@import "../../assets/styles/variables.scss";
.sidebar-container {
  // height: calc(100% - 60px);
  width: 90px;
  padding: 8px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: $black;
  .header-container {
    text-align: start;
    span {
      font-size: $font-size-18;
      font-family: "Inter-Semibold";
      line-height: $line-height-26;
      //letter-spacing: $letter-spacing-point4;
      color: $black_variation-2;
    }
  }
  .item-block {
    display: flex;
    flex-direction: column;
    gap: 0px;
    height: 100%;
    .item {
      //padding: 8px;
      cursor: pointer;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: $green_variation-3;
      font-family: "Inter-Medium";
      display: flex;
      flex-direction: column;
      gap: 6px;
      align-items: center;
      padding: 12px 0px;
      span,
      a {
        font-size: $font-size-12;
        //font-weight: $font-weight-500;
        line-height: 14px;
        color: #bdbdbd;
        -webkit-font-smoothing: antialiased;
        letter-spacing: 0px;
      }
      &.active {
        font-family: "Inter-Medium";
        // background-color: #ecf9f3;
        color: $green;
        span,
        a {
          font-size: $font-size-12;
          //font-weight: $font-weight-600;
          line-height: 14px;
          color: $green;
        }
      }
      &:hover {
        background-color: #2b2b2b;
      }
      img {
        width: 22px;
        height: 22px;
      }
    }
    .mar-top-auto {
      margin-top: auto;
    }
  }
  .app-version {
    @include medium(11px, 16px);
    font-style: italic;
    color: #949494;
  }
}
