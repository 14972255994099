@import "../../assets/styles/variables.scss";
.grid-container {
  max-width: 100%;
  background-color: $white;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  font-family: "Inter", sans-serif !important;
  //border-radius: 12px;
  gap: 24px;
  span {
    -webkit-font-smoothing: auto !important;
  }
  .title-container {
    text-align: start;
    span {
      font-size: $font-size-20;
      line-height: $line-height-24;
      //letter-spacing: $letter-spacing-point4;
      font-weight: $font-weight-500;
      color: $black_variation-2;
    }
  }
  .ag-theme-alpine {
    font-family: "Inter", sans-serif !important;
    width: 100%;
    --ag-header-background-color: #222222;
    --ag-selected-row-background-color: #ecf9f3;
    --ag-row-hover-color: #ecf9f3 !important;
    .ag-header {
      border: 1px solid #222222;
      height: 35px !important;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      .center-aligned {
        .ag-header-cell-label {
          justify-content: center;
        }
      }
    }
    &.text-ellipse {
      span {
        text-overflow: ellipsis;
      }
    }
    .ag-root-wrapper {
      overflow-y: auto;
      border: none;
    }
    .ag-body-viewport {
      border: 1px solid rgb(234, 234, 234);
    }
    &.noborder {
      .ag-body-viewport {
        border: none;
      }
    }
    .ag-cell {
      font-weight: 400;
      color: #222222;
      font-size: 12px;
      //line-height: 20px;
      .dt-img-text-container {
        display: flex;
        gap: 6px;

        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    &.cell-flex {
      .ag-cell {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        .ag-cell-wrapper {
          width: 100%;
        }
      }
    }
    .ag-header-cell {
      white-space: break-spaces;
    }
    .grid-cell-left-aligned {
      text-align: start;
      &.pre-line {
        white-space: pre-line;
        line-height: 160%;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    .grid-cell-middle-aligned {
      text-align: center;
      &.pre-line {
        white-space: pre-line;
        line-height: 160%;
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    .flex-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .flex-justify-space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .cell-border-right {
      border-right: 1px solid rgb(234, 234, 235);
    }
    .no-border {
      border-right: none !important;
    }
    .ag-header-cell-label .ag-header-cell-text {
      font-weight: 500;
      color: white;
      font-size: $font-size-14;
    }
    .ag-center-cols-clipper {
      min-height: 40px !important;
    }
    .no-rows {
      display: flex;
      justify-content: center;
      gap: 12px;
      flex-direction: column;
      align-items: center;
      height: 100%;
      width: 100%;
      border: 1px solid #e1e1e1;
      border-radius: 12px;
    }
    .ag-overlay {
      height: auto;
      width: auto;
      top: 38%;
      left: 35%;
      pointer-events: initial;
      .ag-overlay-wrapper {
        padding-top: 0px;
      }
    }
  }

  .ag-cell.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
    border-left: 1px solid rgb(234, 234, 235);
  }
  .ag-icon {
    color: #fff !important;
  }
  .ag-header-cell-label {
    justify-content: space-between;
    flex-direction: row !important;
  }
  .ag-header-cell {
    padding: 4px 10px !important;
  }
  .ag-ltr .ag-sort-indicator-icon {
    color: #fff !important;
  }
  .ag-header-cell-resize {
    &:hover {
      &::after {
        background-color: blue !important;
      }
    }
  }
  .ag-icon-none::before {
    content: url("../../assets//images/grid//normal.svg");
    width: 20px;
  }
  .ag-icon-asc::before {
    content: url("../../assets//images/grid/asc__new.svg");
  }
  .ag-icon-desc::before {
    content: url("../../assets//images/grid/desc__new.svg");
  }
  &.custom__65__top {
    .ag-overlay {
      top: 65%;
    }
  }
}
.ag-header-cell-resize {
  z-index: 0 !important;
}
