@import "../../assets/styles/variables.scss";

.summary-main-container {
  width: calc(100% - 90px);
  display: flex;
  flex-direction: column;
  background: #f6f6f6;
  overflow-y: scroll;
  padding: 24px;
  padding-bottom: 50px;
  gap: 20px;
  height: 100%;
  position: relative;

  .summary-header-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 20px;
    .header-sub-title-container {
      display: flex;
      text-align: start;
      align-items: center;
      justify-content: space-between;
      padding: 0px 0px;
    }
    .header-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      & > span {
        @include semibold(22px, 28px);
        color: $black_variation-2;
        text-align: center;
        letter-spacing: 0.4px;
      }
      & > p {
        @include regular(14px, 18px);
        color: $black_variation-2;
        margin: 0;
        span {
          @include medium(14px, 18px);
          color: $black_variation-2;
        }
      }
    }
    .tabs__container {
      display: flex;
      gap: 12px;
      width: 295px;
      max-width: fit-content;
      padding: 6px;
      border-radius: 6px;
      border: 1px solid #e6e6e6;
      background: #efeff1;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.08);
      margin-top: 0px;
      @media (max-height: 700px) {
        margin-top: 8px;
      }
      align-items: center;
      width: auto;
      .tab {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        background: #efeff1;
        padding: 6px 8px;
        border-radius: 6px;
        cursor: pointer;
        span {
          @include medium(14px, 20px);
          color: #8a8a8f;
          &:hover {
            color: #222;
          }
        }
        &.active {
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
          background: #fff;
          span {
            @include semibold(14px, 20px);
            color: #4baa7e;
            &:hover {
              color: #4baa7e;
            }
          }
        }
      }
    }
  }
  .select-container {
    cursor: pointer;
    width: 230px;
    display: flex;
    flex-direction: column;
    gap: 7px;
    label {
      @include regular($font-size-12, $line-height-20);
      letter-spacing: $letter-spacing;
      text-align: start;
    }
    @include calendarDropdowns();
  }
  .sub-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
  }

  .summary-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    .summary-cards-container {
      flex-basis: 18%;
      min-width: 18%;
      display: flex;
      flex-grow: 1;
      min-height: 130px;
      padding: 22px 0px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      gap: 4px;
      border-radius: 6px;
      box-shadow: 0px 1px 8px 0px rgba(143, 143, 143, 0.12);
      position: relative;
      .title {
        @include medium(14px, 24px);
        text-align: center;
        margin-bottom: 0px !important;
      }
      .summary {
        @include semibold(22px, 33px);
        color: #4baa7e;
        text-align: center;
        margin-bottom: 0px !important;
      }
    }
  }
  .donut-main-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    background: transparent;
    border-radius: 6px;
    .donut-chart-container {
      flex-basis: 33%;
      background: #ffffff;
      border-radius: 6px;
      align-self: stretch;
      box-shadow: 0px 1px 8px 0px rgba(143, 143, 143, 0.12);
      .donut-chart {
        width: 100%;
        height: 30vh;
        padding: 0px;

        .chart-container {
          width: 100%;
          height: 100%;
          padding: 8px;
        }
        div {
          width: 100%;
          height: 100%;
        }
      }
      .highcharts-credits {
        display: none !important;
      }
    }

    .title-container {
      width: 100%;
      border-bottom: 1px solid #ebebeb;
      text-align: left;
      padding: 12px 16px;
      h5 {
        @include medium(20px, 30px);
        margin-bottom: 0px !important;
      }
      p {
        @include medium(12px, 18px);
        color: #7a7a7a;
        margin-bottom: 0px !important;
      }
    }

    .spline-chart-container {
      flex-basis: 49%;
      flex-grow: 1;
      background: #ffffff;
      border-radius: 6px;
      box-shadow: 0px 1px 8px 0px rgba(143, 143, 143, 0.12);

      .select-container {
        border-bottom: none !important;
        path {
          fill: red !important;
        }
      }
      .spline-header-container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ebebeb;
      }
      .spline-chart {
        width: 100%;
        border-radius: 6px;
        padding: 0px 16px;
        padding-bottom: 16px !important;
      }
      .highcharts-credits {
        display: none !important;
      }
      .filter-container {
        padding: 16px;
      }
    }
  }
  .tp-user-container {
    flex-basis: 100%;
    align-items: flex-start;
    .tp-sub-container {
      flex-basis: 49%;
      flex-grow: 1;
    }
  }
}

.contract-container {
  .summary-cards-container {
    flex-basis: 32% !important;
  }
  .info-icon {
    position: absolute;
    top: 12px;
    right: 14px;
    cursor: pointer;
  }
}
.not-found-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #fff;
  overflow-y: scroll;
  padding: 24px;
  padding-bottom: 50px;
  gap: 20px;
  height: 550px;
  position: relative;
}
.selectcategory--is-disabled {
  @include calendarDropdownDisabled();
  label {
    @include regular($font-size-12, $line-height-20);
    letter-spacing: $letter-spacing;
    text-align: start;
    color: #ccc;

  }
}
.select-container-disabled {
  cursor: not-allowed !important;
}
