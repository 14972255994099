@import "~bootstrap/scss/bootstrap";
@import "./variables.scss";
@import "./button.scss";
body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  .hidden {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  span {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
.connection__fail {
  p {
    color: $red_variation_1;
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 2px;
    img {
      margin-right: 5px;
    }
  }
}
.bold_timer {
  font-size: $font-size-16 !important;
  line-height: $line-height-16 !important;
  font-weight: $font-weight-400 !important;
  color: $black_variation-2 !important;
}

.load {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
  background: transparent;
  z-index: 999 !important;
  background-color: rgba(255, 255, 255, 0.7);
}
.preload {
  // margin-top: 17%;
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 5px solid $green_variation-2;
  animation: 1s pre linear infinite;
}
@keyframes pre {
  0% {
    transform: rotate(0deg);
    border-top: 5px solid $green;
  }
  100% {
    transform: rotate(360deg);
    border-top: 5px solid $green;
  }
}

.preload-dot-pulse {
  width: 60px;
  height: 30px;
  --_g: no-repeat radial-gradient(circle closest-side, #60ca9a 90%, #0000);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 50%;
  animation: d3 1s infinite linear;
}
@keyframes d3 {
  20% {
    background-position: 0% 0%, 50% 50%, 100% 50%;
  }
  40% {
    background-position: 0% 100%, 50% 0%, 100% 50%;
  }
  60% {
    background-position: 0% 50%, 50% 100%, 100% 0%;
  }
  80% {
    background-position: 0% 50%, 50% 50%, 100% 100%;
  }
}

.toaster-popup {
  font-weight: $font-weight-400;
  font-size: $font-size-14;
  line-height: $line-height-16;
  letter-spacing: $letter-spacing;
  color: $black_variation-2;
  min-width: 250px;
  img {
    margin-right: 8px;
  }
  .toaster-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  h3 {
    font-weight: $font-weight-400;
    font-size: $font-size-14;
    line-height: $line-height-16;
    letter-spacing: $letter-spacing;
    color: $black_variation-2;
  }
  .toaster-description {
    max-width: 350px;
    margin-right: 20px;
  }
}
.toaster-popup-close {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}
.parent-container {
  width: 100%;
  height: 100%;
  display: flex;
}
.app-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000;
  opacity: 0.25;
  //z-index: 999;
  top: 0;
  left: 0;
  position: fixed;
}

.tooltip-name {
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-tooltip-lite {
  width: auto !important;
  max-width: 1000px;
  height: auto;
  max-height: 300px;
  overflow: auto;

  padding-left: 30px !important;
  padding-right: 30px !important;
  padding-top: 6px !important;
  padding-bottom: 6px !important;
  text-align: left;
  background: $black_variation-2;
  border-radius: 6px;
  color: $white;
  border: 1px solid $black_variation-2;
  //box-shadow: 4px 6px 32px -2px rgba(226, 226, 234, 0.24);
  span {
    font-weight: $font-weight-400;
    font-size: $font-size-12;
    line-height: $line-height-18;
    letter-spacing: $letter-spacing;
    color: $white;
  }
}
.react-tooltip-lite-arrow {
  border-color: $black_variation-2;
  border-top: 10px solid black;
  border-bottom: 10px solid transparent;
  border-right-width: 10px;
}
.react-tooltip-lite-right-arrow {
  border-color: $black_variation-2;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right-width: 10px;
}
.selectcategory__indicator-separator {
  display: none !important;
}

.selectcategory__menu {
  .selectcategory__menu-list {
    overflow-x: auto;
    .selectcategory__option {
      white-space: nowrap;
    }
  }
}
.cg-relative-position {
  position: relative;
}
.cg-absolute-position {
  position: absolute;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-20 {
  margin-left: 20px;
}
.margin-left-30 {
  margin-left: 30px;
}
.margin-left-25 {
  margin-left: 25px;
}
.capitalize__text {
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  &:hover {
    width: 8px;
  }
}

/* Track */
::-webkit-scrollbar-track {
  width: 5px;
  height: 5px;
  background: transparent;
  &:hover {
    background: $grey-variation-12;
  }
}
/* Handle */
::-webkit-scrollbar-thumb {
  width: 5px;
  border-radius: 6px;
  background: $grey-variation-11;
}
.overflow-auto {
  overflow: auto;
}
.txt__align__end {
  text-align: end;
}
.txt__align__start {
  text-align: start;
}
.txt__align__cntr {
  text-align: center;
}
.nocomp__div {
  display: flex;
  flex-direction: column;
  padding: 10px 14px;
  border-radius: 6px;
  border: 1px solid #eff2f3;
  background: #fff;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  justify-content: center;
  align-items: center;
  gap: 11px;
  img {
    height: 50px;
  }
  p {
    margin-bottom: 0px;
    font-size: 14px;
    font-style: normal;
    font-family: "Inter-Medium";
    line-height: 18px;
    color: #222;
  }
  .no-rows-btn {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    text-transform: capitalize;
    display: flex;
    padding: 7px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
  }
}
.margin__btm__12 {
  margin-bottom: 12px !important;
}

.custom-file-input {
  color: transparent;
}
.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}
.custom-file-input::before {
  content: "Select some files";
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  background: var(
    --Filter-Gradient,
    linear-gradient(180deg, #fff 0%, #fafafb 100%)
  );
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  display: flex;
  height: 34px;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  color: #222;
  font-size: 14px;
  font-weight: 500;
  width: fit-content;
  line-height: normal;
  cursor: pointer;
}
.custom-file-input:hover::before {
  border: 1px solid var(--primary-primary, #60ca9a);
  background: var(
    --Filter-Gradient,
    linear-gradient(180deg, #fff 0%, #fafafb 100%)
  );
}
.custom-file-input:active {
  outline: 0;
}
.custom-file-input:active::before {
  background: var(
    --Filter-Gradient,
    linear-gradient(180deg, #fff 0%, #fafafb 100%)
  );
}
.align-self-center {
  align-self: center;
}
.display-flex {
  display: flex !important;
}
.no-border {
  border: none !important;
}
.flex-row {
  flex-direction: row !important;
}
.info-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  width: auto;
  height: auto;
  cursor: pointer;
}
