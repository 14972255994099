@import "../../assets/styles/variables.scss";
.sign-board-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #f8f9fc;
  background-image: url(../../assets/images/snowflake_image.svg);
  background-repeat: no-repeat;
  background-position: 100% -1%;

  span {
    -webkit-font-smoothing: antialiased;
  }
  .signin-header {
    display: flex;
    background-color: transparent;
  }

  .block {
    height: 100%;
    align-items: center;
  }

  .signboard-parent-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
    max-width: 486px;
    border-radius: 16px;
    overflow-y: hidden;

    .signboard-progress {
      width: 100%;
      height: 6px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      gap: 2px;

      .signboard-progress-item {
        width: 100%;
        height: 100%;
        background: #d6d6d6;
      }

      .signboard-progress-done {
        background: #60ca9a;
      }
    }
  }

  .heading-back {
    display: flex;
    margin-bottom: 24px;

    .back-btn {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 8px;
      cursor: pointer;

      span {
        color: $grey_variation-3;
        font-family: "Inter-Medium";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 26px;
      }
    }
  }

  .heading {
    text-align: left;
    margin-bottom: 20px;
    span {
      color: #222222;
      font-family: "Inter-Semibold";
      font-size: 24px;
      font-style: normal;
      line-height: 36px;
    }
    .signboard-hi {
      font-size: 30px;
    }
    @media (max-width: 425px) {
      span {
        font-size: 22px;
        font-style: normal;
        line-height: 33px;
      }
      margin-bottom: 32px;
    }
  }
  .signboard-question {
    text-align: left;
    span {
      color: #222;
      font-family: "Inter-Medium";
      font-size: 18px;
      line-height: 27px;
    }
    @media (max-width: 425px) {
      span {
        font-size: 16px;
        line-height: 21px;
      }
    }
  }

  .signboard-board-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 38px;
    padding-top: 32px;

    .signboard-list {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 40px;

      .email-error {
        display: flex;
        align-items: flex-start;
        gap: 4px;

        img {
          width: 14px;
          height: 14px;
        }

        span {
          margin: 0px;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          line-height: 16px;
        }
      }

      .signboard-textboxes {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .signboard-namebox {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 20px;
        }
      }

      .signboard-role-dropdown {
        text-align: left;
        .signboard-helper-warning-img {
          width: 14px;
          height: 14px;
        }
      }

      .signboard-dropdown-arrow {
        padding-right: 16px;
      }

      .login-btn {
        padding: 8px 16px;
        height: 52px;
        font-size: 14px;
        line-height: 21px;
        font-family: "Inter-Semibold";
      }
    }
  }

  .signboard-invite-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    .signboard-role-dropdown {
      text-align: left;
      .signboard-helper-warning-img {
        width: 14px;
        height: 14px;
      }
    }

    .signboard-dropdown-arrow {
      padding-right: 16px;
    }
    .heading {
      margin-bottom: 32px;
    }

    .signboard-list {
      display: flex;
      flex-direction: column;

      .signboard-scrollable {
        padding: 0px 38px;
        padding-top: 32px;
        padding-bottom: 16px;
        //overflow-y: auto;
        // max-height: 399px;
        max-height: 450px;
        //border-bottom: 1px solid #d6d6d6;
      }

      .signboard-emailboxes {
        display: flex;
        flex-direction: column;
        gap: 20px;
        max-height: 285px;
        overflow-y: auto;

        input::placeholder {
          color: #bdbdbd;
          opacity: 1;
        }

        .email-error {
          display: flex;
          align-items: flex-start;
          gap: 4px;

          img {
            width: 14px;
            height: 14px;
          }

          span {
            margin: 0px;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            line-height: 16px;
          }

          .signup-password-req {
            display: none;
            color: #7a7a7a;
            font-size: 14px;
            font-family: Inter;
            font-style: normal;
            line-height: 20px;
          }
        }

        .signboard-add-email {
          text-align: left;
          .signboard-add-email-text {
            cursor: pointer;
            color: #60ca9a;
            font-family: "Inter-Medium";
            font-size: 16px;
            line-height: 16px;
          }
        }
      }

      .signboard-invite-submit-div {
        padding: 0px 38px;
        padding-top: 24px;
        display: flex;
        flex-direction: column;
        box-shadow: 0px -1px 8px 0px rgba(165, 165, 165, 0.1);
      }

      .login-btn {
        padding: 8px 16px;
        height: 52px;
        font-size: 14px;
        line-height: 21px;
        font-family: "Inter-Semibold";
      }
    }

    .signboard-invite-footer {
      margin: 0px;
      margin-top: 24px;
      margin-bottom: 40px;

      .signboard-do-later {
        cursor: pointer;
        color: #7a7a7a;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  .signboard-survey-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .heading {
      margin-bottom: 12px;
    }

    .sub-heading {
      text-align: left;
      margin-bottom: 32px;
      span {
        color: #7a7a7a;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        line-height: normal;
      }
    }

    .signboard-question {
      margin-bottom: 16px;
      span {
        font-family: "Inter-Medium";
        font-size: 16px;
        line-height: normal;
      }
    }

    .signboard-list {
      display: flex;
      flex-direction: column;
      gap: 24px;

      .signboard-scrollable {
        padding: 0px 38px;
        padding-top: 32px;
        padding-bottom: 16px;
        //overflow-y: auto;
        max-height: 450px;
        // border-bottom: 1px solid #d6d6d6;
      }

      .signboard-checkboxes {
        display: flex;
        flex-direction: column;

        label {
          color: #7a7a7a;
        }

        .signboard-checkbox-checked {
          color: #222;
        }

        .signboard-checkbox-icon {
          width: 18px;
          height: 18px;
          border: 1px solid #7a7a7a;
          border-radius: 4px;
        }

        .signboard-checkbox-img-icon {
          width: 18px;
          height: 18px;
        }
      }

      .login-btn {
        margin: 0px 38px;
        padding: 8px 16px;
        height: 52px;
        font-size: 14px;
        line-height: 21px;
        font-family: "Inter-Semibold";
      }
    }

    .signboard-survey-footer {
      margin-top: 24px;
      margin-bottom: 40px;

      .signboard-do-later {
        cursor: pointer;
        color: #7a7a7a;
        text-align: center;
        font-family: Inter;
        font-size: 14px;
        line-height: 21px;
      }
    }
  }
}

@media (max-width: 850px) {
  .sign-board-container {
    justify-content: flex-start;
    background-color: #f8f9fc;
    background-image: url(../../assets/images/snowflake_image.svg);
    background-repeat: no-repeat;
    background-position: 100% -1%;

    .signin-header {
      background-color: transparent;
    }

    .signboard-parent-block {
      background: #fff;
      width: 100%;
      border-radius: 8px;
    }

    .signboard-parent {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 425px) {
  .sign-board-container {
    background-color: #fff;
    background-image: none;

    .signin-header {
      background: #f8f9fc;
    }

    .block {
      padding: 0px;
      border-radius: 0px;
    }

    .signboard-parent-block {
      height: 100%;
      padding: 0px;
      justify-content: flex-start;
      border-radius: 0px;
    }

    .signboard-board-parent {
      padding: 20px;
      .heading {
        margin-top: 40px;
      }
    }
  }
}
