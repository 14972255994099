@import "../../assets/styles/variables.scss";
.alert-settings-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 24px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 32px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }

  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      margin-top: 10px;
      .header {
        text-align: start;
        span {
          font-size: 20px;
          line-height: 24px;
          font-weight: $font-weight-600;
          color: $black_variation-2;
          letter-spacing: $letter-spacing-point4;
          //font-family: "Inter-Semibold";
        }
      }
      .subheader-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        .sub-header {
          text-align: start;
          span {
            font-size: 14px;
            font-family: "Inter";
            color: #7a7a7a;
            .subheader-bold {
              font-family: "Inter-Semibold";
              color: #353a3f;
            }
          }
        }
      }
    }
    .top-filter {
      display: flex;
      gap: 20px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        label {
          font-size: $font-size-12;
          font-family: "Inter";
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        .selectcategory__control {
          display: flex;
          min-height: 40px;
          height: auto !important;
          width: 100% !important;
          background-color: $grey-variation-5;
          background-color: #f9f9fc;
          border-radius: 8px;
          .selectcategory__single-value {
            text-align: start;
            font-size: 14px;
            font-family: "Inter-Medium";
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              font-size: $font-size-14;
              font-family: "Inter-Medium";
              line-height: 16px;
              text-align: start;
              height: 40px;
              color: #353a3f;
              background: none;
              cursor: pointer;
              &.selectcategory__option--is-focused {
                background-color: #f0faff;
              }
            }
          }
        }
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .block-container {
    display: flex;
    align-items: center;
    gap: 36px;
    .block {
      padding: 32px 40px;
      display: flex;
      max-width: 308px;
      flex-direction: column;
      gap: 24px;
      height: 320px;
      max-height: 320px;
      position: relative;
      justify-content: space-between;
      border-radius: 20px;
      border: 1px solid #f3f3f3;
      background-color: linear-gradient(
        315.32deg,
        #f8f9fc 0%,
        rgba(248, 249, 252, 0) 100%
      );
      .coming-soon-img-container {
        position: absolute;
        width: 88px;
        height: 88px;
        top: 0;
        right: 0;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .img-container {
        height: 56px;
        width: 56px;
        border: 1px solid #f3f3f3;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .alert-heading-container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 8px;
        .heading-parent {
          display: flex;
          gap: 6px;
          .alert-heading {
            font-family: "Inter-Semibold";
            //font-family: 600;
            font-size: 16px;
            line-height: 22px;
            letter-spacing: $letter-spacing;
            color: $black_variation-2;
            margin-bottom: 0px;
            text-align: start;
          }
        }
        .alert-desc {
          text-align: start;
          span {
            font-size: 14px;
            line-height: 20px;
            font-family: "Inter";
            color: $black_variation-2;
            opacity: 70%;
          }
        }
      }
      button {
        border-radius: 10px;
        font-family: "Inter-Medium";
        font-size: $font-size-14;
        line-height: $line-height-16;
        letter-spacing: $letter-spacing;
        height: 40px;
        background-color: $green;
        border: 1px solid $green;
        &.disabled {
          pointer-events: all;
          cursor: not-allowed;
          color: #cccccc;
          border: 1px solid #e4e4e7;
          opacity: 1;
          background: #f7f7f7;
          &:hover {
            background-color: #f7f7f7;
          }
        }
        &:hover {
          background-color: #56b68b;
        }
      }
    }
  }
  .grid-block {
    width: 100%;
    display: flex;
    gap: 24px;
    border-radius: 12px;
    padding: 16px 0px;
    flex-direction: column;
    background-color: $white;
    .checkbox-container {
      display: flex;
      gap: 8px;
      align-items: center;
      height: 16px;
      .form-check-label {
        font-size: $font-size-14;
        font-weight: $font-weight-400;
        line-height: 21px;
        letter-spacing: 0.2px;
        color: $grey_variation-3;
      }
      input {
        cursor: pointer;
        height: 100%;
        width: 16px;
        margin-top: 0px;
        &:checked {
          background-color: $green;
          border-color: $green;
        }
      }
    }

    .download-loader {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 5px solid $green_variation-2;
      animation: 1s pre linear infinite;
    }
    @keyframes pre {
      0% {
        transform: rotate(0deg);
        border-top: 5px solid $green;
      }
      100% {
        transform: rotate(360deg);
        border-top: 5px solid $green;
      }
    }

    .save_row {
      font-size: $font-size-12;
      line-height: $line-height-16;
      font-weight: $font-weight-400;
      letter-spacing: $letter-spacing;
      height: 22px;
      width: 92px;
      color: $green;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      cursor: pointer;
      background: none;
      border-radius: 4px;
      border: none;
      &:hover {
        color: #56b68b;
      }
      &.disabled {
        //opacity: 0.6;
        pointer-events: none;
        cursor: not-allowed;
        color: #d6d6d6;
      }
      //margin-top: 4px;
    }
    .grid-header-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      .grid-header-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .grid-header {
          display: flex;
          width: 100%;
          text-align: start;
          justify-content: space-between;
          span {
            font-size: 20px;
            line-height: 24px;
            font-weight: 600;
            color: #222222;
            letter-spacing: 0.4px;
          }
        }
      }
      .alert__settings-btn {
        border-radius: 10px;
        font-family: "Inter-Medium";
        font-size: 14px;
        width: 120px;
        line-height: 16px;
        letter-spacing: 0.2px;
        height: 40px;
        background-color: #60ca9a;
        border: 1px solid #60ca9a;
        border-radius: 6px;
        color: #fff;
        &:hover {
          background-color: #56b68b;
        }
        &.disabled {
          pointer-events: all;
          cursor: not-allowed;
          color: #cccccc;
          border: 1px solid #e4e4e7;
          opacity: 1;
          background: #f7f7f7;
        }
      }
      .grid-filter-container {
        display: flex;
        gap: 20px;
        .filter {
          height: 40px;
          display: flex;
          gap: 10px;
          border: 1px solid #e3e3e8;
          border-radius: 6px;
          padding: 0px 15px;
          cursor: pointer;
          .filter-img {
            height: 100%;
            width: 16px;
            align-items: center;
            text-align: center;
            display: flex;
            img {
            }
          }
          .filter-text {
            height: 100%;
            text-align: center;
            align-items: center;
            display: flex;
            span {
              font-weight: $font-weight-500;
              font-size: $font-size-14;
              line-height: $line-height-16;
              letter-spacing: $letter-spacing-point1;
            }
          }
        }
        .select-container {
          cursor: pointer;
          width: 230px;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            display: flex;
            min-height: 40px;
            height: auto !important;
            width: 100% !important;
            background-color: #f9f9fc;
            border-radius: 8px;
            .selectcategory__single-value {
              text-align: start;
              font-size: 14px;
              font-weight: 500;
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
    .grid-filters {
      background-color: $grey-variation-11;
      padding: 16px;
      display: flex;
      gap: 20px;
      .filter-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .filter-label {
          text-align: start;
          span {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
          }
        }
        .select-container {
          width: 236px;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          gap: 7px;
          label {
            font-size: $font-size-12;
            font-weight: $font-weight-400;
            line-height: $line-height-20;
            letter-spacing: $letter-spacing;
            text-align: start;
            color: #222222;
          }
          .selectcategory__control {
            display: flex;
            min-height: 50px;
            height: auto !important;
            width: 100% !important;
            background-color: $grey-variation-5;
            .selectcategory__single-value {
              text-align: start;
            }
            .selectcategory__multi-value {
              background-color: $grey-variation-2;
              .selectcategory__multi-value__remove {
                svg {
                  fill: #60ca9a;
                  stroke: #60ca9a;
                }
                &:hover {
                  background-color: transparent;
                }
              }
            }
            .selectcategory__indicators {
              .selectcategory__clear-indicator {
                display: none;
              }
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;
                height: 40px;
                color: $grey-variation-3;
                background: none;
                cursor: pointer;
                &.selectcategory__option--is-focused {
                  background-color: #f0faff;
                }
              }
            }
          }
        }
      }
    }
  }
  .load {
    background-color: transparent;
  }
}
