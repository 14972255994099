.absolute-toolbox {
  position: absolute;
  top: 63px;
  right: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  // padding: 4px 6px;
  // background-color: #d7d7d7;
  // border-radius: 6px;
  z-index: 80;
  //cursor: pointer;
  .tool-parent {
    gap: 4px;
    padding: 4px 6px;
    background-color: #d7d7d7;
    border-radius: 6px;
    display: flex;
    .tool {
      display: flex;
      align-items: center;
      background-color: #ffffff;
      //padding: 2px 6px;
      border-radius: 4px;
      cursor: pointer;
      span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        padding: 2px 6px;
        color: #7a7a7a;
      }
      svg {
        width: 20px;
        height: 20px;
        padding: 2px;
        cursor: pointer;
        path {
          stroke: #7a7a7a;
        }
        &:hover {
          path {
            stroke: #222222;
          }
        }
      }
      &:hover {
        // background-color: #f0faff;
        opacity: 0.7;
        span {
          color: #222222;
        }
      }
    }
  }
  .tooltext-container {
    text-align: end;
    span {
      font-style: italic;
      font-size: 12px;
      line-height: 14.4px;
      color: #7a7a7a;
    }
  }
}
.raw-div {
  overflow-y: auto;
  background-color: #f7f7f7;
  //border-radius: 10px;
  padding: 8px 0px;
  height: inherit;
  svg {
    width: 100%;
    height: 100%;
  }
}
