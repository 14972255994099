@import "../../assets/styles/variables.scss";
.card-container {
  width: 25%;
  display: flex;
  position: relative;
  padding: 10px 0px 10px 18px;
  border: 1px solid $grey-variation-10;
  border-radius: 6px;
  &.selected {
    border: 1px solid #59b2e0;
  }
  cursor: pointer;
  .card-block {
    flex-basis: 50%;
    display: flex;
    flex-direction: column;
    .image-container {
      padding: 2px;
      width: 40px;
      height: 40px;
      margin-top: 10px;
      margin-bottom: 12px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .header-container {
      text-align: start;
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      .header {
        span {
          font-size: $font-size-18;
          line-height: $line-height-16;
          font-weight: $font-weight-500;
          letter-spacing: $letter-spacing;
          color: $black_variation-2;
        }
      }
      .sub-header {
        span {
          font-weight: $font-weight-400;
          font-size: $font-size-14;
          line-height: $line-height-16;
          letter-spacing: $letter-spacing;
          color: $grey_variation-3;
        }
      }
    }
    .selection-container {
      display: flex;
      flex-direction: column;
      .selected-container {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 8px;
        margin-right: 18px;
      }
      .coming-soon {
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
}
