@import "../../assets/styles/variables.scss";
.tg-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 18px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 32px;
  padding-top: 0px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }

  .grid-block {
    width: 100%;
    display: flex;
    gap: 16px;
    border-radius: 12px;
    padding: 16px 0px;
    flex-direction: column;
    background-color: $white;
    .grid-header-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
      .grid-header-block {
        display: flex;
        flex-direction: column;
        gap: 10px;
        justify-content: center;
        .grid-header {
          text-align: start;
          span {
            font-size: $font-size-18;
            line-height: 22px;
            font-weight: $font-weight-600;
            //letter-spacing: $letter-spacing-point4;
            color: $black_variation-2;
          }
        }
      }
    }
    .grid-filter-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;

      .top-filter {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        .select-container {
          cursor: pointer;
          width: 230px;
          height: 38px;

          display: flex;
          flex-direction: column;
          gap: 7px;
          position: relative;

          .selectcategory__control {
            display: flex;
            height: 38px;
            cursor: pointer;
            width: 100% !important;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
            border-radius: 6px;
            border-color: #E3E3E8;
            background: linear-gradient(
              180deg,
              #fff 0%,
              #fafafb 100%
            ) !important;

            &:active {
              border: 1px solid #60ca9a;
              background: #f7f7f7 !important;
            }
            &:hover {
              border-radius: 6px;
              border: 1px solid #cfcfd6;
              background: #f7f7f7 !important;
            }
            .selectcategory__single-value {
              text-align: start;
              font-size: 14px;
              font-weight: 500;
              margin: 0px !important;
              display: flex;
              align-items: center;
              font-family: "Inter-Medium";
              color: #222222 !important;
              &::before {
                content: "";
                margin-right: 8px;
                margin-top: 4px;
              }
            }
            &.selectcategory__control--is-focused {
              box-shadow: none !important;
              border: 1px solid $black_variation-2 !important;
              outline: none !important;
              font-size: $font-size-14 !important;
              border: 1px solid #60ca9a !important;
            }
          }
          .selectcategory__menu {
            .selectcategory__menu-list {
              .selectcategory__option {
                font-size: $font-size-14;
                font-weight: $font-weight-500;
                line-height: 16px;
                text-align: start;

                height: 36px;
                color: #353a3f;
                background: none;
                cursor: pointer;
                z-index: 99 !important;
                &.selectcategory__option--is-focused {
                  background: #ecf8f4;
                }
                &.selectcategory__option--is-selected {
                  font-family: "Inter-Medium";
                  color: #353a3f;
                }
              }
            }
          }
          &#selectSFAcc {
            width: 160px;
          }
        }
      }
      .date-container {
        .selectcategory__single-value {
          text-align: start;
          font-size: 14px;
          font-weight: 500;
          display: flex;
          align-items: center;
          &::before {
            content: url(../../assets/images/common/calendar.svg) !important;
            margin-right: 8px;
            margin-top: 4px;
          }
        }
      }
    }
  }
  .load {
    background-color: transparent;
  }
}
