@import "../../assets/styles/variables.scss";

.cost-center-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  position: relative;
  overflow-y: scroll;
}
.empty-state-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;

  .empty-table-icon {
    width: 218px;
    height: 142px;
  }
  .description-container {
    width: 368px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .sub-heading {
    @include medium(16px, 24px);
    margin: 0;
    padding: 0;
  }
  .description {
    width: 368px;
    @include regular(13px, 20px);
    color: #7a7a7a;
    letter-spacing: 0.2px;
    margin: 0;
    padding: 0;
  }
  .create-cost-center {
    display: flex;
    flex-direction: row;
    height: 40px;
    max-width: 180px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 4px;
    border: 1px solid #60ca9a;
    background: #60ca9a;
    @include semibold(14px, 18px);
    color: #fff;
  }
}
.cost-center-modal-container {
  width: 694px !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;

  .drawer-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 20px 24px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d6d6d6;
    background: #fff;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 999;
    p {
      @include semibold(20px, 24px);
      margin: 0;
    }
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .divider-container {
    width: 100%;
    padding: 10px 24px;
  }
  .divider {
    width: 100%;
    height: 1.5px;
    background-color: #ebebeb;
  }
  .title-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .title {
      width: 100%;
      @include semibold(16px, 18px);
      letter-spacing: 0.2px;
      margin: 0;
      text-align: left;
    }
    .description {
      width: 100%;
      @include regular(14px, 18px);
      text-align: left;
      color: #8f8f8f;
      margin: 0;
    }
  }
  .step-one-container {
    width: 100%;
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;

    .inputs-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 16px;

      & div {
        width: 48%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;
        position: relative;

        label {
          width: 100%;
          @include medium(14px, 20px);
        }
      }
    }
  }
  .add-condition-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0px 24px;
    justify-content: flex-start;
    align-items: center;
    p {
      cursor: pointer;
      @include medium(14px, 20px);
      color: #0597f2;
      margin: 0;
    }
  }
  .drawer-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 24px;
    justify-content: flex-end;
    align-items: center;
    border-top: 1px solid #d6d6d6;
    background: #fff;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 10;
    gap: 12px;

    .create-button {
      border-radius: 4px;
      border: 1px solid #60ca9a;
      background: #60ca9a;
      width: 116px;
      height: 40px;
      padding: 8px 12px;
      @include semibold(14px, 18px);
      color: #fff;
      &:hover {
        background: #4baa7e;
      }
    }
    .delete-button {
      @include medium(14px, 18px);
      border: 1px solid #e96560;
      background: #fff;
      color: #e96560;
    }
  }
  .cost-resource-main-container,
  .step-one-container {
    input {
      width: 100%;
      height: 38px;
      border-radius: 4px;
      border: 1px solid #e4e4e7;
      outline: none;
      padding: 5px 12px;
      @include regular(14px, 20px);
    }
    .chip-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    .chip {
      min-width: 30px;
      width: 40%;
      padding: 4px;
      border-radius: 4px;
      background: #ebebeb;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #222;
      font-size: 12px;
    }
    .chip2 {
      width: 45px;
      padding: 4px;
      border-radius: 4px;
      background: #ebebeb;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #222;
      font-size: 12px;
    }
  }
  .cost-resource-main-container {
    width: 100%;
    // max-height: 380px;
    // height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    padding: 10px 24px;
    // overflow-y: auto;
    .main-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      position: relative;
      gap: 24px;
      background: #fff;
      .account-input {
        width: 30%;
        height: 38px;
      }
      .operator-input {
        width: 15%;
        height: 38px;
      }
      .value-input {
        width: 55%;
        height: 38px;
        // & ::after {
        //   content: "" !important;
        //   width: 0px !important;
        //   background: none !important;
        //   height: 0px !important;
        //   position: absolute;
        //   left: 0px;
        //   top: 0px;
        // }
        @include regularDropdowns();
        .menuItems {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 12px;
        }
        .dropdown-list-item {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
        }
      }
      .filter-button {
        height: 38px;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-family: "Inter-Medium";
        padding: 4px 6px;
        border-radius: 6px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
        border-radius: 6px;
        color: #ccc;
        background: linear-gradient(180deg, #fff 0%, #fafafb 100%) !important;
        &:hover {
          border-radius: 6px;
          border: 1px solid #cfcfd6;
          background: #f7f7f7 !important;
        }
      }
    }
    #accountRow::after {
      content: "";
      width: 1px;
      background: #e4e4e7;
      height: 65%;
      position: absolute;
      left: 10px;
      top: 38px;
    }
    #whWrapper::after {
      content: "";
      width: 20px;
      background: #e4e4e7;
      height: 1px;
      position: absolute;
      left: 10px;
      top: 50%;
    }
    .account-row-wrapper {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 8px;

      .delete-filter:hover {
        svg {
          cursor: pointer;
          path {
            stroke: #e96560 !important;
          }
        }
      }
    }
    .wearehouse-row-container {
      width: 100%;
      position: relative;
    }
    .wearehouse-row-wrapper {
      padding-left: 30px;
      width: 95%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
    }
  }
}
//Modal CSS Ends

// Table Page Starts
.cost-table-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px 32px 279px 32px;
  gap: 20px;
  .title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .title {
      @include semibold(20px, 24px);
      margin: 0;
    }
    .search-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
      .search-bar {
        width: 240px;
        height: 40px;
        border-radius: 6px;
        background: #fff;
        outline: none;
        .MuiOutlinedInput-root {
          @include regular(14px, 20px);
          font-family: "Inter" !important;
          height: 100%;
        }
        @include regular(14px, 20px);
        fieldset {
          font-family: "Inter" !important;
          border-radius: 6px;
          border: 1px solid #e4e4e7;
        }
        &:hover {
          fieldset {
            border: 1px solid #cfcfd6 !important;
          }
        }
        input:focus + fieldset {
          border: 1px solid #60ca9a !important;
        }
      }

      & img {
        width: 20px;
        height: 20px;
      }
      button {
        flex-basis: 185px;
        display: flex;
        height: 40px;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 6px;
        border-radius: 4px;
        background: #60ca9a;
        border: 1px solid #60ca9a;
        @include semibold(14px, 18px);
        color: #fff;
        &:hover {
          background: #4baa7e;
        }
      }
    }
  }
}
.grid-container {
  width: 100%;
  max-height: 55vh;
  // .ag-cell {
  //   border-right: 1px solid rgb(234, 234, 235) !important;
  // }

  .status-chip-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;
    width: 100%;
    height: 36px;
  }
  .inprogress-chip {
    height: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 38px;
    background: #fef4e7;
    padding: 3px 8px;
    @include medium(11px, 16px);
    color: #f19308;
    & > span {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background: #f19308;
    }
  }
  .live-chip {
    height: 75%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 38px;
    background: #e4f6ee;
    padding: 3px 8px;
    @include medium(11px, 16px);
    color: #13854e;
    & > span {
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background: #13854e;
    }
  }
}
.cost-value-container {
  @include defaultCheckbox();
}
.error-msg {
  position: absolute;
  bottom: -20px;
  @include regular(12px, 20px);
  color: red;
  left: 0px;
}
.disabled-button {
  cursor: not-allowed !important;
  background: #a0dfc2 !important;
  color: #f5f5f5 !important;
}

.delete-table-Icon {
  cursor: pointer;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

  svg {
    path {
      stroke: red;
    }
  }
  .position-sticky {
    position: sticky;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.04);
  }
  .even-row-bg {
    background: #fafafa !important;
  }
}
