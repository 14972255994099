.signin-header {
  display: none;
  padding: 20px;
  background: #f8f9fc;

  .logo-container {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    img {
      width: 32px;
      height: 32px;
    }
    span {
      color: #000;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 30px;
    }
  }
}

@media (max-width: 1024px) {
  .signin-header {
    display: flex;
  }
}
