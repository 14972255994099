@import "../../assets/styles/variables.scss";
.feature-container {
  display: flex;
  //flex-basis: 50%;
  width: 50%;
  background-color: #f8f9fc;
  background-image: url(../../assets/images/snowflake_image.svg);
  background-repeat: no-repeat;
  background-position: 100% -1%;
  .feature-parent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    .logo-container {
      height: 32px;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 12px;
      img {
        height: 100%;
      }
      span {
        //font-weight: $font-weight-400;
        font-size: $font-size-20;
        line-height: $line-height-30;
        color: $black;
        font-family: "Inter-Medium";
        -webkit-font-smoothing: antialiased;
      }
    }
    .feature-space-1 {
      flex: 1 1 140px;
    }
    .feature-space-2 {
      flex: 1 1 200px;
    }
    .feature-list-container {
      display: flex;
      flex-direction: column;
      max-width: 504px;
      .heading {
        text-align: start;
        margin-bottom: 8px;
        // padding-right: 50px;
        span {
          color: #222222;
          font-size: 40px;
          line-height: 56px;
          font-family: "Inter-SemiBold";
          // font-family: Inter;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          //letter-spacing: 0.4px;
        }
      }
      .feature-list {
        list-style-image: none;
        padding-left: 0px;
        .feature-item {
          display: flex;
          margin-top: 16px;
          gap: 10px;
          span {
            color: #6a6a6a;
            font-size: 18px;
            line-height: 27px;
            font-weight: 400;
            -webkit-font-smoothing: antialiased;
            text-align: left;
          }
        }
      }
    }
    .trusted-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      .heading {
        // text-align: start;
        span {
          font-size: 16px;
          color: #8a8a8a;
          // font-family: "Inter-Medium";
          font-family: Inter;
          -webkit-font-smoothing: antialiased;
          line-height: 24px; /* 150% */
        }
      }

      .trusted-orgs-overflow {
        width: 100%;
        overflow-x: hidden;
        position: relative;
      }
      .trusted-orgs-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        height: 52px;
        gap: 44px;
        position: relative;
        width: max-content;
        animation: slide 9s linear infinite;
        // transform: translateX(-50%);
        .org-container {
          padding: 0px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        @keyframes slide {
          0% {
            transform: translateX(0%);
          }
          100% {
            transform: translate(-50%);
          }
        }
      }
      .org-container:first-child {
        margin-left: 44px;
      }
    }
  }
}
//}
.block {
  padding-left: 72px;
  padding-right: 72px;
  // padding-left: 187px;
  // padding-right: 187px;
  padding-top: 52px;
  padding-bottom: 52px;
  display: flex;
  justify-content: center;
  width: 100%;
}

@media (max-width: 1024px) {
  .feature-container {
    display: none;
  }
  .block {
    padding: 0px 20px;
    padding-top: 24px;
    margin: auto;
  }
}
