@import "../../assets/styles/variables.scss";
.dropdown-parent {
  position: absolute;
  inset: 0px 0px auto auto;
  background-color: white !important;
  z-index: 1000;
  width: max-content;
  border: none;
  border-radius: 6px;
  box-shadow: 0 0 8px rgb(0 0 0 / 16%);
  top: 45px;
  :hover {
    background-color: white !important;
  }
  &.center {
    transform: translateX(25%);
  }
  .login-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-bottom: 1px solid #ebebeb;
    padding: 12px 20px;
    p {
      color: #5c5c5c;
      font-family: "Inter";
      font-size: 14px;
      font-style: normal;
      line-height: 21px;
      margin: 0;
    }
    h5 {
      color: #000;
      font-family: "Inter-Medium";
      font-size: 14px;
      font-style: normal;
      line-height: 21px;
      margin: 0;
    }
  }
  .dropdown-block {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    width: auto;
    .option {
      padding: 12px;
      text-align: start;
      width: 100%;
      display: flex;
      gap: 8px;
      align-items: center;
      font-family: "Inter";
      span {
        color: #5c5c5c;
        font-family: "Inter-Medium";
        font-size: 14px;
        font-style: normal;
        line-height: 20px;
      }
      svg {
        width: 22px;
        height: 22px;
      }
      &:hover {
        background: #ecf8f4 !important;
        svg {
          path {
            stroke: #222222;
          }
        }
        span {
          background: #ecf8f4 !important;
          color: $black_variation-2 !important;
        }
      }
      &.active {
        font-family: "Inter-Medium";
        span {
          color: $green;
          //font-weight: $font-weight-600;
        }
      }
    }
  }
}
