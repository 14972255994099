@import "../../assets/styles/variables.scss";
.reset-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  //flex-basis: 50%;
  width: 50%;
  position: relative;
  span {
    -webkit-font-smoothing: antialiased;
  }
  .reset-parent-block {
    width: 100%;
    max-width: 392px;
  }
  .reset-parent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    max-width: 392px;
    .heading-back {
      display: flex;
      margin-bottom: 24px;

      .back-btn {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        cursor: pointer;

        span {
          color: $grey_variation-3;
          font-family: "Inter-Medium";
          font-size: 16px;
          font-style: normal;
          //font-weight: 500;
          line-height: 26px;
          //letter-spacing: 0.4px;
        }
      }
    }
    .heading {
      text-align: left;
      margin-bottom: 12px;
      span {
        color: #222222;
        font-family: "Inter-Semibold";
        font-size: 24px;
        font-style: normal;
        line-height: 26px;
      }
      @media (max-width: 425px) {
        span {
          font-size: 22px;
          font-style: normal;
          font-weight: 600;
          line-height: 33px;
          letter-spacing: 0.2px;
        }
      }
    }
    .subheading {
      margin-bottom: 32px;
      text-align: left;
      span {
        color: $grey_variation-3;
        font-family: "Inter";
        font-size: 14px;
        line-height: 21px; /* 150% */
        font-weight: 400;
        font-style: normal;
      }
      .email-span {
        font-family: "Inter-Medium";
        //font-weight: $font-weight-500;
      }
    }
    .reset-list {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .form-group {
        text-align: start;
        margin-bottom: 24px;
        label {
          margin-bottom: 12px;
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          line-height: $line-height-16;
        }
        input {
          height: 48px;
          border-radius: 6px;
          max-width: 440px;
          &::placeholder {
            color: #bdbdbd;
            font-size: 14px;
            line-height: 16px;
            font-weight: 400;
            letter-spacing: 0.2px;
          }
        }
        .cg-eye {
          top: 18px;
          left: 93%;
          cursor: pointer;
        }
      }
      .cg-eye {
        width: 18.33px;
        height: 18.33px;
      }
      .login-btn {
        margin-top: 8px;
        margin-bottom: 0px;
        height: 52px;
        font-size: 14px;
        line-height: 21px;
        font-family: "Inter-Semibold";
      }
      .email-error {
        display: flex;
        align-items: flex-start;
        gap: 4px;

        img {
          width: 14px;
          height: 14px;
        }

        span {
          margin: 0px;
          font-size: 14px;
          font-family: Inter;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;
          letter-spacing: 0.2px;
        }
      }
      .reset-back {
        span {
          color: #60ca9a;
          text-align: center;
          font-family: "Inter-Medium";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          cursor: pointer;
        }
      }
      .ver-code {
        text-align: start;
        margin-bottom: 16px;
        span {
          color: $black_variation-2;
          font-family: "Inter-Medium";
          font-size: $font-size-14;
          font-style: normal;
          line-height: $line-height-21;
          text-transform: capitalize;
        }
      }
      .reset-pass-heading {
        text-align: start;
        margin-bottom: -8px;
        span {
          color: $black_variation-2;
          font-family: "Inter-Medium";
          font-size: $font-size-14;
          font-style: normal;
          line-height: $line-height-21;
          text-transform: capitalize;
        }
      }
      .name-container {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 8px;
        .form-group {
          flex-basis: 50%;
        }
        div {
          width: 52px;
          height: 52px;
        }
        input {
          width: 100%;
          height: 100%;
          max-width: 52px;
          max-height: 52px;
          aspect-ratio: 1;
          padding: 18px 16px;
          border-radius: 6px;
          border: 1px solid #bdbdbd;
          background: #fff;
          color: #222;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.1px;
        }

        input:focus {
          outline: none;
          border: 1px solid #60ca9a;
        }

        @media (max-width: 768px) {
          input, div {
            width: 52px;
            height: 52px;
          }
        }

        @media (max-width: 400px) {
          input, div {
            width: 46px;
            height: 46px;
          }
        }

        @media (max-width: 350px) {
          input, div {
            width: 40px;
            height: 40px;
          }
        }
      }
      .name-container.error__otp {
        input {
          border: 1px solid #e96560;
        }
      }
      .connection__fail {
        margin-bottom: 8px;
      }
      .otp-list {
        .subheading {
          margin-top: 4px;
          margin-bottom: 16px;
          height: 21px;
          span {
            color: $grey_variation-3;
            font-family: "Inter";
            font-size: 14px;
            font-style: normal;
            font-weight: $font-weight-400;
            line-height: 21px;
          }
          .resend_mail {
            display: inline;
            color: $green;
            font-family: "Inter-Medium";
            font-size: 14px;
            font-style: normal;
            line-height: 21px;
            cursor: pointer;
          }
          .resend_mail_grey {
            display: inline;
            color: $green;
            opacity: 0.5;
            font-family: "Inter-Medium";
            font-size: 14px;
            font-style: normal;
            line-height: 21px;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .reset-container {
    width: 100%;
    justify-content: flex-start;
    background-color: #f8f9fc;
    background-image: url(../../assets/images/snowflake_image.svg);
    background-repeat: no-repeat;
    background-position: 100% -1%;

    .signin-header {
      background-color: transparent;
    }

    .reset-parent-block {
      background: #fff;
      padding: 40px;
      width: 100%;
      max-width: 472px;
      border-radius: 8px;
    }

    .reset-parent {
      justify-content: flex-start;
    }
  }
}

@media (max-width: 425px) {
  .reset-container {
    background-color: #fff;
    background-image: none;

    .signin-header {
      background: #f8f9fc;
    }

    .reset-parent-block {
      height: 100%;
      padding: 0px;
    }

    .reset-parent {
      padding-top: 50px;
    }
  }
}
