@import "../../assets/styles/variables.scss";
.qdet-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 24px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 24px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;
      margin-top: 10px;
      .breadcrumbs-container {
        color: $grey_variation-3;
        font-size: $font-size-14;
        font-weight: $font-weight-400;
        line-height: $line-height-18;
        letter-spacing: $letter-spacing;
        a {
          &:hover {
            text-decoration: underline !important;
          }
        }
        span {
          color: $grey_variation-3;
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          line-height: $line-height-18;
          letter-spacing: $letter-spacing;
        }
      }
      .header {
        text-align: start;
        display: flex;
        gap: 5px;
        align-items: center;
        .back_btn {
          cursor: pointer;
        }
        span {
          font-family: "Inter-Semibold";
          font-size: $font-size-20;
          line-height: 24.2px;
          letter-spacing: $letter-spacing;
          color: $black_variation-2;
        }
      }
    }
    .top-filter {
      display: flex;
      gap: 20px;
      .select-container {
        cursor: pointer;
        width: 230px;
        display: flex;
        flex-direction: column;
        gap: 7px;
        label {
          font-size: $font-size-12;
          font-weight: $font-weight-400;
          line-height: $line-height-20;
          letter-spacing: $letter-spacing;
          text-align: start;
          color: #222222;
        }
        .selectcategory__control {
          display: flex;
          min-height: 40px;
          height: auto !important;
          width: 100% !important;
          background-color: $grey-variation-5;
          background-color: #f9f9fc;
          border-radius: 8px;
          .selectcategory__single-value {
            text-align: start;
            font-size: 14px;
            font-weight: 500;
          }
        }
        .selectcategory__menu {
          .selectcategory__menu-list {
            .selectcategory__option {
              font-size: $font-size-14;
              font-weight: $font-weight-500;
              line-height: 16px;
              text-align: start;
              height: 40px;
              color: #353a3f;
              background: none;
              cursor: pointer;
              &.selectcategory__option--is-focused {
                background-color: #f0faff;
              }
            }
          }
        }
        &#selectSFAcc {
          width: 160px;
        }
      }
    }
  }
  .qdet_info-container {
    display: flex;
    gap: 24px;
    justify-content: space-between;
    .qdet-parent {
      flex: 1 1 0px;
      padding: 24px 0px;
      height: 420px;
      max-width: 40%;
      border: 0.3px solid #e1e1e1;
      box-shadow: 0px 4px 8px 0px #29292914;
      border-radius: 10px;
      transition: box-shadow 0.2s ease-in-out;
      //transition: border 0.3s ease-in-out;
      &:hover {
        box-shadow: 0px 12px 20px 0px #29292914;
      }
      .header-container {
        text-align: start;
        display: flex;
        align-items: center;
        padding-bottom: 16px;
        padding-left: 24px;
        padding-right: 24px;
        font-family: "Inter-Semibold";
        span {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: $letter-spacing-point4;
          color: $black_variation-2;
        }
      }
      .details-container {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        padding: 0px 24px;
        .detail-block {
          display: flex;
          justify-content: space-between;
          .heading {
            span {
              color: #7a7a7a;
              font-size: $font-size-12;
              line-height: 16px;
              font-weight: $font-weight-400;
              letter-spacing: 0.1px;
            }
          }
          .value {
            max-width: 180px;
            text-align: start;
            span {
              color: #222222;
              font-size: $font-size-12;
              line-height: 16px;
              font-weight: $font-weight-400;
              letter-spacing: 0.1px;
            }
          }
        }
      }
    }
    .text-div-container {
      flex: 1 1 0px;
      padding: 16px 0px;
      height: 420px;
      max-width: 60%;
      border: 0.3px solid #e1e1e1;
      box-shadow: 0px 4px 8px 0px #29292914;
      border-radius: 10px;
      overflow: hidden;
      flex-direction: column;
      gap: 10px;
      display: flex;
      transition: box-shadow 0.2s ease-in-out;
      //transition: border 0.3s ease-in-out;
      &:hover {
        box-shadow: 0px 12px 20px 0px #29292914;
      }
      .header-container {
        text-align: start;
        display: flex;
        align-items: center;
        //padding-bottom: 16px;
        padding-left: 16px;
        padding-right: 16px;
        justify-content: space-between;
        position: relative;
        span {
          font-family: "Inter-Semibold";
          font-size: 16px;
          line-height: 24px;
          letter-spacing: $letter-spacing-point4;
          color: $black_variation-2;
        }

        .actions__div {
          display: flex;
          gap: 12px;
          align-items: center;
          .copy-image {
            cursor: pointer;
            z-index: 99;
            &:hover {
              opacity: 0.8;
            }
          }
          .download-link {
            height: 28px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            img {
              width: 20px;
              height: 20px;
            }
          }
          .download-loader {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 5px solid $green_variation-2;
            animation: 1s pre linear infinite;
          }
          @keyframes pre {
            0% {
              transform: rotate(0deg);
              border-top: 5px solid $green;
            }
            100% {
              transform: rotate(360deg);
              border-top: 5px solid $green;
            }
          }
        }
      }
      .raw-div {
        overflow-y: auto;
        background-color: #ffffff;
        border-radius: 10px;
        position: relative;
        padding-left: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 16px;
        height: inherit;
        text-align: start;

        pre {
          background-color: #f7f7f7;
          padding-left: 8px;
          padding-right: 8px;
          margin-bottom: 0px;
          code {
            font-size: 12px;
            line-height: 20px;
            color: #424242;
            font-family: "Monaco";
          }
        }
      }
    }
  }
  .subheader-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-bottom: 0.5px solid #d7d7d7;
    .qdet-nav {
      display: flex;
      gap: 16px;
      //padding: 3px;
      //border-radius: 8px;
      width: max-content;
      .qdet-link {
        text-decoration: none;
        padding: 8px 10px;
        color: #222222;
        font-size: 18px;
        line-height: 22px;
        //border-radius: 4px;
        letter-spacing: $letter-spacing;
        font-family: "Inter";
        &:hover {
          border-bottom: 2px solid #bdbdbd;
          font-family: "Inter-Medium";
        }
        &.active {
          color: $green;
          font-family: "Inter-Semibold";
          border-bottom: 2px solid $green;
        }
      }
    }
  }
  .rec-block {
    display: flex;
    gap: 24px;
    //margin-top: 16px;
    .rec-parent {
      display: flex;
      flex-direction: column;
      gap: 16px;
      border: 0.3px solid #e1e1e1;
      box-shadow: 0px 4px 8px 0px #29292914;
      padding: 16px;
      max-width: 50%;
      border-radius: 10px;
      flex: 1 1 0px;
      transition: box-shadow 0.2s ease-in-out;
      //transition: border 0.3s ease-in-out;
      &:hover {
        box-shadow: 0px 12px 20px 0px #29292914;
      }
      .heading-container {
        display: flex;
        text-align: start;
        align-items: center;
        //gap: 24px;
        span {
          font-size: 16px;
          line-height: 24px;
          color: $black_variation-2;
          letter-spacing: $letter-spacing-point4;
          font-family: "Inter-Semibold";
        }
      }
      .rec-container {
        display: flex;
        .rec {
          //padding: 24px;
          // width: 556px;
          background-color: #ffffff;
          @media (max-height: 700px) {
            width: 150px;
          }
          height: 200px;
          overflow-y: auto;
          flex: 1 1 0px;
          a {
            color: #0597f2;
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          div {
            font-size: 12px;
            line-height: 22px;
            font-weight: 400;
            letter-spacing: 0.1px;
            color: #7a7a7a;
            margin-bottom: 8px;
            div {
              text-align: start;
              b {
                font-size: 14px;
                font-weight: 600 !important;
                margin-bottom: 10px;
                line-height: 16px;
                letter-spacing: $letter-spacing;
                display: block;
              }
              ul {
                text-align: start;
                li {
                  font-size: 12px;
                  line-height: 22px;
                  font-weight: 400;
                  letter-spacing: 0.1px;
                  color: #7a7a7a;
                  margin-bottom: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .load {
    background-color: transparent;
  }
}
