@import "../../assets/styles/variables.scss";
.accounts-container {
  span {
    -webkit-font-smoothing: antialiased;
  }
  display: flex;
  padding: 70px 0px 0px 100px;
  @media (max-height: 700px) {
    padding: 60px 0px 0px 100px;
  }
  //gap: 57px;
  height: 100%;
  .account-info {
    display: flex;
    flex-direction: column;
    //flex-basis: 60%;
    text-align: start;
    width: 782px;
    margin-top: 40px;
    .link-container {
      color: $grey_variation-3;
      font-size: $font-size-14;
      font-weight: $font-weight-400;
      line-height: $line-height-18;
      letter-spacing: $letter-spacing;
      margin-bottom: 8px;
    }
    .header-container {
      margin: 8px 0px;
      display: flex;
      gap: 8px;
      .back_btn {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
      .header {
        span {
          font-weight: $font-weight-600;
          font-size: 26px;
          line-height: $line-height-26;
          letter-spacing: $letter-spacing-point4;
          color: $black_variation-2;
        }
      }
    }
    .steps-container {
      display: flex;
      gap: 10px;
      margin: 24px 0px;
      .step {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        gap: 10px;
        .step-divider {
          width: 131px;
          border-radius: 100px;
          height: 2px;
          background-color: rgba(122, 122, 122, 0.4);
          &.active {
            background-color: $green;
          }
        }
        .form-check {
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          &.check-box {
            margin-bottom: 24px;
            &:last-child {
              margin-bottom: 0px;
            }
            .form-check-input {
              margin-top: 0px;
              margin-right: 8px;
              cursor: pointer;
              border: 1px solid #7a7a7a;
            }
            .form-check-label,
            label {
              cursor: pointer;
              text-transform: capitalize;
              &.active {
                color: #222222;
              }
            }
          }
          .form-check-input {
            outline: 0 !important;
            box-shadow: none;
          }
          .form-check-label {
            font-size: $font-size-14;
            line-height: $line-height-16;
            color: $grey_variation-3;
            font-weight: $font-weight-500;
            letter-spacing: $letter-spacing;
          }
          .form-check-input:checked {
            background-color: $green;
            border-color: $green;
          }
          &.check-label {
            margin-top: -7px;
          }
        }
        .success_container {
          display: flex;
          gap: 8px;
          .success_img {
            width: 16px;
            height: 16px;
            display: flex;
            //justify-content: center;
            align-items: center;
            margin-top: 5px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .success-label {
            display: flex;
            align-items: center;
            margin-top: 3px;
            span {
              color: #7a7a7a;
              font-weight: $font-weight-400;
              font-size: $font-size-14;
              line-height: $line-height-16;
            }
          }
        }
      }
    }
    .info-container {
      margin: 8px 0px;
      display: flex;
      flex-direction: column;
      //justify-content: space-between;
      gap: 20px;
      //width: 420px;
      .info {
        span {
          font-weight: $font-weight-400;
          font-size: 14px;
          line-height: 21px;
          color: #7a7a7a;
          #sql_script_span {
            color: $blue;
            cursor: pointer;
          }
        }
      }
    }
    .contract-container {
      display: flex;
      padding: 16px;
      background-color: #f8f9fc;
      flex-direction: column;
      width: 401px;
      gap: 24px;
      border-radius: 6px;
      .credit {
        display: flex;
        flex-direction: column;
        .credit-container {
          display: flex;
          gap: 8px;
          align-items: center;
          span {
            font-weight: 500;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: $letter-spacing-point4;
            color: #222222;
            .credit-subtext {
              font-weight: $font-weight-500;
              font-size: $font-size-14;
              line-height: $line-height-21;
              letter-spacing: $letter-spacing-point4;
              color: #7a7a7a;
            }
          }
          input {
            border: 1px solid #bdbdbd;
            border-radius: 6px;
            height: 32px;
            width: 50px;
            font-size: 14px;
            line-height: 17px;
            font-weight: 400;
            color: #222222;
            text-align: center;
          }
          .select-container {
            cursor: pointer;
            width: 150px;
            display: flex;
            flex-direction: column;
            gap: 7px;
            margin-left: 38px;
            label {
              font-size: $font-size-12;
              font-weight: $font-weight-400;
              line-height: $line-height-20;
              letter-spacing: $letter-spacing;
              text-align: start;
              color: #222222;
            }
            .selectcategory__control {
              display: flex;
              min-height: 40px;
              height: auto !important;
              width: 100% !important;

              border-radius: 8px;
              .selectcategory__single-value {
                text-align: start;
                font-size: 14px;
                font-weight: 500;
              }
            }
            .selectcategory__menu {
              .selectcategory__menu-list {
                .selectcategory__option {
                  font-size: $font-size-14;
                  font-weight: $font-weight-500;
                  line-height: 16px;
                  text-align: start;
                  height: 40px;
                  color: #353a3f;
                  background: none;
                  cursor: pointer;
                  &.selectcategory__option--is-focused {
                    background-color: #f0faff;
                  }
                }
              }
            }
          }
        }
      }
      .seperator {
        border-bottom: 1px solid #e1e1e1;
      }
    }
    .info-header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 10px;
      margin-bottom: 24px;
      .hdr {
        span {
          font-weight: $font-weight-600;
          font-size: 20px;
          line-height: 24.2px;
          color: #222222;
        }
        &.step3 {
          span {
            line-height: 30px;
            letter-spacing: $letter-spacing-point4;
          }
        }
      }
      .info-subtitle {
        width: 410px;
        span {
          color: $green;
          font-weight: $font-weight-600;
          font-size: $font-size-12;
          line-height: $line-height-18;
        }
        &.step3 {
          span {
            color: #7a7a7a;
            font-size: $font-size-14;
            line-height: $line-height-20;
            font-weight: $font-weight-400;
            letter-spacing: $letter-spacing-point1;
          }
        }
      }
    }
    .instruction-container {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: space-between;
      //   //width: 500px;
      //   gap: 10px;
      //   margin-top: 8px;
      //   margin-bottom: 16px;
      //   padding: 16px 12px;
      margin-top: 12px;

      .code-instruction-parent {
        padding: 12px 16px;
        background-color: $grey_variation-5;
        display: flex;
        width: 715px;
        max-height: 340px;
        overflow-y: auto;
        justify-content: space-between;
        .code-instruction {
          display: flex;
          flex-direction: column;
          gap: 2px;
          width: 650px;
          p {
            font-family: Menlo;
            font-weight: $font-weight-400;
            font-size: 10px;
            color: #424242;
            margin-bottom: 2px;
            .sp-r {
              color: #cf222e;
            }
            .sp-b {
              color: #0550ae;
            }
            .sp-v {
              color: #8250df;
            }
            .sp-db {
              color: #0a3069;
            }
            .sp-g {
              color: #6e7781;
            }
          }
        }
        .copy-image {
          cursor: pointer;
          &.fixed {
            position: fixed;
            left: 760px;
          }
          .copy {
            &:hover {
              svg {
                stroke: $white;
                background-color: $green;
              }
            }
          }
        }
      }

      .instruction {
        display: flex;
        span {
          font-weight: $font-weight-400;
          font-size: 15px;
          line-height: 22.5px;
          color: #424242;
        }
      }
    }
    .btn-container {
      display: flex;
      gap: 15px;
      margin: 24px 0px 10px 0px;
      &.finalsetup {
        margin: 40px 0px 10px 0px;
      }
      button {
        border-radius: 10px;
        font-size: $font-size-14;
        font-weight: $font-weight-500;
        line-height: $line-height-16;
        letter-spacing: $letter-spacing-point1;
        padding: 12px 16px;
      }
    }
    .line {
      margin-top: 8px;
      display: flex;
      width: 100%;
      border-bottom: 1px solid #e1e1e1;
      margin-bottom: 12px;
    }
    .docs-link-container {
      margin-top: 12px;
      display: flex;
      gap: 33px;
      .doc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        text-decoration: none;
        cursor: pointer;
        .ing-container {
          img {
            width: 100%;
            height: 100%;
          }
        }
        .text {
          span {
            font-weight: $font-weight-500;
            font-size: $font-size-14;
            line-height: $line-height-14;
            color: $green;
          }
        }
      }
    }
  }
  .features-info {
    display: flex;
    flex-direction: column;
    //margin-top: 35px;
    flex-grow: 1;
    background: linear-gradient(
      339.25deg,
      #f7f7f7 0%,
      rgba(247, 247, 247, 0) 100%
    );
    overflow-y: auto;
    .faq-header {
      display: flex;
      flex-direction: column;
      //align-items: center;
      text-align: start;
      .docs-link-container {
        margin-top: 12px;
        margin-bottom: 12px;
        display: flex;
        min-width: 400px;
        max-width: 400px;
        gap: 33px;
        .doc {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 10px;
          text-decoration: none;
          cursor: pointer;
          .ing-container {
            img {
              width: 100%;
              height: 100%;
            }
          }
          .text {
            span {
              font-weight: $font-weight-500;
              font-size: $font-size-14;
              line-height: $line-height-14;
              color: $green;
            }
            div {
              font-weight: $font-weight-400;
              font-size: $font-size-14;
              line-height: $line-height-21;
              color: #3c3c43;
              p {
                margin-bottom: 3px;
              }
            }
          }
        }
      }
      span {
        font-weight: $font-weight-600;
        font-size: 20px;
        line-height: 24.2px;
        color: #222222;
      }
    }
    padding-left: 80px;
    padding-top: 72px;
    padding-bottom: 12px;
    gap: 16px;
    .features-parent {
      //border-radius: 6px;
      display: flex;
      flex-direction: column;
      max-width: 400px;
      min-width: 310px;
      gap: 16px;
      .feature-block {
        display: flex;
        padding: 16px;
        flex-direction: column;
        border: 1px solid #e1e1e1;
        border-radius: 6px;
        gap: 12px;
        .qs-container {
          display: flex;
          justify-content: space-between;
          gap: 65px;
          .qs-parent {
            text-align: start;
            span {
              font-weight: $font-weight-500;
              font-size: $font-size-16;
              line-height: 24px;
              color: #222222;
            }
          }
          .qs-collapse {
            cursor: pointer;
          }
        }
        .ans-container {
          text-align: start;
          .ans-text {
            font-weight: $font-weight-400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: $letter-spacing-point1;
            color: #7a7a7a;
            text-align: start;
            .faq {
              display: flex;
              flex-direction: row;
              margin-left: 10px;
              gap: 10px;
            }
          }
        }
        // .desc-container {
        //   display: flex;
        //   flex-direction: column;
        //   gap: 4px;
        //   .desc-header {
        //     text-align: start;
        //     span {
        //       font-weight: $font-weight-600;
        //       font-size: $font-size-16;
        //       line-height: $line-height-20;
        //       color: $black;
        //     }
        //   }
        //   .desc-subheader {
        //     text-align: start;
        //     span {
        //       font-weight: $font-weight-400;
        //       font-size: $font-size-14;
        //       line-height: $line-height-21;
        //       color: #3c3c43;
        //     }
        //   }
        // }
      }
    }
  }
}
