// colors
$white: #ffffff;
$white_variation-2: #ddd;
$black: #000000;
$black_variation-2: #222;
$grey_variation-1: #bdbdbd;
$grey_variation-2: #f5f5f5;
$grey_variation-3: #7a7a7a;
$grey_variation-4: #f0f0f0;
$grey_variation-5: #f7f7f7;
$grey-variation-6: #dadada;
$grey-variation-7: #c3c3c3;
$grey-variation-8: #e9ecef;
$grey_variation-9: #e6e6e6;
$grey-variation-11: #f9f9fb;
$green: #60ca9a;
$green_variation-2: #effaf5;
$green_variation-3: #808080;
$blue: #0597f2;
$red_variation_1: #e96560;
$red-variation-2: #fef6f5;
$grey-variation-10: #d9d9d9;
$grey-variation-11: #999999;
$grey-variation-12: #dcdcdc;

// font-size
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-28: 28px;

// Line Height
$line-height-12: 12px;
$line-height-14: 14px;
$line-height-15: 15px;
$line-height-16: 16px;
$line-height-17: 17px;
$line-height-18: 18px;
$line-height-19: 19px;
$line-height-20: 20px;
$line-height-21: 21px;
$line-height-22: 22px;
$line-height-23: 23px;
$line-height-24: 24px;
$line-height-26: 26px;
$line-height-30: 30px;
$line-height-32: 32px;
$line-height-36: 36px;

// Letter Spacing
$letter-spacing-point1: 0.1px;
$letter-spacing-point15: 0.15px;
$letter-spacing: 0.2px;
$letter-spacing-point4: 0.4px;

//font-weight
$font-weight-normal: normal;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

//font-family
$font-family-inter: Inter;

// Font-Mixins
@mixin semibold($font-size, $line-height) {
  font-family: "Inter-Semibold";
  font-size: $font-size;
  font-style: normal;
  line-height: $line-height;
  color: $black_variation-2;
}

@mixin medium($font-size, $line-height) {
  font-family: "Inter-Medium";
  font-size: $font-size;
  font-style: normal;
  line-height: $line-height;
  color: $black_variation-2;
}
@mixin regular($font-size, $line-height) {
  font-family: "Inter";
  font-size: $font-size;
  font-style: normal;
  line-height: $line-height;
  color: $black_variation-2;
}
@mixin regulatTabs() {
  display: flex;
  gap: 12px;
  max-width: fit-content;
  padding: 6px;
  background-color: #f7f7f9;
  border-radius: 6px;
  margin-top: 0px;
  @media (max-height: 700px) {
    margin-top: 8px;
  }
  align-items: center;
  width: auto;
  .tab {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    background: #f7f7f9;
    padding: 6px 8px;
    border-radius: 6px;
    cursor: pointer;
    span {
      color: #8a8a8f;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      &:hover {
        color: #222;
      }
    }
    &.active {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
      background: #fff;
      span {
        color: #4baa7e;
        font-size: 14px;
        font-family: "Inter-medium";
        line-height: 20px;
        &:hover {
          color: #4baa7e;
        }
      }
    }
  }
}
@mixin calendarDropdowns() {
  .selectcategory__control {
    display: flex;
    min-height: 38px;
    cursor: pointer;
    height: auto !important;
    width: 100% !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    border-color: #e3e3e8;
    background: linear-gradient(180deg, #fff 0%, #fafafb 100%) !important;

    &:active {
      border: 1px solid #60ca9a;
      background: #f7f7f7 !important;
    }
    &:hover {
      border-radius: 6px;
      border: 1px solid #cfcfd6;
      background: #f7f7f7 !important;
    }
    .selectcategory__single-value {
      text-align: start;
      font-size: 14px;
      font-weight: 500;
      margin: 0px !important;
      display: flex;
      align-items: center;
      font-family: "Inter-Medium";
      color: #222;
      &::before {
        content: url(../../assets/images/common/calendar.svg) !important;
        margin-right: 8px;
        margin-top: 4px;
      }
    }
    &.selectcategory__control--is-focused {
      box-shadow: none !important;
      border: 1px solid $black_variation-2 !important;
      outline: none !important;
      font-size: $font-size-14 !important;
      border: 1px solid #60ca9a !important;
    }
  }
  .selectcategory__menu {
    .selectcategory__menu-list {
      .selectcategory__option {
        font-size: $font-size-14;
        font-weight: $font-weight-500;
        line-height: 16px;
        text-align: start;

        height: 36px;
        color: #353a3f;
        background: none;
        cursor: pointer;
        z-index: 99 !important;
        &.selectcategory__option--is-focused {
          background: #ecf8f4;
        }
        &.selectcategory__option--is-selected {
          font-family: "Inter-Medium";
          color: #353a3f;
        }
      }
    }
  }
  &#selectSFAcc {
    width: 160px;
  }
}
@mixin regularDropdowns() {
  .selectcategory__control {
    display: flex;
    min-height: 38px;
    cursor: pointer;
    height: 38px;
    width: 100% !important;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.07);
    border-radius: 6px;
    border-color: #e3e3e8;
    background: linear-gradient(180deg, #fff 0%, #fafafb 100%) !important;
    &:active {
      border: 1px solid #60ca9a !important;
      background: #f7f7f7 !important;
    }
    &:hover {
      border-radius: 6px;
      border: 1px solid #cfcfd6 !important;
      background: #f7f7f7 !important;
    }
    .selectcategory__single-value {
      text-align: start;
      font-size: 14px;
      font-weight: 500;
      font-family: "Inter-Medium";
      color: #222222;
      padding: 0px;
    }
    &.selectcategory__control--is-focused {
      box-shadow: none !important;
      outline: none !important;
      border: 1px solid #60ca9a !important;
      font-size: $font-size-14 !important;
    }
    &.selectcategory__option--is-selected {
      font-family: "Inter-Medium";
      background: #ecf8f4;
      color: #353a3f;
    }
  }
  .selectcategory__value-container {
    padding: 0px 8px;
  }
  .selectcategory__menu {
    .selectcategory__menu-list {
      .selectcategory__option {
        font-size: $font-size-14;
        font-weight: $font-weight-500;
        line-height: 16px;
        text-align: start;
        height: 36px;
        color: #353a3f;
        background: none;
        cursor: pointer;
        &.selectcategory__option--is-focused {
          background: #ecf8f4;
        }
        &.selectcategory__option--is-selected {
          font-family: "Inter-Medium";
          color: #353a3f;
        }
      }
    }
  }
}

@mixin calendarDropdownDisabled() {
  cursor: pointer;
  width: 230px;
  display: flex;
  flex-direction: column;
  gap: 7px;
  .selectcategory__single-value {
    @include medium(14px, 18px);
    text-align: start;
    margin: 0px !important;
    display: flex;
    align-items: center;
    color: #ccc;
    &::before {
      content: url(../../assets/images/common/calendar_disabled.svg) !important;
      margin-right: 8px;
      margin-top: 4px;
    }
  }
}
@mixin defaultCheckbox() {
  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 14px;
    height: 14px;
    background-color: #fff; /* Unchecked background color */
    border: 1px solid #222; /* Checked background color */
    border-radius: 3px;
    outline: none;
    cursor: pointer;
    position: "relative";
    padding: 0px !important;
  }

  /* Style the checked state of the checkbox */
  input[type="checkbox"]:checked {
    background-color: #60ca9a; /* Checked background color */
    border-color: none;
    border: 1px solid #60ca9a;
    border-radius: 3px;
    max-width: 14px;
    height: 14px;
    padding: 0px !important;
    /* Border color for the checked checkbox */
  }

  /* Style the checkmark inside the checkbox */
  input[type="checkbox"]:checked::after {
    content: "\2713" !important; /* Unicode checkmark character */
    display: block !important;
    width: 14px;
    height: 14px;
    max-width: 14px;
    text-align: center !important;
    line-height: 10px !important;
    color: #fff; /* Color of the checkmark */
    font-size: 12px !important;
    font-weight: bold;
    position: absolute;
    top: 1px;
    left: -1px;
    outline: none;
    padding: 0px !important;
  }
}
