@import "../../assets/styles/variables.scss";
.sso-settings-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 16px;
  span {
    -webkit-font-smoothing: antialiased;
  }
  //background-color: $grey-variation-11;
  padding: 20px 24px;
  @media (max-height: 700px) {
    padding: 15px 24px;
  }
  .header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // padding: 24px 0px;
    .header-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      .header {
        text-align: start;
        display: flex;
        gap: 8px;
        .back_btn {
          cursor: pointer;
        }
        span {
          font-size: 20px;
          line-height: 30px; /* 150% */
          letter-spacing: 0.2px;
          font-weight: $font-weight-600;
          color: $black_variation-2;
          //font-family: "Inter-Semibold";
        }
      }
      .subheader-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;
        .sub-header {
          text-align: start;
          span {
            font-size: 14px;
            font-weight: 400;
            color: #7a7a7a;
            .subheader-bold {
              font-weight: 600;
              color: #353a3f;
            }
          }
        }
      }
    }
  }
  .sso__steps__block {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    .block {
      padding: 18px 16px;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 6px;
      border: 1px solid #ebebeb;
      text-align: start;
      .steps__header__container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .steps__header {
          color: #222;
          font-size: 15px;
          font-weight: 600;
          line-height: 18px; /* 120% */
          letter-spacing: 0.2px;
        }
        .steps__subheader {
          color: #222;
          font-size: 14px;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
          letter-spacing: 0.2px;
        }
      }
      .checkbox-container {
        display: flex;
        gap: 8px;
        align-items: center;
        height: 16px;
        .form-check-label {
          font-size: $font-size-14;
          font-weight: $font-weight-400;
          line-height: 16px;
          letter-spacing: 0.2px;
          color: #222;
        }
        input {
          cursor: pointer;
          height: 100%;
          width: 16px;
          margin-top: 0px;
          border-color: #222;
          border-radius: 3px;
          -webkit-appearance: none;
          appearance: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          outline: none !important;
          &:checked {
            background-color: $green;
            border-color: $green;
          }
          &:focus {
            outline: none !important;
          }
        }
      }
      .sso__subblock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        .header__block {
          display: flex;
          gap: 4px;
          span {
            color: #222;
            font-size: 14px;
            font-family: "Inter-Medium";
            line-height: 16px; /* 114.286% */
            letter-spacing: 0.2px;
          }
        }
        .upload__container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          .upload__subheader {
            color: #7a7a7a;
            font-size: 12px;
            font-weight: 400;
            line-height: normal;
          }
          .input__container {
            display: flex;
            flex-direction: column;
            gap: 1px;
            .sso__upload {
              height: 34px;
            }
          }
        }
        .uploaded__container {
          display: flex;
          width: 215px;
          height: 42px;
          padding: 10px 12px;
          align-items: center;
          gap: 6px;
          border-radius: 6px;
          position: relative;
          background: #f0faf6;
          .sso__filename {
            color: #000;
            font-size: 13px;
            font-weight: 400;
            line-height: normal;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: 120px;
          }
          .delete__file {
            background: transparent;
            border: none;
            position: absolute;
            right: 9px;
            padding: 0;
            width: 18px;
            height: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              width: 100%;
              font-size: 15px;
              margin-bottom: 3px;
              align-self: center;
            }
          }
          .sso__filesize {
            color: rgba(0, 0, 0, 0.7);
            font-size: 11px;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
      .sso__list__block {
        display: flex;
        gap: 16px;
        align-items: center;
        .sso__img__container {
          display: flex;
          padding: 16px 26px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          gap: 12px;
          border-radius: 6px;
          cursor: pointer;
          border: 1px solid var(--gray-s-7, #ebebeb);
          background: #fff;
          box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.08);
          .tick__img {
            top: 6px;
            right: 10px;
            height: 18px;
            width: 18px;
          }
          span {
            color: #222;
            font-size: 14px;
            font-family: "Inter-Medium";
            line-height: 18px; /* 128.571% */
          }
          &:hover {
            border: 1px solid #4baa7e;
            background: linear-gradient(
              67deg,
              #f7fcfb -26.64%,
              rgba(220, 243, 235, 0.01) 112.06%
            );
          }
          &.green__bg {
            border: 1px solid #4baa7e;
            background: linear-gradient(
              67deg,
              #f7fcfb -26.64%,
              rgba(220, 243, 235, 0.01) 112.06%
            );
          }
        }
      }
      &.hidden {
        display: none !important;
      }
      &.btn__parent {
        padding: 16px 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        .save__config__btn {
          border-radius: 4px;
          color: #fff;
          font-size: 14px;
          font-family: "Inter-Medium";
          line-height: 18px; /* 128.571% */
          height: 40px;
          background-color: #60ca9a;
          border: 1px solid #60ca9a;

          &:hover {
            background-color: #56b68b;
          }
        }
        .disabled__config__btn {
          border-radius: 4px;
          color: #f5f5f5;
          font-size: 14px;
          font-family: "Inter-Medium";
          line-height: 18px; /* 128.571% */
          height: 40px;
          background-color: #a0dfc2;
          border: 1px solid #a0dfc2;

          &:hover {
            color: #f5f5f5;
            background-color: #a0dfc2;
            cursor: not-allowed;
          }
        }
        .disable__sso__btn {
          display: flex;
          width: 120px;
          height: 40px;
          padding: 8px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          appearance: none;
          -moz-appearance: none;
          border-radius: 4px;
          border: 1px solid #e96560;
          color: #e96560;
          font-size: 14px;
          font-family: "Inter-Medium";
          line-height: 18px; /* 128.571% */
          &:hover {
            background-color: #e96560;
            color: #ffffff;
          }
        }
      }
    }
  }
  .sso__banner__block {
    display: flex;
    gap: 16px;
    align-items: center;

    .sso__banner__container {
      display: flex;
      width: 167px;
      height: 140px;
      padding: 16px 20px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      gap: 8px;
      border-radius: 6px;
      cursor: pointer;
      border: 1px solid #ebebeb;
      background: #fff;
      box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.08);
      span {
        color: #222;
        font-size: 14px;
        //font-family: "Inter-Medium";
        font-weight: 600;
        line-height: 18px; /* 128.571% */
      }
      .edit__img {
        top: 10px;
        right: 10px;
      }
      &:hover {
        border: 1px solid #4baa7e;
        background: linear-gradient(
          67deg,
          #f7fcfb -26.64%,
          rgba(220, 243, 235, 0.01) 112.06%
        );
      }
      &.green__bg {
        border: 1px solid #4baa7e;
        background: linear-gradient(
          67deg,
          #f7fcfb -26.64%,
          rgba(220, 243, 235, 0.01) 112.06%
        );
      }
    }
  }
}
