html {
  width: 100%;
  height: 100%;
}
#root {
  width: 100%;
  height: 100%;
}
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
}
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: local("Inter"), url(./assets/fonts/Inter-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Black";
  src: local("Inter-Black"),
    url(./assets/fonts/Inter-Black.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Bold";
  src: local("Inter-Bold"),
    url(./assets/fonts/Inter-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-ExtraBold";
  src: local("Inter-ExtraBold"),
    url(./assets/fonts/Inter-ExtraBold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Light";
  src: local("Inter-Light"),
    url(./assets/fonts/Inter-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-ExtraLight";
  src: local("Inter-ExtraLight"),
    url(./assets/fonts/Inter-ExtraLight.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Medium";
  src: local("Inter-Medium"),
    url(./assets/fonts/Inter-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-SemiBold";
  src: local("Inter-SemiBold"),
    url(./assets/fonts/Inter-SemiBold.ttf) format("truetype");
}
@font-face {
  font-family: "Inter-Thin";
  src: local("Inter-Thin"),
    url(./assets/fonts/Inter-Thin.ttf) format("truetype");
}
@font-face {
  font-family: "Monaco";
  src: local("Monaco"), url(./assets/fonts//Monaco.woff) format("woff");
}
code {
  font-family: source-code-pro, Monaco, Menlo, Consolas, "Courier New",
    monospace;
}

#container-toasts {
  z-index: 2;
  position: absolute;
}

.woot--bubble-holder {
  z-index: 1;
  position: absolute;
}
