@import "../../assets/styles/variables.scss";
.main-block {
  min-height: 100%;
  height: auto !important;
  width: 100%;
  display: flex;
  gap: 0px;
  overflow: auto;
  padding-top: 64px;
  @media (max-height: 700px) {
    padding-top: 60px;
  }
}
