@import "../../assets/styles/variables.scss";
.app-header {
  height: 64px;
  @media (max-height: 700px) {
    height: 60px;
  }
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0px 24px;
  background-color: $white;
  border-bottom: 1px solid #ebebeb;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  .logo-container {
    cursor: pointer;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    span {
      font-family: "Inter-Medium";
      font-size: $font-size-20;
      line-height: 30px;
      color: #222;
    }
  }
  .menu-container {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 20px;
    position: relative;
    min-height: 31px;
    min-width: 100px;
    .menu-item-container-parent {
      height: 100%;
      padding: 14px 0px;
      .menu-item-container {
        padding: 4px 8px;
        cursor: pointer;
        position: relative;
        border-radius: 6px;
        .item-block {
          position: relative;
          display: flex;
          gap: 6px;
          align-items: center;
          // padding: 5px 5px;
          .icon-container {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .menu-item {
            span {
              font-weight: $font-weight-400;
              font-size: 15px;
              line-height: 22.5px;
              color: $grey_variation-3;
              -webkit-font-smoothing: antialiased;
            }
            a {
              text-decoration: none;
              font-weight: $font-weight-400;
              font-size: 15px;
              line-height: 22.5px;
              color: $grey_variation-3;
            }
          }
        }
        &.active {
          font-family: "Inter-Medium";
          background-color: #ecf9f3;
          .menu-item {
            span,
            a {
              //font-weight: $font-weight-600;
              color: $green;
            }
          }
        }
        &:hover {
          background-color: #f7f7f7;
        }
      }
    }
    // .menu-item-container-parent:hover .dropdown-parent {
    //   display: block;
    // }
  }

  .user-parent {
    display: flex;
    align-items: center;
    gap: 8px;
    .custom__tooltip {
      position: absolute;
      top: 35px;
      right: -10px;
      display: none;
      padding: 4px 6px;
      background: #000;
      color: #fff;
      border-radius: 4px;
      font-size: 10px;
      font-family: "Inter-Medium";
      line-height: 16px;
    }
    .settings-control {
      width: 32px;
      height: 32px;
      padding: 4px;
      margin-left: 4px;
      cursor: pointer;
      position: relative;
      &:hover {
        background: #f5f5f5;
        border-radius: 2px;
      }
    }
    .settings-control:hover .custom__tooltip {
      display: block !important;
    }
    .acc-select-container {
      cursor: pointer;
      width: 164px;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      .selectcategory__control {
        display: flex;
        min-height: 38px;
        color: #222 !important;
        width: 164px;
        cursor: pointer;
        height: auto !important;
        border-radius: 6px;
        border: 1px solid #ebebeb;
        background: #f5f5f5;
        &:active {
          border: 1px solid #60ca9a !important;
          background: #f7f7f7 !important;
        }
        &:hover {
          border-radius: 6px;
          border: 1px solid #cfcfd6 !important;
          background: #f7f7f7 !important;
        }
        .selectcategory__single-value {
          text-align: start;
          font-size: 14px;
          font-family: "Inter-Medium";
          color: #222;
        }
        &.selectcategory__control--is-focused {
          box-shadow: none !important;
          outline: none !important;
          border: 1px solid #60ca9a !important;
          font-size: $font-size-14 !important;
        }
      }
      .selectcategory__menu {
        width: max-content;
        max-width: 200px;
        overflow: auto;
        .selectcategory__menu-list {
          width: max-content;
          .selectcategory__option {
            font-size: $font-size-14;
            font-weight: $font-weight-500;
            line-height: 16px;
            text-align: start;
            height: 36px;
            color: #353a3f;
            background: none;
            cursor: pointer;
            padding: 12px;
            &.selectcategory__option--is-focused {
              background: #ecf8f4;
            }
            &.selectcategory__option--is-selected {
              font-family: "Inter-Medium";
              color: #60ca9a;
            }
          }
        }
      }
    }
    .line_vert {
      height: 31px;
      border-left: 1px solid #e1e1e1;
    }
    .user {
      display: flex;
      gap: 20px;
      align-items: center;
      cursor: pointer;
      height: 100%;
      :hover {
        background: #f5f5f5;
        border-radius: 3px;
      }
      .user-details {
        display: flex;
        align-items: center;
        gap: 4px;
        position: relative;
        padding: 4px;
        cursor: pointer;
        .user-panel {
          align-items: center;
          background: #5c5c5c;
          border: 1px solid transparent;
          border-radius: 100%;
          color: white;
          display: flex;
          font-size: 12.25px;
          font-family: "Inter-Medium";
          height: 28px;
          justify-content: center;
          line-height: 14px;
          // margin-right: 4px;
          text-transform: capitalize;
          width: 28px;
          :hover {
            background: #5c5c5c;
            border-radius: 2px;
          }
        }
        .profile-dropdown {
          border: 1px solid transparent;
          border-radius: 0.8px;
          img {
            height: 16px;
            width: 16px;
            padding: 2px;
          }
        }
      }
      // .user-details:hover .dropdown-parent {
      //   display: block;
      // }
    }
  }
}
